import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 登录信息
    token: '',
    refresh_token: '',
    // 登录状态
    loginStatus: false,
    //用户信息
    userData: {},
    //websocket
    client_id:'',
    //未读消息
    message:0,
    //系统信息
    systemInfo:{}
  },
  mutations: {
    set_token(state, token) {
      state.token = token
      localStorage.token = token
      sessionStorage.setItem('token', token)
    },
    set_refresh_token(state, token) {
      state.refresh_token = token
      localStorage.refresh_token = token
      sessionStorage.setItem('refresh_token', token)
    },
    set_telphone(state, telphone) {
      state.userData.telphone = telphone
      this.commit('set_userData', state.userData)
    },
    set_userData(state, userData) {
      state.userData = userData
      localStorage.userData = JSON.stringify(userData)
      sessionStorage.setItem('userData', JSON.stringify(userData))
    },
    set_loginStatus(state, loginStatus) {
      let flag = true
      if (loginStatus == 'false' || !loginStatus) {
        flag = false
      } else if (loginStatus == 'true' || loginStatus) {
        flag = true
      }
      state.loginStatus = flag
      localStorage.loginStatus = flag
      sessionStorage.setItem('loginStatus', flag)
    },
    set_clientId(state,clientId){
      state.client_id = clientId
    },
    set_message(state,message){
      state.message = message
    },
    set_systemInfo(state,info){
      state.systemInfo = info
    },

    //localStorage删除token
    del_token(state) {
      state.token = ''
      state.refresh_token = ''
      state.userData = {}
      state.loginStatus = false
      localStorage.removeItem('loginStatus')
      localStorage.removeItem('token')
      localStorage.removeItem('userData')
      localStorage.removeItem('sby_userid')
      sessionStorage.clear()
      this.commit('set_loginStatus', false)
      this.commit('set_userData', {})
      this.commit('set_token', '')
      this.commit('set_refresh_token', '')
    },

    user_paypwd(state, paypwd) {
      state.userData.paypwd = paypwd.pay_pwd
      this.commit('set_userData', state.userData)
    },
    user_touxiang(state, touxiang) {
      state.userData.touxiang = touxiang
      this.commit('set_userData', state.userData)
    },
    user_BasicInfo(state, userInfo) {
      state.userData.nickname = userInfo.nickname
      state.userData.email = userInfo.email
      state.userData.telphone = userInfo.telphone
      state.userData.qq = userInfo.qq
      state.userData.touxiang = userInfo.touxiang
      this.commit('set_userData', state.userData)
    },
    user_realName(state, realName) {
      state.userData.cert_name = realName.r_name
      state.userData.cert_id = realName.id_num
      state.userData.frontIdPhoto = realName.frontIdPhoto
      state.userData.backIdPhoto = realName.backIdPhoto
      this.commit('set_userData', state.userData)
    },
    user_withdrawalAccount(state, withdrawalAccount) {
      state.userData.alipay = withdrawalAccount.alipay
      state.userData.alipay_qrcode = withdrawalAccount.alipayCode
      state.userData.wechat = withdrawalAccount.wechat
      state.userData.wechat_qrcode = withdrawalAccount.wechatCode
      state.userData.bankcard = withdrawalAccount.bank
      state.userData.bank_address = withdrawalAccount.bankName
      state.userData.bank_realname = withdrawalAccount.name

      this.commit('set_userData', state.userData)
    }
  },
  actions: {},
  modules: {},
})
