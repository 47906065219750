<template>
  <div class="winners-list">
    <div class="top"></div>
    <div class="container">
      <div class="win-main">
        <ul>
          <li class="win-list">
            <div>
              <span class="lis-img-t">头像</span>
              <span class="list-name">用户昵称</span>
              <span class="list-tel">手机号</span>
            </div>
          </li>
          <li class="win-list">
            <div v-for="(item, index) in winnerList" :key="index">
              <span class="list-img">
                <img :src="`${item.touxiang ? item.touxiang : require('@/assets/logo.png')}`" alt="">
              </span>
              <span class="list-name">{{item.nickname}}</span>
              <span class="list-tel">{{item.telphone}}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { apiGetwinner } from "../../request/API";
export default {
  data() {
    return {
      id: '',
      winnerList: []
    }
  },
  created() {
    this.id = this.$route.query.id
    apiGetwinner({id: this.id}).then(res => {
      this.winnerList = res.data
    })
  },
}
</script>

<style lang="less">
.winners-list{
  .top{
    width: 100vw;
    height: 271px;
    position: relative;
    background: url('../../assets/image/Questionnaire/wint.png') no-repeat center center;
  }
  .container{
    width: 1200px;
    margin:  0 auto;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.10);
    position: relative;
    top: -74px;
    padding-top: 20px;
    padding-bottom: 100px;
    .win-main{
      width: 989px;
      margin: 0 auto;
      .win-list{
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #a5abb8;
        border-bottom: 1px #EDEBEB solid;
        div{
          width: 367px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 80px;
          .lis-img-t{
            text-indent: 10px;
          }
          .list-img{
            width: 50px;
            height: 50px;
            // border: 1px #000 solid;
            border-radius: 50%;
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }
          .list-name{
            width: 96px;
            text-overflow:ellipsis;
            overflow:hidden;
            white-space:nowrap;
          }
          .list-tel{
            width: 100px;
          }
        }
        
      }
    }
  }
  @media screen and (max-width: 1300px) {
    .container {
      width: 95vw;
    }
  }
  @media screen and (max-width: 1100px) {
    .top{
      background: url('../../assets/image/Questionnaire/wint2.png') no-repeat center center;
      background-size: cover;
    }
    .container {
      top: -100px;
      .win-main{
        width: 85vw;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .top{
      background: url('../../assets/image/Questionnaire/wint3.png') no-repeat center center;
      background-size: cover;
    }
    .container {
      padding-top: 0;
      .win-main{
        .win-list{
          div{
            width: 75vw;
            height: 70px;
          }
        }
      }
    }
  }
}
</style>