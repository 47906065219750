/**
 * api接口统一管理
 */
import {get, post} from './http'

//首页
export const apiDesktopIndex = p => get('api/desktop/index', p);
export const apiShowList = p => get('api/desktop/show/list', p);
export const apiPTotal = p => get('api/desktop/product/total', p);
export const apiDArticleList = p => get('api/desktop/article/list', p);
export const apiProductTop = p => get('api/desktop/product/top', p);
export const apiProductNew = p => get('api/desktop/product/new', p);
// export const apiNewTransaction = p => get('api/desktop/new/transaction', p);

//随机客服
export const apiCustomerRandom = p => get('api/customer/random', p);
//公告页面
export const apiArticleCategory = p => get('api/article/category', p);
export const apiArticleList = p => get('api/article/list', p);
//我要卖号 游戏账号
export const apiProductAdd = p => post('api/product/add', p);
//我要卖号 游戏金币
export const apiGoldAdd = p => post('api/gold/add', p);
//我要卖号 游戏金币
export const apiEquipAdd = p => post('api/equip/add', p);

//我要卖号 游戏账号 自主截图
export const apiUploadPicture = p => post('api/upload/picture', p);

//我要卖号 游戏装备 详情图片
export const apiUploadEquip = p => post('api/upload/equip', p);
//我要卖号 游戏装备 价值证明
export const apiUploadProve = p => post('api/upload/prove', p);

//全部游戏
export const apiGameList = p => get('api/game/list', p);
export const apiGameClass = p => get('api/game/class', p);
//游戏筛选列表 属性查询
export const apiProductCategory = p => get('api/product/category', p); //游戏账号
export const apiGoldCategory = p => get('api/gold/category', p); //游戏金币
export const apiEquipCategory = p => get('api/equip/category', p); //游戏装备
//游戏筛选列表 二级属性查询
export const apiProductPcate = p => get('api/product/pcate', p); //游戏账号
export const apiGoldAttr = p => get('api/gold/attr', p); //游戏金币
export const apiEquipAttr = p => get('api/equip/attr', p); //游戏金币
//游戏筛选列表 游戏账号列表
export const apiProductList = p => get('api/product/list', p);
export const apiEquipList = p => get('api/equip/list', p);
export const apiGoldList = p => get('api/gold/list', p);
//帐号详情
export const apiProductInfo = p => get('api/product/info', p);
//装备详情
export const apiEquipInfo = p => get('api/equip/info', p);
//金币详情
export const apiGoldInfo = p => get('api/gold/info', p);
//账号详情 收藏
export const apiCollectSet = p => post('api/collect/set', p);
//装备详情 收藏
export const apiCollectEquip = p => post('api/collect/equip', p);
//金币详情 收藏
export const apiCollectGold = p => post('api/collect/gold', p);

//登录
export const apiLogin = p => post('api/login', p);
export const apiSmsLogin = p => post('api/smslogin', p);
export const apiLogout = p => post('api/out', p);
//注册
export const apiRegister = p => post('api/register', p);
//忘记密码
export const apiReset = p => post('api/reset', p);
//发送验证码
export const apiSmsSend = p => get('api/sms/send', p);
//换绑手机 验证验证码
export const apiSmsCheck = p => post('api/sms/check', p);
export const apiChangePhone = p => post('api/user/change/phone', p);
//黑号查询 & 客服账号验证
export const apiBlackCheck = p => get('api/black/check', p);
export const apiCusVerify = p => get('api/customer/verify', p);
//个人中心 我的账号
export const apiuserInformation = p => get('api/user/information', p);
//用户基本信息
export const apiuserInfo = p => get('api/user/info', p);
//个人中心 个人信息
export const apiUserSave = p => post('api/user/save', p);
export const apiUserChangepwd = p => post('api/user/changepwd', p);
export const apiUserPaypwd = p => post('api/user/paypwd', p);
//个人中心 基本信息 头像上传
export const apiUploadPage = p => post('api/upload/page', p);
//个人中心 基本信息 实名认证
export const apiUserVerifed = p => post('api/user/verified', p);
export const apiUploadAuth = p => post('api/upload/auth', p);
//个人中心 基本信息 设置体现账户
export const apiUserSetAccount = p => post('api/user/setaccount', p);
export const apiBankName = p => get('api/bank/name', p);
//个人中心 我是卖家 账号列表
export const apiSellerAccountList = p => get('api/user/seller/account/list', p);
//个人中心 我是卖家 金币列表
export const apiSellerGoldList = p => get('api/gold/sell/list', p);
export const apirGoldInventory = p => post('api/gold/inventory', p); //修改库存
export const apirGoldOffshelf = p => post('api/gold/offshelf', p); //下架
export const apirGoldGore = p => post('api/gold/gore', p); //顶一顶
//个人中心 我是卖家 装备列表
export const apiSellerEquipList = p => get('api/equip/sell/list', p);
export const apirEquipInventory = p => post('api/equip/inventory', p); //修改库存
export const apirEquipOffshelf = p => post('api/equip/offshelf', p); //下架
export const apirEquipGore = p => post('api/equip/gore', p); //顶一顶
//个人中心 我是卖家 降价请求
export const apiSellerReduRequest = p => get('api/bargain/list/sale', p);
export const apiSellerReduRequestEquip = p => get('api/bargain/list/saleequip', p);

//我是卖家 修改价格
export const apiProductPrice = p => post('api/product/price', p);
//我是卖家 修改账号密码
export const apiProductEditinfo = p => get('api/product/editinfo', p);
export const apiProductAccount = p => post('api/product/account', p);
//顶一顶 下架 重选上架
export const apiProductGore = p => post('api/product/gore', p);
export const apiProductOff = p => post('api/product/offshelf', p);
export const apiProductOn = p => post('api/product/onshelf', p);
//个人中心 我是买家 账号订单
export const apiBuyAccountList = p => get('api/user/buy/account/list', p);
//个人中心 我是买家 金币订单
export const apiBuyGoldList = p => get('api/order/list/gold', p);
//个人中心 我是买家 装备订单
export const apiBuyEquipList = p => get('api/order/list/equip', p);
//个人中心 我是买家 游戏账号 降价请求列表
export const apiBuyBargainList = p => get('api/bargain/list/buy', p);
//个人中心 我是买家 游戏装备 降价请求列表
export const apiBuyBargainListEquip = p => get('api/bargain/list/buyequip', p);
export const apiBuyBargainStatus = p => post('api/bargain/status', p);
export const apiBuyBargainEquipStatus = p => post('api/bargain/equipstatus', p);
//游戏账号 修改 发起降价请求
export const apiBargainEdit = p => post('api/bargain/edit', p);
//游戏账号 添加 发起降价请求
export const apiBargainAdd = p => post('api/bargain/add', p);
//游戏装备 添加 发起降价请求
export const apiBargainAddEquip = p => post('api/bargain/equip', p);
//发起降价请求点击量
export const apiBargainClcik = p => post('api/product/click', p);
//个人中心 我的收藏 游戏账号
export const apiCollectList = p => get('api/collect/list', p);
//个人中心 我的收藏 游戏装备
export const apiCollectListEquip = p => get('api/collect/listequip', p);
//个人中心 我的收藏 游戏金币
export const apiCollectListGold = p => get('api/collect/listgold', p);
//财务中心 充值
export const apiUserRecharge = p => post('api/user/recharge', p);
export const apiRechargeInfo = p => get('api/user/recharge/info', p);
//财务中心 提现
export const apiUserCashAdd = p => post('api/user/cash/add', p);
//财务中心 提现记录
export const apiUserCashList = p => get('api/user/cash/list', p);
export const apiUserCashStatus = p => post('api/user/cash/status', p);

//财务中心 收支明细
export const apiUserBillList = p => get('api/user/bill/list', p);
//个人中心 站内通知
export const apiStationList = p => get('api/station/list', p);
export const apiStationRead = p => post('api/station/read', p);
//账号交易 账号回收
export const apiRecyclingList = p => get('api/recycling/list', p);
export const apiRecyclingGameList = p => get('api/recycling/game/list', p);
export const apiRecyclingInfo = p => get('api/recycling/info', p);
//顶部 搜索
export const apiPSearchList = p => get('api/product/search/list', p);
//每日成交 全部
export const apiProductTotal = p => get('api/product/total', p);
//每日成交 昨日成交帐号详情
export const apiPDailyDetails = p => get('api/product/daily/details', p);
//每日成交 昨日成交装备详情
export const apiEquipyesterday = p => get('/api/order/list/equipyesterday', p);

//获取客服、售后、投诉链接
export const apiCustomerSys = p => get('api/setting/customer', p);
//导航菜单 下拉
export const apiNavPage = p => get('api/page/leftnav', p);
//顶部导航菜单
export const apiTopNav = p => get('api/desktop/topnav', p);
//单页面数据
export const apiPageInfo = p => get('api/page/info', p);
//客服服务中心
export const apiCusCateGory = p => get('api/customer/category', p);
export const apiCusList = p => get('api/customer/list', p);
export const apiGameCusSer = p => get('api/game/customer', p);
//游戏详情（含手续费率）
export const apiGameInfo = p => get('api/game/info', p);
//系统配置信息
export const apiSystem = p => get('api/setting/system', p);
//求降价状态更改
export const apiPremiumEdit = p => post('api/product/premium/status', p);

//昨天成交
export const apiYesterdayDeal = p => get('api/product/yesterday/deal', p);

//53保存用户访问的客服链接
export const apiUserAccessLink = p => post('api/user/access/link', p);


//websocket
export const apiBind = p => post('im/bind', p);
//房间列表
export const apiRoomList = p => get('im/room/list', p);
//买卖双发加入房间
export const apiRoomJoin = p => post('im/room/join', p);
//进入已绑定的房间
export const apiRoomEnter = p => post('im/room/enter', p);
//发消息
export const apiMsgSend = p => post('im/message/send', p);
//获取历史消息
export const apiMsgHistory = p => get('im/message/history', p);
//私聊
export const apiRoomCreate = p => post('im/room/create', p);
//退出房间
export const apiRoomKill = p => post('im/room/kill', p);

//根据用户输入的登录账号查询  在本平台交易次数
export const apiProductTransactions = p => get('api/product/transactions', p);

// 添加估价信息
export const apiValuation = p => post('api/assess/product/add', p);
// 估价信息详情
export const apiValuationDetail = p => get('api/assess/product/info', p);


//全部游戏列表 最近浏览
export const apiHistoryAdd = p => post('api/history/game/add', p);
export const apiHistoryList = p => get('api/history/game/list', p);
export const apiHistoryDel = p => post('api/history/game/del', p);

//商品浏览记录
export const apiHistoryProductAdd = p => post('api/history/product/add', p);
export const apiHistoryProductList = p => get('api/history/product/list', p);
//商品客服咨询记录
export const apiHistoryCusAdd = p => post('api/history/customer/add', p);
export const apiHistoryCusDel = p => post('api/history/customer/del', p);
export const apiHistoryCusList = p => get('api/history/customer/list', p);

//招聘列表
export const apiRecruitList = p => get('api/recruit/list', p);
export const apiRecruitInfo = p => get('api/recruit/Info', p);

//卖家合同
export const apiSellContract = (u,p) => post(u === 1?'api/contract/seller':'app/contract/seller', p);
//卖家合同上传身份证
export const apiSellCard = (u,p) => post(u===1?'api/upload/card':'app/upload/card', p);
export const appUploadAuth = (u,p) => post(u===1?'api/upload/auth':'app/upload/auth', p);
export const appUploadAuthCardBehind = (u,p) => post(u===1?'api/upload/authCardBehind':'app/upload/authCardBehind', p);
//买家合同
export const apiBuyerContract = (u,p) => post(u===1?'api/contract/buyer':'app/contract/buyer', p);
// 获取用户合同信息
export const apiuserContract = (u,p) => post(u===1?'api/contract/userContract':'app/contract/userContract', p);
// 获取合同订单号
export const apiContractDetails = (u,p) => post(u===1?'api/contract/contractDetails':'app/contract/contractDetails', p);

// 包赔
export const apiCompensations = p => post('api/contract/compensations', p);
// 问卷调查判断
export const apiGetstatus = p => post('api/survey/getstatus', p);
// 问卷调查获取问卷信息
export const apiGetsurvey = p => post('api/survey/getsurvey', p);
// 问卷调查提交
export const apiSurveyAdd = p => post('api/survey/add', p);
// 调研问卷中奖名单
export const apiGetwinner = p => post('api/survey/getwinner', p);

// 个人中心 合同信息列表
export const apiContractList = p => get('api/user/contract/list', p);
// 个人中心 合同信息-签署合同
export const apiContractSigned = p => post('api/user/contract/signed', p);
// 个人中心 合同信息-法大大实名认证
export const apiContractVerify = p => post('api/user/contract/fdd/personVerify', p);
// 个人中心 合同信息-合同下载
export const apiDownloadContract = p => get('api/user/contract/fdd/downLoadContract', p);
// 号商广告位
export const apiMerchantProduct = p => get('api/product/merchant/list', p);
// 帮助中心
export const apiHelpCenter = p => get('api/article/help', p);
// 帮助中心 全部问题
export const apiHelpAll = p => get('api/article/all', p);
// 帮助中心详情
export const apiHelpDetail = p => get('api/article/list', p);
// 帮助中心搜索
export const apiHelpSearch = p => post('api/article/search', p);
// 发布的账号审核失败 删除
export const apiProductDel = p => post('api/product/del', p);

//我是卖家求降价列表 批量删除
export const apiBargainSaleDel = p => post('api/bargain/sale/del', p);
//我是买家发起降价列表 批量删除
export const apiBargainBuyDel = p => post('api/bargain/bargain/del', p);
// 个人中心 合同信息-查看合同
export const apiViewContract = p => get('api/user/contract/fdd/viewContract', p);
// 个人中心 站内通知-一键已读
export const apiReadAll = p => post('api/readall', p);
// 搜索推荐
export const searchParticiple = p => get('api/search/participle', p);
// 商品详情 创建订单
export const apiOrderAdd = p => post('api/order/add', p);
// 商品详情 订单状态查询
export const apiOrderInfo = p => get('api/order/orderInfo', p);
// 商品详情 在线交易 支付接口s
export const apiOrderPay = p => post('api/order/pay', p);

