<template>
  <div class="my-emoji">
    <transition name="fade-in-linear">
      <div class="popup" v-show="visible">
        <div class="popup-content">
          <div class="emoji-content">
            <div v-show="emojiClassIndex == 0" class="emoji-list">
              <div class="emoji-item" v-for="(item,index) in emojiCompact" :key="index" @click="onClick(item)">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="emoji-class">
            <div class="item" v-for="(item,index) in classList"
                 :class="{'active':emojiClassIndex==index}"
                 :key="index"
                 @click="emojiClassIndex=index"
            >
              <img :src="item.src">
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!--  快捷语遮罩层  -->
    <div class="mask" v-show="visible" @click="visible=false"></div>
    <slot :open="()=>{visible=true}"></slot>
  </div>
</template>

<script>
export default {
  name: 'emoji',
  data() {
    return {
      visible: false,
      emojiClassIndex: 0,
      emojiCompact: require('./emoji.json'),
      classList: [
        { src: require('@/assets/image/chat/emoji.png') }
      ]
    }
  },
  methods: {
    onClick(item) {
      this.visible = false
      this.$emit('click', item)
    }
  }
}
</script>

<style scoped lang="less">
.my-emoji {
  display: inline;
  position: relative;

  .popup {
    z-index: 99;
    bottom: 44px;
    left: -187px;
    position: absolute;
    width: 400px;
    height: 384px;
    background: #FFFFFF;
    box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.3);
    border-radius: 10px;

    &::before {
      position: absolute;
      width: 24px;
      height: 24px;
      content: '';
      background: #FFFFFF;
      bottom: -8px;
      left: calc(50% - 12px);
      box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.3);
      transform: rotate(45deg) skewX(8deg) skewY(8deg);
    }

    .popup-content {
      position: relative;
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      border-radius: 10px;
      overflow: auto;
      display: flex;
      flex-direction: column;

      .emoji-content {
        flex: 1;

        .emoji-list {
          display: grid;
          grid-template-columns: repeat(auto-fill, 40px);
          justify-content: space-around;
          padding: 15px 10px 10px;

          .emoji-item {
            cursor: default;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 1.7rem;
            border-radius: 5px;

            &:hover {
              background-color: #f2f2f2;
              font-size: 1.9rem;
            }
          }
        }
      }

      .emoji-class {
        position: relative;
        min-height: 50px;
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        padding-left: 20px;

        .item {
          width: 40px;
          height: 40px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;

          &:hover, &.active {
            background-color: #f2f2f2;
          }
        }
      }
    }
  }

  .mask {
    position: fixed;
    z-index: 98;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
}
</style>
