// 在http.js中引入axios
import axios from 'axios';
import md5 from 'js-md5'
import router from '@/router/index'
import evn from "../util/evn";
import {Message} from "element-ui";

import store from '@/store/index';

let refreshFlag = false

//重试队列
let requests = []

let service = axios.create({
  baseURL: evn.api,
  timeout: 30 * 1000,
})
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
service.interceptors.request.use(
  config => {
    const token = store.state.token;
    const refresh_token = store.state.refresh_token;
    if(store.state.loginStatus){
      if (!refreshFlag) {
        config.headers.Authorization = token
        config.headers.LoginStatus = store.state.loginStatus
      }else {
        config.headers.refreshToken = refresh_token
      }
    } else {
      let num="";
      for(let i = 0; i < Math.floor(Math.random()*10) + 1; i++)
      {
        num+=Math.floor(Math.random()*10);
      }
      config.headers.token =  md5(num);
      config.headers.ApiToken = md5(new Date().getFullYear() + '' +(new Date().getMonth()+1 < 10 ? '0'+(new Date().getMonth()+1) : new Date().getMonth()+1) +'UU_GAME'+md5(num));
      config.headers.LoginStatus = store.state.loginStatus
    }
    return config;
  },
  error => {
    return Promise.error(error);
  }
)
// 响应拦截器
service.interceptors.response.use(
  response => {
    if (response.data.code === 20000) {
      if (!refreshFlag) {
        refreshFlag = true
        //调用刷新token的接口
        return post('api/refresh/token').then(res => {
          let token = res.data.token
          // 替换token
          store.commit('set_token', res.data.token)
          store.commit('set_refresh_token', res.data.refresh_token)
          refreshFlag = false
          response.headers.Authorization = `${token}`
          // token 刷新后将数组的方法重新执行
          requests.forEach((cb) => cb(token))
          requests = [] // 重新请求完清空
          return service(response.config)
        }).catch(err => {
          //跳到登录页
          Message({
            type: "error",
            center: true,
            message: '登录过期，请重新登录！'
          })
          store.commit('del_token')
          router.push('/login')
          return Promise.reject(err)
        }).finally(() => {
          refreshFlag = false
        })
      } else {
        // 返回未执行 resolve 的 Promise
        return new Promise(resolve => {
          // 用函数形式将 resolve 存入，等待刷新后再执行
          requests.push(token => {
            response.headers.Authorization = `${token}`
            resolve(service(response.config))
          })
        })
      }
    }
    if (response.data.code === 200) {
      return Promise.resolve(response);
    }
    else {
      return Promise.reject(response);
    }
  },
  async error => {
    if (error.data.code) {
      return Promise.reject(error.response);
    }
  }
)



/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

export function get(url, params){

  return new Promise((resolve, reject) => {
    service.get(url, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err =>{
      reject(err.data)
    })
  })
}


/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    service.post(url,params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err =>{
        reject(err.data)
      })
  });
}

