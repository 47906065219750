export default {
  // api: 'https://api.pxb7.com/',
  api: 'https://api.uushouyou.com/',
  // 预生产接口地址
  // api: 'http://release-api.pxb7.com/',

  chatApi: 'https://api.pxb7.com/im/',
  // chatApi: 'https://api-demo.pxb7.com/im/',

  // socketApi: 'wss://api.pxb7.com:7909',
  // socketApi: 'wss://api-demo.pxb7.com:8080',

  // https://wsservice.pxb7.com
  IMApi: 'https://wsservice.pxb7.com/',
  IMSApi: 'https://wsapi.pxb7.com/',
  // wss://wsservice.pxb7.com
  // ID pxb7pcwebsadfjlk
  // key sadfjlkpxb7pcweb
  IMSocketApi: 'wss://wsservice.pxb7.com/1.0?appid=pxb7pcwebsadfjlk'
}

