<template>
    <el-input :placeholder="placeholder" v-model.trim="password"
              :key="passwordType"
              :type="passwordType"
              :autocomplete="autocomplete?'new-password':''"
              ref="password">
      <slot name="myprefix" slot="prefix" >
        <i slot="prefix" class="el-input__icon iconfont pxb7-login-pwd"></i>
      </slot>
      <i slot="suffix" class="el-input__icon show-pwd iconfont" :class="passwordType === 'password' ? 'pxb7-eye' : 'pxb7-eye-close'" @click="showPwd"></i>
    </el-input>
</template>

<script>
export default {
  name: "inputPwd",
  props:{
    placeholder: {
      type: String,
      default() {
        return ''
      }
    },
    pwd: {
      type: String,
      default() {
        return ''
      }
    },
    autocomplete:{
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data(){
    return{
      password:'',
      passwordType: 'password',

    }
  },
  methods:{
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
  },
  watch:{
    pwd(val){
      this.password = val
    },
    password(val){
      this.$emit('inputChange',val)
    }

  }

}
</script>

<style scoped lang="less">
.show-pwd{
  cursor: pointer;
}

</style>
