<template>
  <div>
    <div class="pop_alert" v-if="show">
      <vueCropper
        class="cropper"
        @mouseenter.native="enter"
        @mouseleave.native="leave"
        @realTime="realTime"
        ref="cropper"
        :img="uploadImg"
        :outputSize="option.size"
        :outputType="option.outputType"
        :info="true"
        :full="option.full"
        :canMove="option.canMove"
        :canMoveBox="option.canMoveBox"
        :original="option.original"
        :autoCrop="option.autoCrop"
        :fixed="option.fixed"
        :fixedNumber="option.fixedNumber"
        :centerBox="option.centerBox"
        :infoTrue="option.infoTrue"
        :fixedBox="option.fixedBox"
        style="background-image:none"
      >
      </vueCropper>
      <div class="btn_box" :style="imgStyle">
        <div class="btn_red" @click="close"><i class="el-icon-close"></i></div>
        <div class="brtn_green" @click="save"><i class="el-icon-check"></i></div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  props: {
    returnUrl: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
      option: {
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: false, // 是否默认生成截图框
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [7, 5], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMove: false, //时候可以移动原图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      uploadImg: "",
      show: false,
      imgStyle:''
    }
  },
  methods: {
    realTime(e) {
      let dom = document.querySelector('.cropper-crop-box').getBoundingClientRect()
      this.imgStyle = `left:${dom.left + e.w - 100}px;top:${dom.top + e.h + 10}px`;
    },
    tailoring() {            //裁剪
      this.$nextTick(() => {
        this.$html2canvas(document.body, {
          useCORS: true,
        }).then(canvas => {
          let context = canvas.getContext('2d');
          //设定填充图形的样式
          context.fillStyle = "#f4f4f4";
          //绘制图形
          context.fillRect(0,0,150,75);
          let dataURL = canvas.toDataURL('image/png', 1.0).replace('image/png', 'image/octet-stream');
          // dataURL = canvas.toDataURL("image/png");
          this.uploadImg = dataURL
          this.show = true
        });
      })
    },
    enter() {
      if (this.uploadImg == "") {
        return;
      }
      this.$refs.cropper.startCrop(); //开始裁剪
    },
    leave() {
      this.$refs.cropper.stopCrop();//停止裁剪
    },
    save() {        //确认截图
      // this.$refs.cropper.getCropData((data) => {      //获取截图的base64格式数据
      //   this.returnUrl(data)
      // this.$emit('upload-img', data);
      //   this.show = false
      // })
      this.$refs.cropper.getCropBlob(data => { //获取截图的Blob格式数据
        // this.downFileToLocal('a',data)
        this.returnUrl(data)
        this.$emit('upload-img', data);
        this.show = false
      });
    },
    downFileToLocal(fileName, blob) {
        // 创建用于下载文件的a标签
        const d = document.createElement('a')
        // 设置下载内容
        d.href = window.URL.createObjectURL(blob)
        // 设置下载文件的名字
        d.download = fileName
        // 界面上隐藏该按钮
        // d.style.display = 'none'
        // 放到页面上
        document.body.appendChild(d)
        console.log(d);
        // 点击下载文件
        // d.click()
        // 从页面移除掉
        // document.body.removeChild(d)
        // 释放 URL.createObjectURL() 创建的 URL 对象
        // window.URL.revokeObjectURL(d.href)
    },
    close() {        //取消
      this.show = false
    },
  },
}
</script>
<style lang="less">
.pop_alert {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px dashed #fcad13;
  background-color: #000000;
  .btn_box {
    position: absolute;
    // bottom: 15px;
    // right: 15px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 6666;
    width: 100px;
    height: 40px;
    background: #363636;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    .btn_red{
      color: red;
    }
    .brtn_green{
      color: green;
    }
  }
}

</style>
