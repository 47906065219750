import axios from 'axios'
import {Message} from 'element-ui'
import store from '@/store/index';
import evn from "../../util/evn";

// create an axios instance
const service = axios.create({
  baseURL: evn.chatApi,
  timeout: 60 * 1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
    if (!config.notToken) {
      config.headers['Authorization'] = store.state.token
    }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 200) {
      Message({
        message: res.msg || res.message || '服务器异常',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: '服务器异常',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)


export default service
