<template>
  <div class="textarea-wrapper">
    <div class="edit-div "
         id="edit-div"
         :class="editPlaceholder && !value?'edit-Focus':''"
         v-html="innerText"
         :contenteditable="canEdit"
         @focus="handleFocus"
         @blur="handleBlur"
         @compositionstart="lock=true"
         @compositionend="onCompositionEnd"
         @paste="textInit"
         @input="changeText"
         @keydown.enter="handleEnter"
    >
    </div>
    <div class="reminder" @click="switchover">
      {{ isEnterSend ? '回车发送消息，点击切换Ctrl+回车' : 'Ctrl+回车发送消息，点击切换回车' }}
    </div>
    <div v-show="memberVisible" class="member-box" ref="memberBox" :style="`left: ${left}px;top: ${top}px`"
         @mousemove="isHoverMemberBox=true" @mouseout="isHoverMemberBox=false"
    >
      <div class="member-list">
        <div class="item" @click="handleClickAT" @mouseover="handleHoverAT(index)"
             :class="{'active':index==memberIndex}"
             v-for="(item,index) in memberList"
             :key="item.user_id"
        >
          <el-image class="avatar" :src="item.avtar">
            <el-image slot="error" :src="require('@/assets/image/chat/active.jpg')"></el-image>
          </el-image>
          <div class="name">{{ item.userName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'myTextarea',
  props: {
    value: {
      type: String,
      default: ''
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    members: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      innerText: `<div></div>`,
      isLocked: false,
      lock: false,
      //@成员列表是否显示
      memberVisible: false,
      memberList: [],
      memberIndex: 0,
      //@出现的下标
      startIndex: 0,
      lastLenght: 0,
      left: 0,
      top: 0,
      //鼠标是否悬浮在@成员列表
      isHoverMemberBox: false,
      //是否回车发送消息
      isEnterSend: true,
      // 定义最后光标对象
      lastEditRange: null,
      editPlaceholder:true
    }
  },
  watch: {
    'value'(val) {
      if (!this.isLocked || !this.innerText) {
        this.innerText = this.value
        document.getElementById('edit-div').innerHTML = this.innerText
      }
    },
    members(val) {
      this.memberList = this.members.filter(item => !item.is_me)
    }
  },
  created() {
    let chatIsEnterSend = localStorage.getItem('chatIsEnterSend')
    if (chatIsEnterSend == '1') {
      this.isEnterSend = false
    }
  },
  mounted() {
    this.keyDown()
  },
  methods: {
    //插入文本
    insertText(value) {
      // 获取编辑框对象
      let edit = document.getElementById('edit-div')
      // 编辑框设置焦点
      edit.focus()
      // 获取选定对象
      let selection = getSelection()
      // 判断是否有最后光标对象存在
      if (this.lastEditRange) {
        // 存在最后光标对象，选定对象清除所有光标并添加最后光标还原之前的状态
        selection.removeAllRanges()
        selection.addRange(this.lastEditRange)
      }
      // 判断选定对象范围是编辑框还是文本节点
      if (selection.anchorNode.nodeName != '#text') {
        // 如果是编辑框范围。则创建表情文本节点进行插入
        let emojiText = document.createTextNode(value)
        if (edit.childNodes.length > 0) {
          // 如果文本框的子元素大于0，则表示有其他元素，则按照位置插入表情节点
          for (let i = 0; i < edit.childNodes.length; i++) {
            if (i == selection.anchorOffset) {
              edit.insertBefore(emojiText, edit.childNodes[i])
            }
          }
        } else {
          // 否则直接插入一个表情元素
          edit.appendChild(emojiText)
        }
        // 创建新的光标对象
        let range = document.createRange()
        // 光标对象的范围界定为新建的表情节点
        range.selectNodeContents(emojiText)
        // 光标位置定位在表情节点的最大长度
        range.setStart(emojiText, emojiText.length)
        // 使光标开始和光标结束重叠
        range.collapse(true)
        // 清除选定对象的所有光标对象
        selection.removeAllRanges()
        // 插入新的光标对象
        selection.addRange(range)
      } else {
        // 如果是文本节点则先获取光标对象
        let range = selection.getRangeAt(0)
        // 获取光标对象的范围界定对象，一般就是textNode对象
        let textNode = range.startContainer
        // 获取光标位置
        let rangeStartOffset = range.startOffset
        // 文本节点在光标位置处插入新的表情内容
        textNode.insertData(rangeStartOffset, value)
        // 光标移动到到原来的位置加上新内容的长度
        range.setStart(textNode, rangeStartOffset + value.length)
        // 光标开始和光标结束重叠
        range.collapse(true)
        // 清除选定对象的所有光标对象
        selection.removeAllRanges()
        // 插入新的光标对象
        selection.addRange(range)
      }
      // 无论如何都要记录最后光标对象
      this.lastEditRange = selection.getRangeAt(0)
      this.$emit('input', document.getElementById('edit-div').innerHTML)
    },
    //切换发送消息
    switchover() {
      this.isEnterSend = !this.isEnterSend
      localStorage.setItem('chatIsEnterSend', this.isEnterSend ? '0' : '1')
    },
    handleBlur() {
      this.editPlaceholder = true
      if (!this.isHoverMemberBox) {
        this.isLocked = false
        this.memberVisible = false
      }
      let selection = window.getSelection()
      this.lastEditRange = selection.getRangeAt(0)
    },
    handleFocus(){
      this.editPlaceholder = false
      this.isLocked = true
    },

    handleHoverAT(index) {
      this.memberIndex = index
    },
    handleClickAT() {
      let member = this.memberList[this.memberIndex]
      let innerText = document.getElementById('edit-div').innerHTML
      let str = innerText.substring(0, innerText.lastIndexOf('@'))
      str += `<span class="at-text" data-id="${member.user_id}" data-role-id="${member.role_id}">@${member.nickname}</span>&nbsp;` + innerText.substring(innerText.lastIndexOf('@') + 1, innerText.length)
      this.innerText = str
      document.getElementById('edit-div').innerHTML = str
      this.$emit('input', str)
      this.memberVisible = false
      setTimeout(() => {
        this.keepLastIndex(document.getElementById('edit-div'))
      }, 50)
    },
    keyDown() {
      // 监听键盘
      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
        //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
        if (this.memberVisible) {
          if (e1 && e1.keyCode == 38) {
            // 按下上箭头
            this.memberIndex = this.memberIndex - 1 >= 0 ? this.memberIndex - 1 : this.memberList.length - 1
            e.preventDefault()
          } else if (e1 && e1.keyCode == 40) {
            // 按下下箭头
            this.memberIndex = this.memberIndex + 1 < this.memberList.length ? this.memberIndex + 1 : 0
            e.preventDefault()
          } else if (e1 && e1.keyCode == 13) {
            // 按下回车键
            e.preventDefault()
            this.handleClickAT()
          }
        }
        if (e.keyCode === 8) {
          // 删除逻辑
          // 1 ：因为在建立时默认会在 @xxx 后添加一个空格，
          // 因此当得知光标位于 @xxx 以后的一个第一个字符后并按下删除按钮时，
          // 应该将光标前的 @xxx 给删除
          // 2 ：当光标位于 @xxx 中间时，按下删除按钮时应该将整个 @xxx 给删除。
          let range = window.getSelection().getRangeAt(0)
          let removeNode = null
          if (range.startOffset <= 1 && range.startContainer.parentElement.className != 'at-text') {
            removeNode = range.startContainer.previousElementSibling
          }
          if (range.startContainer.parentElement.className == 'at-text') {
            removeNode = range.startContainer.parentElement
          }
          if (removeNode) {
            document.getElementById('edit-div').removeChild(removeNode)
          }
        }
      }
    },
    handleEnter(e) {
      if (!this.memberVisible) {
        e.preventDefault()
        if ((e.ctrlKey == false && this.isEnterSend) || (e.ctrlKey == true && !this.isEnterSend)) {
          this.$emit('ctrl-enter')
          this.isLocked = false
          return
        }
        let innerText = document.getElementById('edit-div').innerHTML
        this.innerText = innerText += `<div><br/></div>`
        this.$emit('input', this.innerText)
        this.$nextTick(() => {
          this.keepLastIndex(document.getElementById('edit-div'))
        })
      }
    },
    keepLastIndex(obj) {
      let range, selection
      if (document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
      {
        range = document.createRange()//Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(obj)//Select the entire contents of the element with the range
        range.collapse(false)//collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection()//get the selection object (allows you to change selection)
        selection.removeAllRanges()//remove any selections already made
        selection.addRange(range)//make the range you have just created the visible selection
      } else if (document.selection)//IE 8 and lower
      {
        range = document.body.createTextRange()//Create a range (a range is a like the selection but invisible)
        range.moveToElementText(obj)//Select the entire contents of the element with the range
        range.collapse(false)//collapse the range to the end point. false means collapse to end rather than the start
        range.select()//Select the range (make it the visible selection
      }
    },
    //输入框input事件
    changeText(e) {
      if (!this.isLocked) this.isLocked = true
      if (this.lock) return true
      this.$emit('input', e.target.innerHTML.replace(/<div><\/div>/g, ''))
      //去除标签
      let regx = /<[^>]*>|<\/[^>]*>/gm
      let str = e.target.innerHTML.replace(regx, '')
      this.startIndex = str.lastIndexOf('@')
      //出现@字符，显示成员列表
      if (str.lastIndexOf('@') === -1) {
        this.memberVisible = false
      } else if (this.startIndex === str.length - 1) {
        this.memberVisible = true
      }
      //判断是否进行成员名称匹配
      if (this.memberVisible || (this.lastLenght !== 0 && str.length - this.startIndex - 2 <= this.lastLenght)) {
        //获取输入的字符串
        let name = str.substring(this.startIndex + 1)
        //为空的话显示所有成员
        if (name === '') {
          this.memberList = this.members.filter(item => !item.is_me)
        } else {
          //过滤出输入的成员
          this.memberList = this.members.filter(item => {
            return (item.userName.indexOf(name) > 0 || item.pinyin.indexOf(name) > 0 || item.szm.indexOf(name) > 0) && !item.is_me
          })
          this.memberIndex = 0
          //没有匹配的成员的话隐藏@成员列表框
          if (this.memberList.length === 0) {
            this.memberVisible = false
            //最后一次匹配成功的字符长度
            this.lastLenght = str.length - this.startIndex - 2
          } else if (!this.memberVisible) this.memberVisible = true
        }
        this.$nextTick(() => {
          //获取@成员列表高度
          let memberBoxHeight = this.$refs.memberBox.clientHeight
          //获取光标位置并复制给@成员列表
          let {x, y} = this.getSelectionCoords()
          this.left = x - 70
          this.top = y - memberBoxHeight - 14
        })
      }
    },
    //中文输入结束事件
    onCompositionEnd(e) {
      this.lock = false
      this.changeText(e)
    },
    //获取光标位置
    getSelectionCoords(win) {
      win = win || window
      let doc = win.document
      let sel = doc.selection, range, rects, rect
      let x = 0, y = 0
      if (sel) {
        if (sel.type != 'Control') {
          range = sel.createRange()
          range.collapse(true)
          x = range.boundingLeft
          y = range.boundingTop
        }
      } else if (win.getSelection) {
        sel = win.getSelection()
        if (sel.rangeCount) {
          range = sel.getRangeAt(0).cloneRange()
          if (range.getClientRects) {
            range.collapse(true)
            rects = range.getClientRects()
            if (rects.length > 0) {
              rect = rects[0]
            }
            // 光标在行首时，rect为undefined
            if (rect) {
              x = rect.left
              y = rect.top
            }
          }
          // Fall back to inserting a temporary element
          if ((x == 0 && y == 0) || rect === undefined) {
            let span = doc.createElement('span')
            if (span.getClientRects) {
              // Ensure span has dimensions and position by
              // adding a zero-width space character
              span.appendChild(doc.createTextNode('\u200b'))
              range.insertNode(span)
              rect = span.getClientRects()[0]
              x = rect.left
              y = rect.top
              let spanParent = span.parentNode
              spanParent.removeChild(span)

              // Glue any broken text nodes back together
              spanParent.normalize()
            }
          }
        }
      }
      return {x: x, y: y}
    },
    //复制是去除css样式
    async textInit(e) {
      e.preventDefault()
      let text
      let clp = (e.originalEvent || e).clipboardData
      if (clp === undefined || clp === null) {
        text = window.clipboardData.getData('text') || ''
        let img = await this.pasteImg(clp)
        if (window.getSelection) {
          if (text !== '') {
            let newNode = document.createElement('span')
            newNode.innerHTML = text
            window.getSelection().getRangeAt(0).insertNode(newNode)
          }
          if (img !== '') {
            window.getSelection().getRangeAt(0).insertNode(img)
          }
        } else {
          if (text !== '') {
            document.selection.createRange().pasteHTML(text)
          }
          if (img !== '') {
            document.selection.createRange().pasteHTML(img.outerHTML)
          }
        }
      } else {
        text = clp.getData('text/plain') || ''
        let img = await this.pasteImg(clp)
        if (img !== '') {
          document.execCommand('insertHTML', false, img.outerHTML)
        }
        if (text !== '') {
          document.execCommand('insertText', false, text)
        }
      }
    },
    pasteImg(clp) {
        return new Promise(resolve => {
          let type = clp.items[0].type
          if (type.match(/image/)) {
            let blob = clp.items[0].getAsFile()
            let file = new FileReader()
            file.addEventListener('loadend', function (e) {
              let img = document.createElement('img')
              img.src = e.target.result
              img.style.maxWidth = '100px'
              resolve(img)
            })
            file.readAsDataURL(blob)
          } else {
            resolve('')
          }
        })

    }
  }
}
</script>
<style scoped lang="less">
.edit-div {
  width: 100%;
  height: 100%;
  overflow: auto;
  word-break: break-all;
  outline: none;
  user-select: text;
  white-space: pre-wrap;
  text-align: left;
  background: #FFFFFF;
  border-radius: 5px 5px 5px 5px;
  border: #FFFFFF 1px solid;
  padding: 5px;

  .at-text {

  }
}

.edit-Focus::before{
  content: '请输入文字...';
  font-size: 14px;
  font-family: PingFang SC-Light, PingFang SC;
  font-weight: 400;
  color: #8B8B8B;
  line-height: 16px;
}

.member-box {
  position: fixed;
  z-index: 999;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);

  &::after {
    position: absolute;
    z-index: -1;
    width: 24px;
    height: 24px;
    content: '';
    background: #FFFFFF;
    bottom: -3px;
    left: calc(50% - 12px);
    transform: rotate(45deg) skewX(8deg) skewY(8deg);
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
  }

  .member-list {
    position: relative;
    z-index: 0;
    width: 248px;
    max-height: 137px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 4px;
    overflow-y: auto;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
      cursor: default;

      &.active {
        background-color: #FFFAF0;
      }

      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 4px 4px 4px 4px;
      }

      .name {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #282828;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 200px;
        margin-left: 4px;
      }
    }
  }
}
.textarea-wrapper{
  display: flex;
  flex-direction: column;
}
.reminder {
  text-align: center;
  align-self: flex-end;
  font-size: 12px;
  margin-top: 3px;
  cursor: pointer;
  width: 210px;
  height: 30px;
  line-height: 30px;
  background: #EAF1FA;
  border-radius: 20px 20px 20px 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #91ACCF;
}
</style>
