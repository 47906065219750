import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';

Vue.use(VueRouter)
import Layout from '@/views/Layout/index';
import page404 from '@/views/404';
import Login from '@/views/Login/index';
import ForgetPassword from '@/views/Login/ForgetPassword/index';
import Register from '@/views/Login/Register/index';
// import Download from '@/views/Download/index';
import BannerPage from '@/views/Home/BannerPage/index';
import SellerContract from '@/views/Contract/sellerContract.vue';
import BuyerContract from '@/views/Contract/buyerContract.vue';
import IndemnityContract from '@/views/Contract/indemnityContract.vue';
import SubmitSuccess from '@/views/Contract/submitSuccess.vue';
import Questionnaire from '@/views/Questionnaire/index';
import WinnersList from '@/views/Questionnaire/winnersList';
import HelpDetail from '@/views/HelpCenter/helpDetail';
import CustomerSer from '@/views/CustomerSer/index';


const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/',
    children: [
      // 首页
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home/index'),
        meta: {keepAlive: true}
      },
      // 中介担保
      {
        path: '/vouch',
        name: 'Vouch',
        component: () => import('@/views/Home/SellPop/vouch'),
        meta: {keepAlive: false}
      },
      // 客服中心
      {
        path: '/home/cusSerCenter',
        name: 'CusSerCenter',
        component: () => import('@/views/Home/CusSerCenter/index'),
        meta: {
          keepAlive: false,
          auth: true
        }
      },
      //每日成交 更多
      {
        path: '/home/dailyTransaction',
        name: 'DailyTransaction',
        component: () => import('@/views/Home/DailyTransaction/index'),
        meta: {keepAlive: true}
      },
      //每日成交详情
      {
        path: '/home/dailyTxDetails',
        name: 'DailyTxDetails',
        component: () => import('@/views/Home/DailyTxDetails/index'),
        meta: {keepAlive: false}
      },
      //装备每日成交详情
      {
        path: '/allGame/dailyTxDetailsEquip',
        name: 'DailyTxDetailsEquip',
        component: () => import('@/views/AllGame/DailyTxDetailsEquip/index'),
        meta: {keepAlive: false}
      },
      //公告 更多
      {
        path: '/home/announcement',
        name: 'Announcement',
        component: () => import('@/views/Home/Announcement/index'),
        meta: {keepAlive: false}
      },
      //文章
      {
        path: '/home/article',
        name: 'Article',
        component: () => import('@/views/Home/Article/index'),
        meta: {keepAlive: false}
      },
      // 账号回收
      {
        path: 'accRecycle',
        name: 'AccountRecycle',
        component: () => import('@/views/AccountDeal/AccountRecycle/index'),
        meta: {keepAlive: false}
      },
      // 号商详情
      {
        path: '/accountRecycle/recyclingInfo',
        name: 'RecyclingInfo',
        component: () => import('@/views/AccountDeal/AccountRecycle/RecyclingInfo/index'),
        meta: {keepAlive: true}
      },
      // 我要卖号
      {
        path: 'accountSell',
        name: 'AccountSell',
        meta: {auth: true, keepAlive: false},
        component: () => import('@/views/AccountDeal/AccountSell/new')
      },
      // 账号估价
      {
        path: '/accountValuation',
        name: 'AccountValuation',
        meta: {auth: true, keepAlive: false},
        component: () => import('@/views/AccountValuation/index')
      },
      // 查看估价信息
      {
        path: '/valuationInformation',
        name: 'ValuationInformation',
        meta: {auth: true, keepAlive: false},
        component: () => import('@/views/AccountValuation/ValuationInformation/index')
      },
      // 关于我们
      {
        path: 'about',
        name: 'About',
        component: () => import('@/views/About/index'),
        meta: {keepAlive: false}
      },
      // 公司法务
      {
        path: 'legal',
        name: 'Legal',
        component: () => import('@/views/Legal/index'),
        meta: {keepAlive: false}
      },
      // 全部游戏
      {
        path: 'allGame',
        name: 'AllGame',
        component: () => import('@/views/AllGame/index'),
        meta: {keepAlive: false}
      },
      // 账号交易 我要买号
      {
        path: 'accountTrade/allGame',
        name: 'accountTradeAllGame',
        redirect: 'allGame',
        component: () => import('@/views/AllGame/index'),
        meta: {keepAlive: true}
      },
      // 游戏列表筛选
      {
        path: 'selectgame',
        name: 'SelectGame',
        component: () => import('@/views/AllGame/selectGame/index'),
        meta: {keepAlive: false}
      },
      // 账号详情
      {
        path: 'gamedetails',
        name: 'GameDetails',
        component: () => import('@/views/AllGame/gameDetails/index'),
        meta: {keepAlive: false}
      },
      // 金币详情
      {
        path: 'golddetails',
        name: 'GoldDetails',
        component: () => import('@/views/AllGame/goldDetails/index'),
        meta: {keepAlive: false}
      },
      // 装备详情
      {
        path: 'equipmentdetails',
        name: 'EquipmentDetails',
        component: () => import('@/views/AllGame/equipmentDetails/index'),
        meta: {keepAlive: false}
      },
      // 立即购买交易页面
      {
        path: 'onlineDeal',
        name: 'OnlineDeal',
        component: () => import('@/views/AllGame/onlineDeal/index')
      },
      //黑号验证
      {
        path: 'blacknumverify',
        name: 'BlackNumVerify',
        component: () => import('@/views/Verify/BlackNumVerify/index'),
        meta: {auth: true, keepAlive: false},
      },
      // 客服验证
      {
        path: 'cusserverify',
        name: 'CusSerVerify',
        component: () => import('@/views/Verify/CusSerVerify/index'),
        meta: {keepAlive: false}
      },
      // 个人中心
      {
        path: 'personalCenter',
        name: 'PersonalCenter',
        redirect: '/personalCenter/myAccount',
        meta: {auth: true, keepAlive: false},
        component: () => import('@/views/PersonalCenter/index'),
        children: [
          //我的账号
          {
            path: '/personalCenter/myAccount',
            name: 'MyAccount',
            component: resolve => require(['@/views/PersonalCenter/MyAccount/index'], resolve),
            meta: {keepAlive: false}
          },
          //我是卖家 游戏账号
          {
            path: '/personalCenter/seller',
            name: 'Seller',
            component: resolve => require(['@/views/PersonalCenter/Seller/index'], resolve),
            meta: {keepAlive: false}
          },
          //我是卖家 游戏金币
          {
            path: '/personalCenter/seller/gold',
            name: 'SellerGold',
            component: resolve => require(['@/views/PersonalCenter/Seller/gold'], resolve),
            meta: {keepAlive: false}
          },
          //我是卖家 游戏装备
          {
            path: '/personalCenter/seller/equip',
            name: 'SellerEquip',
            component: resolve => require(['@/views/PersonalCenter/Seller/equip'], resolve),
            meta: {keepAlive: false}
          },
/*          //我是卖家 收到的降价请求
          {
            path: '/personalCenter/seller/bargain',
            name: 'SellerBargain',
            component: resolve => require(['@/views/PersonalCenter/Seller/bargain'], resolve),
            meta: {keepAlive: false}
          },*/
          //我是买家 账号订单
          {
            path: '/personalCenter/buyer',
            name: 'Buyer',
            component: resolve => require(['@/views/PersonalCenter/Buyer/index'], resolve),
            meta: {keepAlive: false}
          },
          //我是买家 金币订单
          {
            path: '/personalCenter/buyer/gold',
            name: 'BuyerGold',
            component: resolve => require(['@/views/PersonalCenter/Buyer/gold'], resolve),
            meta: {keepAlive: false}
          },
          //我是买家 装备订单
          {
            path: '/personalCenter/buyer/equip',
            name: 'BuyerEquip',
            component: resolve => require(['@/views/PersonalCenter/Buyer/equip'], resolve),
            meta: {keepAlive: false}
          },
/*          //发起的降价请求
          {
            path: '/personalCenter/buyer/bargain',
            name: 'BuyerBargain',
            component: resolve => require(['@/views/PersonalCenter/Buyer/bargain'], resolve),
            meta: {keepAlive: false}
          },*/
/*          //我的收藏 游戏账号
          {
            path: '/personalCenter/myCollection',
            name: 'MyCollection',
            component: resolve => require(['@/views/PersonalCenter/MyCollection/index'], resolve),
            meta: {keepAlive: false}
          },
          //我的收藏 游戏金币
          {
            path: '/personalCenter/myCollection/gold',
            name: 'MyCollectionGold',
            component: resolve => require(['@/views/PersonalCenter/MyCollection/gold'], resolve),
            meta: {keepAlive: false}
          },
          //我的收藏 游戏装备
          {
            path: '/personalCenter/myCollection/equip',
            name: 'MyCollectionEquip',
            component: resolve => require(['@/views/PersonalCenter/MyCollection/equip'], resolve),
            meta: {keepAlive: false}
          },*/
          //个人中心
          {
            path: '/personalCenter/personalInfo',
            name: 'PersonalInfo',
            component: resolve => require(['@/views/PersonalCenter/PersonalInfo/index'], resolve),
            meta: {keepAlive: false},
            children: [
              //个人信息
              {
                path: '',
                name: 'BasicInfo',
                component: resolve => require(['@/views/PersonalCenter/PersonalInfo/BasicInfo/index'], resolve),
                meta: {keepAlive: false}
              },
              //实名认证
              {
                path: '/personalCenter/personalInfo/rNameAuthentication',
                name: 'RNameAuthentication',
                component: resolve => require(['@/views/PersonalCenter/PersonalInfo/RNameAuthentication/index'], resolve),
                meta: {keepAlive: false}
              },
              //修改登录密码
              {
                path: '/personalCenter/personalInfo/modifyLoginPwd',
                name: 'ModifyLoginPwd',
                component: resolve => require(['@/views/PersonalCenter/PersonalInfo/ModifyLoginPwd/index'], resolve),
                meta: {keepAlive: false}
              },
              //设置体现账户
              {
                path: '/personalCenter/personalInfo/withdrawalAccount',
                name: 'WithdrawalAccount',
                component: resolve => require(['@/views/PersonalCenter/PersonalInfo/WithdrawalAccount/index'], resolve),
                meta: {keepAlive: false}
              },
              //支付密码
              {
                path: '/personalCenter/personalInfo/paymentPwd',
                name: 'PaymentPwd',
                component: resolve => require(['@/views/PersonalCenter/PersonalInfo/PaymentPwd/index'], resolve),
                meta: {keepAlive: false}
              },
              //支付密码
              {
                path: '/personalCenter/personalInfo/changeTelphone',
                name: 'ChangeTelphone',
                component: resolve => require(['@/views/PersonalCenter/PersonalInfo/ChangeTelphone/index'], resolve),
                meta: {keepAlive: false}
              },
            ]
          },
          //财务中心
          {
            path: '/personalCenter/financialCenter',
            name: 'FinancialCenter',
            component: resolve => require(['@/views/PersonalCenter/FinancialCenter/index'], resolve),
            meta: {keepAlive: false},
            children: [
              //充值
              {
                path: '',
                name: 'Recharge',
                component: resolve => require(['@/views/PersonalCenter/FinancialCenter/Recharge/index'], resolve),
                meta: {keepAlive: false}
              },
              //提现
              {
                path: '/personalCenter/financialCenter/withdraw',
                name: 'Withdraw',
                component: resolve => require(['@/views/PersonalCenter/FinancialCenter/Withdraw/index'], resolve),
                meta: {keepAlive: false}
              },
              //提现记录
              {
                path: '/personalCenter/financialCenter/withdrawRecord',
                name: 'WithdrawRecord',
                component: resolve => require(['@/views/PersonalCenter/FinancialCenter/WithdrawRecord/index'], resolve),
                meta: {keepAlive: false}
              },
              //收支明细
              {
                path: '/personalCenter/financialCenter/moneyDetails',
                name: 'MoneyDetails',
                component: resolve => require(['@/views/PersonalCenter/FinancialCenter/MoneyDetails/index'], resolve),
                meta: {keepAlive: false}
              },
            ]
          },
          //站内通知
          {
            path: '/personalCenter/siteNotice',
            name: 'SiteNotice',
            component: resolve => require(['@/views/PersonalCenter/SiteNotice/index'], resolve),
            meta: {keepAlive: false}
          },
          //聊天室列表
          {
            path: '/chat/list',
            name: 'ChatList',
            component: resolve => require(['@/views/chat/list'], resolve),
            meta: {keepAlive: false}
          },
          //最近浏览
          {
            path: '/personalCenter/accHistory',
            name: 'AccHistory',
            component: resolve => require(['@/views/PersonalCenter/History/account'], resolve),
            meta: {keepAlive: false}
          },
          //咨询记录
          {
            path: '/personalCenter/serHistory',
            name: 'SerHistory',
            component: resolve => require(['@/views/PersonalCenter/History/service'], resolve),
            meta: {keepAlive: false}
          },
/*          //合同中心
          {
            path: '/personalCenter/contractCenter',
            name: 'ContractCenter',
            component: resolve => require(['@/views/PersonalCenter/ContractCenter/index'], resolve),
            meta: {keepAlive: false},
            children: [
              //合同信息
              {
                path: '',
                name: 'ContractInfo',
                component: resolve => require(['@/views/PersonalCenter/ContractCenter/ContractInfo/index'], resolve),
                meta: {keepAlive: false}
              },
            ]
          },*/
        ]
      },
      // 交易必看
      {
        path: 'tradeTip',
        name: 'TradeTip',
        component: () => import('@/views/TradeTip/index'),
        meta: {keepAlive: false}
      },
      // 中介担保
      {
        path: 'intermediaryGuarantee',
        name: 'IntermediaryGuarantee',
        component: () => import('@/views/TradeTip/intermediaryGuarantee'),
        meta: {keepAlive: true}
      },
      // 帮助中心
      {
        path: 'helpCenter',
        name: 'helpCenter',
        component: resolve => require(['@/views/HelpCenter/index'], resolve),
        meta: {keepAlive: false}
      },
      {
        path: '/helpCenter/search',
        name: 'helpCenterSearch',
        component: resolve => require(['@/views/HelpCenter/search'], resolve),
        meta: {keepAlive: false}
      },
    ]
  },
  // 404page
  {path: '/404', name: 'page404', component: page404},
  {path: '/login', name: 'Login', component: Login},
  {path: '/forgetpassword', name: 'ForgetPassword', component: ForgetPassword},
  {path: '/register', name: 'Register', component: Register},
  // {path: '/download', name: 'Download', component: Download},
  {path: '/bannerPage', name: 'BannerPage', component: BannerPage},
  {path: '/sellerContract', name: 'SellerContract', component: SellerContract},
  {path: '/buyerContract', name: 'BuyerContract', component: BuyerContract},
  {path: '/indemnityContract', name: 'IndemnityContract', component: IndemnityContract},
  {path: '/submitSuccess', name: 'SubmitSuccess', meta: {auth: true}, component: SubmitSuccess},
  {path: '/questionnaire', name: 'Questionnaire', component: Questionnaire},
  {path: '/winnersList', name: 'WinnersList', component: WinnersList},
  {path: '/customerSer', name: 'CustomerSer', meta: {auth: true}, component: CustomerSer},
  // {path: '/chatRoom', name: 'chatRoom', meta: {auth: true}, component: () => import('@/views/chat/room')},
  {path: '/helpDetail', name: 'helpDetail', component: HelpDetail},
  {
    path: '*', // 页面不存在的情况下会跳到404页面
    redirect: '/',
    name: 'notFound',
    hidden: true
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})

router.beforeEach((to, from, next) => {
  // if (localStorage.getItem('loginStatus') == 'true') { // 已经登陆
  //   if (store.state.client_id === '') {
  //     Vue.prototype.$WS.createSocket()
  //   }
  // }
  if (to.matched.some(m => m.meta.auth)) {
    // 对路由进行验证
    if (localStorage.getItem('loginStatus') == 'true') { // 已经登陆
      store.commit('set_loginStatus', localStorage.getItem('loginStatus'))
      store.commit('set_userData', JSON.parse(localStorage.getItem('userData')))
      store.commit('set_token', localStorage.getItem('token'))
      next()     // 正常跳转到你设置好的页面
    } else {
      // 未登录则跳转到登陆界面，query:{ Rurl: to.fullPath}表示把当前路由信息传递过去方便登录后跳转回来；
      next({path: '/login', query: {Rurl: to.fullPath}})
    }
  } else {
    next()
  }
})

export default router
