<template>
  <div class="container-fixed">
    <div class="container-fixed_footer">
      <div class="CFF_list">
        <div class="item">
          <div class="item_img">
            <img src="@/assets/image/Footer/footer_item1.png" alt="">
          </div>
          <div class="item_text">
            <div>找回包赔 玩腻换号</div>
            <div>不玩回收 找回包赔 玩腻换号</div>
          </div>
        </div>
        <div class="item">
          <div class="item_img">
            <img src="@/assets/image/Footer/footer_item2.png" alt="">
          </div>
          <div class="item_text">
            <div>官方交易 资金保障</div>
            <div>签署正规电子版合同 永久包赔</div>
          </div>
        </div>
        <div class="item">
          <div class="item_img">
            <img src="@/assets/image/Footer/footer_item3.png" alt="">
          </div>
          <div class="item_text">
            <div>9:30-24:00 专属客服</div>
            <div>专业客服团队为您服务</div>
          </div>
        </div>
      </div>

    </div>
    <div class="footer">
      <router-link class="logo" :to="{path:'/'}">
        <img src="@/assets/image/Footer/footer_logo.png">
      </router-link>
      <div class="border"></div>
      <div class="box">
        <div class="linkList">
          <router-link :to="{path:'/'}" class="linkItem">首页</router-link>
          <router-link :to="{name:'TradeTip',query:{id:'116',parent_id:'83'}}" class="linkItem">服务协议</router-link>
          <router-link :to="{name:'TradeTip',query:{id:'108',parent_id:'83'}}" class="linkItem">业务介绍</router-link>
          <router-link :to="{name:'Legal',query:{id:'15',parent_id:'13'}}" class="linkItem">找回赔偿</router-link>
          <router-link :to="{name:'Legal',query:{id:'113',parent_id:'13'}}" class="linkItem">追回案例</router-link>
          <router-link :to="{name:'Legal',query:{id:'104',parent_id:'13'}}" class="linkItem">法务介绍</router-link>
          <router-link :to="{name:'About',query:{id:'7',parent_id:'18'}}" class="linkItem">公司介绍</router-link>
        </div>
        <div id="box">
          <div class="icp">
            <a href="https://beian.miit.gov.cn/" target="_blank" class="user_center">{{ data.icp }}</a>
<!--            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33070302100220"-->
<!--               style="display:inline-block;text-decoration:none;height:20px;line-height:20px;margin: 0">-->
<!--              <img src="../assets/image/Footer/gonganbeian.png" style="float:left;"/>-->
<!--              <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">-->
<!--                浙公网安备 33070302100220号-->
<!--              </p>-->
<!--            </a>-->
<!--            <a href="https://images.pxb7.com/zhizhao.pdf" target="_blank" class="user_center" style="margin: 0 0 0 50px">电子营业执照</a>-->
<!--            <a href="https://images.pxb7.com/icp.jpg" target="_blank" class="user_center" style="margin: 0 0 0 50px">浙B2-20210138</a>-->
          </div>
          <div class="copy">
            &copy;版权所有 uu手游 All Rights Reserved 抄袭必究
          </div>
        </div>
        <div class="img_ground">
<!--          <a><img src="@/assets/image/Footer/shiming.png"></a>-->
<!--          <a href="https://xinyong.yunaq.com/certificate?domain=www.pxb7.com&from=label&code=10010" target="_blank"><img-->
<!--            src="@/assets/image/Footer/quanwei.png"></a>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {apiSystem} from "@/request/API";

export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      data: this.$store.state.systemInfo
    }
  },
  methods: {
  },
  created() {
    setTimeout(()=>{
      this.data = this.$store.state.systemInfo
    },500)
  },
  watch: {},
  computed: {}
}
</script>

<style lang='less' scoped>
.container-fixed {
  width: 100vw;
  height: 446px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  .container-fixed_footer {
    margin: 0 0 20px;

    .CFF_list {
      background: #FFFFFF;
      box-shadow: 0px -3px 11px 0px rgba(0,0,0,0.0400);
      border-radius: 10px 10px 10px 10px;
      width: 1200px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin: 0 auto;

      .item {
        height: 83px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .item_text {
          margin: 0 0 0 9.5px;

          div:first-of-type {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #787878;
          }

          div:last-of-type {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Light;
            font-weight: 300;
            color: #9d9d9d;
          }
        }
      }
    }
  }

  .footer {
    width: 100vw;
    height: 186px;
    padding: 57px 0;
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .logo {
      cursor: pointer;
      margin: 0 80px 0 0;

      img {
        width: 162px;
        height: 174px;
      }
    }

    .border {
      width: 1px;
      height: 186px;
      background: radial-gradient(#797979, rgba(255, 255, 255, 0.00) 71%);
    }

    .box {
      height: 186px;
      padding: 0 0 0 78px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .linkList {
        display: flex;
        flex-direction: row;
        align-items: center;

        .linkItem {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #7d8086;
          padding: 0 20px;
          border-left: 0.5px solid #acadae;
          border-right: 0.5px solid #acadae;
        }

        .linkItem:hover {
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #6a6c71;
        }

        .linkItem:first-of-type {
          padding: 0 20px 0 0;
          border:none;
          border-right: 0.5px solid #acadae;
        }

        .linkItem:last-of-type {
          padding: 0 0 0 20px;
          border:none;
          border-left: 0.5px solid #acadae;
        }
      }
      #box{
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Light;
        font-weight: 300;
        color: #c1c1c1;
        .icp{
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0 0 16px;
          a{
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Light;
            font-weight: 300;
            color: #c1c1c1;
            margin: 0 70px 0 0;
          }
        }
      }
      .img_ground{
        a{
          img{
            width: 133px;
            height: 45px;
          }
        }
        a:first-of-type{
          margin:  0 40px 0 0;
        }
      }

    }
  }
}

</style>
