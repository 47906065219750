import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import Router from 'vue-router'
import md5 from 'js-md5'
import moment from 'moment/moment'
import {getBase64} from '@/util/ImgBase64'
import QRCode from 'qrcodejs2';
import accessLink from "@/util/accessLink";
import html2canvas from 'html2canvas'
import VueCropper from 'vue-cropper'
// 在main.js或需要使用的地方引入并建立连接
// import webSocket from "@/util/webSocket";
import kf53Client from "@/util/53kf";

// 导入全局样式
import './assets/css/index.css'
import './assets/css/icon.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import './assets/css/icon.css'
// import './util/flexible'

Vue.use(kf53Client)
Vue.use(VueCropper)

Vue.use(Element)


const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.filter('moment', function (value, formatString) {
  formatString = formatString || 'YYYY-MM-DD';
  return moment(value * 1000).format(formatString);
});
Vue.filter('moments', function (value, formatString) {
  formatString = formatString || 'YYYY-MM-DD HH:mm:ss';
  return moment(value * 1000).format(formatString);
});

Vue.prototype.$formatDate = (time, format) => {
  if(!time) return ''
  format = format || 'YYYY-MM-DD HH:mm:ss';
  return moment(time * 1000).format(format);
}

Vue.prototype.$axios = axios
Vue.prototype.$md5 = md5
Vue.prototype.$getBase64 = getBase64
Vue.prototype.$qrCode = QRCode;
Vue.prototype.$accessLink = accessLink;
// Vue.prototype.$WS = webSocket;
Vue.prototype.$kf53Client = kf53Client;
Vue.prototype.$html2canvas = html2canvas;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
