<template>
  <div class="rightTools">
    <img class="header" src="@/assets/image/RightTools/title.png" alt="">
    <div class="body">
      <div
        class="item"
        v-for="(item,index) in list"
        :key="index"
        @mouseenter="changeActive(index)"
        @mouseleave="removeActive(index)"
        @click="goLink(index)">
        <img class="itemIcon" :src="require(`@/assets/image/RightTools/${item.img}`)">
        <div class="name">
          {{item.name}}
        </div>
      </div>
      <div class="item" @click="goTop" style="border-top:1px solid rgba(0,0,0,0.20);padding-top:20px" v-if="scrollTop">
        <img class="itemIcon-top" src="@/assets/image/RightTools/top.png">
        <div class="name" style="font-family: PingFang SC-Medium, PingFang SC;font-weight: 500;color: #000000;">
            返回顶部
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components:{
  },
  data() {
    return {
      list:[
        // {name:'下载APP',img:'xzapp.png'},
        // {name:'联系客服',img:'lxkf.png'},
        // {name:'估价直播',img:'gjzb.png'},
        {name:'售后案例',img:'zhal.png'},
        {name:'业务介绍',img:'ywjs.png'},
        {name:'投诉建议',img:'tsjy.png'},
        {name:'最近浏览',img:'zjll.png'},
        {name:'帮助中心',img:'bzzx.png'},
        // {name:'招贤纳士',img:'zxns.png'},
        {name:'有奖问卷',img:'yjwj.png'},
      ],
      routeLink: [
      //   {
      //   // 下载app
      //   name: 'Download'
      // },
        {
        // 联系客服
        name: 'CusSerCenter'
      },{
        // 售后案例
        name: 'Legal',
        query: {id:'113',parent_id:'13'}
      },{
        // 业务介绍
        name:'TradeTip',
        query:{id:'108',parent_id:'83'}
      },{
        // 投诉建议
        name:'TradeTip',
        query:{id:'112',parent_id:'83'}
      },{
        // 最近浏览
        name: 'AccHistory'
      }, {
        // 帮助中心
        name: 'helpCenter'
      },
      // {
      //   // 招贤纳士
      //   name: 'About',
      //   query: {id: '120',parent_id: '18'}
      // },
      {
        // 有奖问卷
        name: 'Questionnaire'
      }],
      hoverList: [
        'xzapp.gif',
        'lxkf.gif',
        // 'gjzb.gif',
        'zhal.gif',
        'ywjs.gif',
        'tsjy.gif',
        'zjll.gif',
        'bzzx.gif',
        // 'zxns.gif',
        'yjwj.gif'
      ],
      leaveList: [
        'xzapp.png',
        'lxkf.png',
        // 'gjzb.png',
        'zhal.png',
        'ywjs.png',
        'tsjy.png',
        'zjll.png',
        'bzzx.png',
        // 'zxns.png',
        'yjwj.png'
      ],
      scrollTop:0
    }
  },
  computed: {
    switchOldTDWeb() {
      const query = this.$route.query.gameCode;
      return this.$route.fullPath === '/goodsList?gameCode=TYMYD'||this.$route.name === 'goodsDetails' && (query==='tymyd'||query==='TYMYD');
    }
  },
  watch: {
    $route(to) {
    },
  },
  created() {
    this.switchTool()
    this.close = localStorage.getItem('closeRight') === 'true' ? true : null;
  },
  methods: {
    // 鼠标移入
    changeActive(index) {
      this.list[index].img = this.hoverList[index]
    },
    // 鼠标移出
    removeActive(index) {
      this.list[index].img = this.leaveList[index]
    },
    // 路由跳转
    goLink(index){
      this.$router.push(this.routeLink[index])
    },
    switchTool() {
      this.close = !this.close;
      localStorage.setItem('closeRight',this.close)
    },
    /**
     * 生成下载app二维码
     */
    getQCode(){
      new QRCode(this.$refs.qrCodeDiv, {
        text: 'https://mobile.pxb7.com/pages/downloadApp/downloadApp',
        width: 120,
        height: 120,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })
    },
    /**
     * 监听滚动条
     */
    scrollToTop() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    },
    /**
     * 回到顶部
     */
    goTop(){
      cancelAnimationFrame(this.timer)
      const self = this
      self.timer = requestAnimationFrame(function fn () {
        const oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 150
          self.timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(self.timer)
        }
      })
    }
  },
  mounted() {

    window.addEventListener('scroll', this.scrollToTop)
  },
}
</script>
<style scoped lang="less">
.rightTools {
  position: fixed;
  right: 0px;
  top: 15%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;

  .body{
    transition: right 0.2s ease-in-out 0s;
    width: 86px;
    box-sizing: border-box;
    //height: 366px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.20);
    border-radius: 0 0 10px 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 14px 10px 0;
    .item{
      cursor: pointer;
      margin-bottom: 10px;
      .itemIcon{
        height: 40px;
        width: 40px;
      }
      .itemIcon-top{
        width: 18px;
        height: 9px;
      }
      .name{
        text-align: center;
        color: #7E7E7E;
        font-size: 14px;
      }
    }
  }
}
</style>
