<template>
  <div class='header_search'>
    <div class="heading_group">
      <div class="flex">
        <!--        <span @click="testCD">进入禅道test</span>-->
        <div class="heading_group_welcome">您好，欢迎来到uu手游！</div>
        <div class="heading_group_btn">
          <!-- <router-link class="appDownload" :to="{name:'CustomerSer'}" tag="a" target="_blank">
            联系客服
          </router-link> -->
          <router-link tag="div" :to="{name:'PersonalCenter'}" class="registerLogin"
                       v-if="this.$store.state.loginStatus">
            <span class="user_center">个人中心 <span id="message"
                                                 v-show="this.$store.state.message>0">{{
                this.$store.state.message > 100 ? '99+' : this.$store.state.message
              }}</span>&emsp;｜&emsp;</span>
            <div class="head_portrait">
              <!--            <img src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng60a5d6456ddef79d8278c812b71d1067121131f9f768480e2bf992543d7b60a7"/>-->
              <img v-if="this.$store.state.userData.touxiang !== ''" :src="this.$store.state.userData.touxiang"/>
              <img v-else src="@/assets/image/PersonalCenter/MyAccount/userAvatar.png"/>
            </div>
            <span class="user_account">&emsp;{{ this.$store.state.userData.telphone }}</span>
          </router-link>
          <router-link tag="a" href="javascript:;" v-else class="register" :to="{name:'Login'}">登录</router-link>
          <a v-if="this.$store.state.loginStatus" href="javascript:;" class="quit" @click="logout">退出</a>
        </div>
      </div>

    </div>
    <div class="header_logo_search">
      <div class="flex">
        <router-link tag="div" class="logo_search" :to="{path:'/'}">
          <img src="@/assets/image/Home/logo.png">
        </router-link>
        <div class="search">
          <div class="search_input">
            <el-select :popper-append-to-body="false" v-model="value" placeholder="" @change="selectChange">
              <el-option v-for="(item,index) in options" :key="index" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
            <span></span>
            <input type="text" id="search_input" v-model.trim="searchText" @keyup.enter="searchSubmit"
                   placeholder="请输入关键词进行搜索" @keydown="inputKeyDown" @focus="showRecommend=true" :maxlength='20'
                   @blur="showRecommend=false;recommendIndex=-1" @input="handleInput" autocomplete="off"
                   @compositionstart="flag = false" @compositionend="flag = true">
            <div class="search_recommend" v-show="showRecommend  && (searchText?recommendList.length>0:recommendHistory.length>0)">
              <div class="item" :class="{'active':index === recommendIndex}" v-for="(item,index) in searchText?recommendList:recommendHistory"
                   :key="index" @mousedown="clickRecommend(item)">
                <span>{{ item.title }}</span>
                <span class="itemTip" v-if="!searchText">历史搜索</span>
              </div>
              <div class="historyDel"  v-if="!searchText">
                <span class="itemTip" @mousedown.prevent="recommendHistory = []">全部删除</span>
              </div>
            </div>
            <div class="search_btn" @click="searchSubmit">
              <img src="@/assets/image/AllGame/search.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab_bar">
      <el-menu class="el-menu" mode="horizontal" @select="handleSelect" router :default-active="defaultActive">
        <el-menu-item index="/">网站首页</el-menu-item>
        <el-menu-item index="/allGame">全部游戏</el-menu-item>
        <el-submenu index="accountTrade" popper-class="head_menu_horizontal">
          <template slot="title">账号交易</template>
          <el-menu-item index="/accountTrade/allGame">我要买号</el-menu-item>
          <el-menu-item index="/accountSell">我要卖号</el-menu-item>
          <el-menu-item index="/accRecycle">账号回收</el-menu-item>
        </el-submenu>
        <el-menu-item index="/vouch">担保中介</el-menu-item>
        <el-submenu index="/tradeTip" popper-class="head_menu_horizontal">
          <template slot="title">交易必看</template>
          <el-menu-item
            index="/tradeTip" v-for="(item,index) in tradeTip" :key="index"
            :route="'_child' in item ?{name:'TradeTip',query:{id:item._child[0].id,parent_id:item.parent_id}}:{name:'TradeTip',query:{id:item.id,parent_id:item.parent_id}}">
            {{ item.page_name }}
          </el-menu-item>
        </el-submenu>
        <!--        <el-menu-item index="/personalCenter/myAccount">个人中心</el-menu-item>-->
        <el-submenu index="/legal" popper-class="head_menu_horizontal">
          <template slot="title">交易保障</template>
          <el-menu-item
            index="/legal" v-for="(item,index) in legal" :key="index"
            :route="'_child' in item ?{name:'Legal',query:{id:item._child[0].id,parent_id:item.parent_id}}:{name:'Legal',query:{id:item.id,parent_id:item.parent_id}}">
            {{ item.page_name }}
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="/helpCenter">帮助中心</el-menu-item>
        <el-submenu index="/about" popper-class="head_menu_horizontal">
          <template slot="title">关于我们</template>
          <el-menu-item
            index="/about" v-for="(item,index) in about" :key="index"
            :route="'_child' in item ?{name:'About',query:{id:item._child[0].id,parent_id:item.parent_id}}:{name:'About',query:{id:item.id,parent_id:item.parent_id}}">
            {{ item.page_name }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>
<script>
import {apiGameList, apiLogout, apiPSearchList, apiNavPage, apiTopNav, searchParticiple} from '@/request/API'

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      activeIndex: '1',
      loginStatus: this.$store.state.loginStatus,
      //搜索
      searchText: '',
      options: [],
      //每日成交下拉框选中
      value: '',
      tradeTip: [],
      about: [],
      legal: [],
      //搜索推荐
      recommendList: [],
      recommendIndex: -1,
      showRecommend: false,
      setTimeOut: null,
      flag: true,
      recommendHistory:[],
    }
  },

  methods: {
    /*    testCD(){
          let url = 'http://project.pxb7.com/api.php?'
          let code = 'pxb7'
          let key = 'lx8s7vavq8ms85p3ud7gu14n4thuazi4'
          let time = (new Date().getTime() / 1000).toFixed(0)
          let token = this.$md5(code + key + time)
          let newUrl = `${url}m=user&f=apilogin&account=admin&code=${code}&time=${time}&token=${token}`
          window.open(newUrl,'_blank')
        },*/
    searchInputBlur(){
      this.showRecommend=false
      this.recommendIndex=-1
    },
    handleSelect(key, keyPath) {
    },
    //退出
    logout() {
      if (this.$store.state.loginStatus) {
        apiLogout({}).then(res => {
          this.$message({
            center: true,
            type: 'success',
            message: "退出成功"
          })
        }).catch(err => {
          this.$message({
            center: true,
            type: 'error',
            message: err.msg
          })
        })
      }
      this.$store.commit('del_token')
      this.$store.state.userData = {}
      this.$store.state.loginStatus = false
      sessionStorage.clear();
      localStorage.removeItem('cusetomerTokens')
      localStorage.removeItem('cusetomerSid')
      localStorage.removeItem('roomId')
    },
    //搜索
    searchSubmit() {
      let historyItem = {game_id: 0, keyword: this.searchText, title: this.searchText}
      if (this.recommendHistory.findIndex(v => v.title === historyItem.title) === -1) {
        this.recommendHistory.unshift(historyItem)
        if (this.recommendHistory.length > 10) {
          this.recommendHistory =  this.recommendHistory.slice(0,9)
        }
      }
      this.showRecommend = false
      document.getElementById('search_input').blur()
      apiPSearchList({
        game_id: this.value,
        matchCon: this.searchText
      }).then(res => {
        this.$router.push({
          name: 'SelectGame',
          query: {
            game_id: res.data.game.game_id,
            rec: 's',
            matchCon: res.data.post_key ? this.searchText : ''
          },
          params: {
            game_name: res.data.game.game_name,
            game_class: res.data.game.game_class,
            game_id: res.data.game.game_id,
          }
        })
        this.value = res.data.game.game_id
      }).catch(err => {
        this.$message({
          type: "error",
          center: true,
          message: err.msg
        })
      })
    },
    //点击推荐结果
    clickRecommend(item) {
      if (item.game_id == '0') {
        this.value = ''
      }else{
        this.value = item.game_id * 1
      }
      this.searchText = item.keyword
      if (this.recommendHistory.findIndex(v => v.title === item.title) === -1) {
        this.recommendHistory.unshift(item)
        if (this.recommendHistory.length > 10) {
          this.recommendHistory =  this.recommendHistory.slice(0,9)
        }
      }
      this.searchSubmit()
    },
    //监听按键
    inputKeyDown(e) {
      if (e.code === 'ArrowDown') {
        if (this.recommendIndex === this.recommendList.length - 1) {
          this.recommendIndex = 0
        } else {
          this.recommendIndex++
        }
        this.searchText = this.recommendList[this.recommendIndex].keyword
      } else if (e.code === 'ArrowUp') {
        if (this.recommendIndex === 0) {
          this.recommendIndex = this.recommendList.length - 1
        } else {
          this.recommendIndex--
        }
        this.searchText = this.recommendList[this.recommendIndex].keyword
      }
    },
    //输入文本时获取推荐结果
    handleInput() {

      setTimeout(() => {
        if (this.flag) {
          if (this.setTimeOut) {
            clearTimeout(this.setTimeOut)
          }
          if (this.searchText === '') {
            this.recommendList = []
            return
          }
/*
          this.setTimeOut = setTimeout(() => {
            searchParticiple({
              game_id:this.value,
              match: this.searchText
            }).then(res => {
              this.recommendList = res.data
            })
          }, 100)
*/
        }
      }, 0)
    },
    //下拉框监听
    selectChange(event) {
    },
    //获取下拉框数据
    getSelectGame() {
      apiGameList({}).then(res => {
        res.data.forEach((item, index) => {
          let options = new Object()
          options.value = item.game_id
          options.label = item.game_name
          this.options.push(options)
        })
        let opiton = new Object()
        opiton.value = ''
        opiton.label = '全部游戏'
        this.options.unshift(opiton)
      })
    },
    //导航栏菜单
    getTopNav() {
      apiTopNav({}).then(res => {
        res.data.forEach((item, index) => {
          switch (item.id) {
            case 13:
              this.legal = res.data[index]._child
              break;
            case 83:
              this.tradeTip = res.data[index]._child
              break;
            case 18:
              this.about = res.data[index]._child
              break;
          }
        })
      })
    },

  },
  mounted() {
    //获取下拉框数据
    this.getSelectGame()
    //获取顶部导航栏数据
    this.getTopNav()
  },
  created() {
    this.recommendHistory = localStorage.getItem('recommendHistory')?JSON.parse(localStorage.getItem('recommendHistory')):[]
  },
  watch: {
    $route(to) {
      if (to.name != 'SelectGame') {
        this.value = ''
        this.searchText = ''
      }
    },
    recommendHistory(val){
      localStorage.setItem('recommendHistory',JSON.stringify(val))
    }
  },
  computed: {
    defaultActive() {
      if (this.$route.path.indexOf('personalCenter') > -1) {
        return '/personalCenter/myAccount'
      }
      if (this.$route.path.indexOf('home') > -1) {
        return '/'
      }
      if (this.$route.path.indexOf('allGame') > -1) {
        return '/allGame'
      }
      return this.$route.path;
    }
  },
}
</script>

<style lang="less">
.head_menu_horizontal {
  .el-menu {
    margin: 0 !important;
    padding: 10px 0 !important;
    // min-width: 171.42px !important;
    min-width: 147.17px !important;
    border-radius: 0 0 20px 20px;

    .el-menu-item {
      // width: 171.42px;
      width: 147.17px;
      text-align: center;
      height: 49px !important;
      line-height: 49px !important;
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #8F8F8F !important;
    }

    .el-menu-item:last-of-type {
      border-radius: 0 0 7px 7px;
    }

    .el-menu-item:hover {
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #5B5B5B;
      background: #F4F4F4;
    }

  }
}
</style>

<style lang='less' scoped>
.header_search {
  width: 100vw;
  background: #FFFFFF;

  .heading_group {
    height: 46px;
    border-bottom: 1px solid #E2E2E2;
    display: flex;
    width: 100vw;
    align-items: center;
    flex-direction: column;

    .flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 46px;

      .heading_group_welcome {
        font-size: 16px;
        color: #5B5B5B;
        float: left;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;

        a {
          font-size: 16px;
          color: #5B5B5B;
          float: left;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          margin: 0 20px 0 0;
        }
      }

      .heading_group_btn {
        display: flex;

        a {
          padding: 0;
          margin: 0;
        }

        .register {
          font-size: 16px;
          color: #5B5B5B !important;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
        }

        .registerLogin {
          font-size: 16px;
          color: #5B5B5B !important;
          font-family: PingFangSC-Regular, PingFang SC;
          height: 46px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .user_center {
            display: inline-block;
            position: relative;

            #message {
              //min-width: 17px;
              height: 17px;
              line-height: 17px;
              background: #F56B6C;
              border-radius: 12px;
              font-size: 12px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              padding: 0 5px;
              position: absolute;
              top: 7px;
              right: 40px;
            }
          }

          .head_portrait {
            width: 30px;
            height: 30px;
            display: inline-block;

            img {
              border-radius: 50%;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }

          .user_account {
            display: inline-block;
            font-family: PingFangSC-Light, PingFang SC;
          }
        }

        .quit {
          margin: 0 0 0 50px;
          font-size: 16px;
          color: #5B5B5B !important;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
        }

        .appDownload {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FF5400;
          line-height: 22px;

          .hotApp {
            width: 45px;
            height: 20px;
            margin: 0 5px 0 0;
          }

          span {
            font-size: 16px;
            color: #5B5B5B !important;
            font-family: PingFangSC-Regular, PingFang SC;
            cursor: default;
          }
        }
      }
    }
  }

  .header_logo_search {
    display: flex;
    width: 100vw;
    align-items: center;
    flex-direction: column;

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3px 0 6px;

      .logo_search {
        float: left;
        width: 282.99px;
        height: 99px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;

        img {
          margin: 1px 0 0 0;
          width: 100%;
          object-fit: cover;
        }
      }

      .search {
        float: right;
        width: 476px;
        height: 46px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        border-radius: 4px;

        .search_input {
          position: relative;
          background: #FFFFFF;
          border: 1px solid #283748;
          display: flex;
          flex-direction: row;
          align-items: center;

          /deep/ .el-input__inner {
            line-height: 100%;
            padding: 0;
            text-align: center;
            width: 130px;
            border: none;
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            color: #000000;
          }

          /deep/ .el-input__inner::-webkit-input-placeholder {
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #000000;
          }

          /deep/ .el-select__caret {
            color: #000000 !important;
            font-size: 12px
          }

          /deep/ .el-select-dropdown__item.hover {
            background-color: #FFFBF3 !important;
          }

          /deep/ .selected {
            color: #FCAD13;
          }

          #search_input {
            padding: 0 0 0 10px !important;
            width: 290px;
            height: 50px;
            border: none;
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #5B5B5B;
          }

          #search_input::-webkit-input-placeholder {
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #E2E2E2;
          }
          >span{
            width: 1px;
            height: 22px;
            opacity: 1;
            background: #E2E2E2;
            display: block;
          }

          .search_recommend {
            padding-top: 8px;
            padding-bottom: 16px;
            position: absolute;
            top: 56px;
            left: 130px;
            z-index: 999;
            width: 347px;
            max-height: 40vh;
            background: #ffffff;
            border-radius: 5px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.20);
            overflow-y: auto;

            .item {
              line-height: 20px;
              padding: 9px 21px;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #8f8f8f;
              cursor: pointer;
              display: flex;
              justify-content: space-between;
              .itemTip{
                font-size: 12px;
                font-family: PingFang SC, PingFang SC-Regular;
                font-weight: 400;
                color: #8f8f8f;
              }

              &:hover {
                background: #fffcf3;
              }

              &.active {
                background: #fffcf3;
              }
            }
            .historyDel{
              padding: 0 21px;
              display: flex;
              align-items: center;
              justify-content: right;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #8f8f8f;
              span{
                cursor: pointer;
              }
              span:hover{
                color: #fcac10;
              }
            }
          }

          .search_btn {
            width: 24.66px;
            height: 23.66px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  .tab_bar {
    height: 60px;
    display: flex;
    width: 100vw;
    align-items: center;
    flex-direction: column;

    /deep/ .el-menu--horizontal {
      border-bottom: none !important;
    }

    .el-menu {
      width: 1200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .el-menu-item {
        // width: 171.42px;
        width: 147.17px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 20px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #000000;
        border-radius: 10px 10px 0 0;
      }

      .el-menu-item:hover {
        background: #434ED6;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        border-radius: 10px 10px 0 0;
      }

      /deep/ .el-submenu {
        // width: 171.42px;
        width: 147.17px;
        height: 60px;
        line-height: 60px;

        .el-submenu__title {
          height: 60px;
          line-height: 60px;
          text-align: center;
          font-size: 20px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #000000;
          border-radius: 10px 10px 0 0;

        }

        .el-submenu__title:hover {
          background: #434ED6;
          border-radius: 10px 10px 0 0;
          font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }
      }

      .is-active {
        border-bottom: none;
        background: #434ED6;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        border-radius: 10px 10px 0 0;

        /deep/ .el-submenu__title {
          border-bottom: none;
          background: #434ED6;
          font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }

  div .flex {
    width: 1200px;
  }
}

</style>
