<template>
  <div id='app'>
    <router-view></router-view>
  </div>
</template>
<script>
import chatRoom from "./request/chat/chatRoom";
import {apiSystem} from "@/request/API";

export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      //白名单
      whiteList: ['/chatIndex', '/chatRoom', '/sellerContract', '/buyerContract', '/indemnityContract', '/questionnaire', '/winnersList', '/submitSuccess'],
      //系统信息
      systemInfo:{
        // mobile_url:'https://m.uushouyou.com'
      }
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },

    getRequestParams() {
      let url = location.href;
      let requestParams = {};
      if (url.indexOf("?") !== -1) {
        let str = url.substr(url.indexOf("?") + 1); //截取?后面的内容作为字符串
        let strs = str.split("&"); //将字符串内容以&分隔为一个数组
        for (let i = 0; i < strs.length; i++) {
          requestParams[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
          // 将数组元素中'='左边的内容作为对象的属性名，'='右边的内容作为对象对应属性的属性值
        }
      }
      return requestParams;
    },
    // //初始化websocket
    // initSocket() {
    //   this.$WS.on('unread_tips', (e) => {
    //     this.$store.commit("set_message", e.data.message)
    //   })
    //
    // },

  },
 async mounted() {
     await apiSystem({}).then(res => {
       this.systemInfo = res.data
       this.$store.commit('set_systemInfo',res.data)
     })
   let search = this.getRequestParams()
    if (this.whiteList.indexOf(location.pathname) === -1 && this._isMobile()) {
      let path = this.systemInfo.mobile_url
      if (location.href.indexOf('buyerContract') != -1 || location.href.indexOf('sellerContract') != -1 || location.href.indexOf('indemnityContract') != -1 || location.href.indexOf('submitSuccess') != -1
        || location.href.indexOf('cusSerEval') != -1) {
        return;
      } else {
        if (location.pathname === "/gamedetails") {
          location.replace(`${path}/pages/product/detail${location.search}`)
        } else if (location.pathname === "/selectgame") {
          location.replace(`${path}/pages/product/productList?gameId=${search.game_id}&productType=product`)
        } else if (location.pathname === "/equipmentdetails") {
          location.replace(`${path}/pages/product/detail${location.search}&type=equip`)
        } else if (location.pathname === "/about") {
          location.replace(`${path}/pages/my/tool/about${location.search}`)
        } else if (location.pathname === "/vouch" && !location.search) {
          location.replace(`${path}/pages/sell/intermediary/index`)
        } else if (location.pathname === "/vouch" && location.search) {
          location.replace(`${path}/pages/sell/intermediary/info${location.search}`)
        } else if (location.pathname === "/tradeTip") {
          location.replace(`${path}/pages/my/tool/suggestions`)
        } else {
          location.replace(path)
        }
      }
      // this.$router.replace('/m_index');
    } else {
      // this.$router.replace('/pc_index');
    }
  },
  watch: {},
  computed: {},
  created(){
    if (localStorage.getItem('loginStatus') == "true") {
        this.$store.commit('set_loginStatus', localStorage.getItem('loginStatus'))
        this.$store.commit('set_userData', JSON.parse(localStorage.getItem('userData')))
        this.$store.commit('set_token', localStorage.getItem('token'))
        this.$store.commit('set_refresh_token', localStorage.getItem('refresh_token'))
    }
    //处理过期token
    let tokens = localStorage.getItem('chatTokens')
    if (tokens) {
      tokens = JSON.parse(tokens)
      let newTokens = []
      let nowTime = new Date().getTime()
      tokens.forEach(item => {
        let day = (nowTime - item.time) / (1000 * 60 * 60 * 24)
        if (day <= 30) {
          newTokens.push(item)
        }
      })
      localStorage.setItem('chatTokens', JSON.stringify(newTokens))
    }
    // this.initSocket()
  },

}
</script>
<style lang='less' scoped>
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F4F4F4;
  overflow: hidden;
}

</style>
