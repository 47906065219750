<template>
  <div class="bannerPage">
    <img src="../../../assets/image/Home/bannerPage/BG1.png" alt="">
    <img class="btn" src="../../../assets/image/Home/bannerPage/BG2.png" @click="gotoPath('https://jq.qq.com/?_wv=1027&k=OPnYqSVG','_black')" alt="">
    <img class="btn" src="../../../assets/image/Home/bannerPage/BG3.png" @click="gotoPath('https://jq.qq.com/?_wv=1027&k=HPjjeN8W','_black')" alt="">
    <img src="../../../assets/image/Home/bannerPage/BG4.png" alt="">
  </div>
</template>

<script>
export default {
  name: "bannerPage",
  methods:{
    gotoPath(href,target){
      window.open(href,target)
    }
  }
}
</script>

<style scoped lang="less">
.bannerPage{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  min-height: 100vh;
  width: 100vw;
  img{
    width: 25%;
    object-fit: contain;
  }
  .btn{
    cursor: pointer;
  }
}

</style>
