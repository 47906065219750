import request from './customerRequest'
import md5 from 'js-md5'
import {Encrypt} from './util'

import ajax from './ajax'

const appid = 'pxb7pcwebsadfjlk'

const appkey = 'sadfjlkpxb7pcweb'

const sign = (data) => {
  let datas = {...data}
  let str = ''
  for(let i in datas){
    if(data[i] !== ''){
      str+=i+'='+data[i]+'&'
    }
  }
  return md5(`appid=${appid}&${str}key=${appkey}`).toUpperCase()
}
/**
 * 获取token
 * @param data
 * @returns {AxiosPromise}
 */
const getToken = (data) => {
  return request({
    url: 'api/access_token',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 创建会话
 * @param data
 * @returns {AxiosPromise}
 */
 const createConversation = (data) => {
  return request({
    url: '/api/room/create',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 发送消息
 * @param data
 * @returns {AxiosPromise}
 */
 const sendMsg = (data) => {
  return request({
    url: '/api/msg/send',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 发送离线消息
 * @param data
 * @returns {AxiosPromise}
 */
 const sendOffline = (data) => {
  return request({
    url: '/api/msg/sendoffline',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}


/**
 * 未读消息反馈
 * @param data
 * @returns {AxiosPromise}
 */
 const readback = (data) => {
  return request({
    url: '/api/msg/readback',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 撤回消息
 * @param data
 * @returns {AxiosPromise}
 */
 const recall = (data) => {
  return request({
    url: '/api/msg/recall',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 查看聊天记录
 * @param data
 * @returns {AxiosPromise}
 */
 const query = (data) => {
  return request({
    url: '/api/msg/query',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 邀请进入房间
 * @param data
 * @returns {AxiosPromise}
 */
 const invite = (data) => {
  return request({
    url: '/api/room/invite',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 转接会话
 * @param data
 * @returns {AxiosPromise}
 */
 const transfer = (data) => {
  return request({
    url: '/api/room/transfer',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 强制会话
 * @param data
 * @returns {AxiosPromise}
 */
 const force = (data) => {
  return request({
    url: '/api/room/force',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 订阅消息
 * @param data
 * @returns {AxiosPromise}
 */
 const submsg = (data) => {
  return request({
    url: '/api/room/submsg',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 取消订阅
 * @param data
 * @returns {AxiosPromise}
 */
 const unsubmsg = (data) => {
  return request({
    url: '/api/room/unsubmsg',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 查询房间成员列表
 * @param data
 * @returns {AxiosPromise}
 */
 const querymember = (data) => {
  return request({
    url: '/api/room/querymember',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 发起评价
 * @param data
 * @returns {AxiosPromise}
 */
 const createEvaluate = (data) => {
  return request({
    url: '/api/evaluate/create',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 完成评价
 * @param data
 * @returns {AxiosPromise}
 */
 const completeEvaluate = (data) => {
  return request({
    url: '/api/evaluate/complete',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}

/**
 * 发送文件图片
 * @param data
 * @returns {AxiosPromise}
 */
 const uploaddata = (data) => {
  return ajax(
    '/api/upload/uploaddata',
    data,
    'formdata'
  )
}
const encryptMsg = (data)=>{
  return Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
}

/**
 * 获取客服分组列表
 * @param data
 * @returns {AxiosPromise}
 */
 const grouplist = (data) => {
  return ajax(
    '/api/open/grouplist',
    data,
    'POST'
  )
}

/**
 * 根据分组标识获取客服列表
 * @param data
 * @returns {AxiosPromise}
 */
 const getcustomercarelistbycode = (data) => {
  return ajax(
    '/api/open/getcustomercarelistbycode',
    data,
    'POST'
  )
}

/**
 * 根据分组标识获取客服列表
 * @param data
 * @returns {AxiosPromise}
 */
//  const createappoint = (data) => {
//   return ajax(
//     '/api/room/createappoint',
//     data,
//     'POST',
//     {
//       'token':localStorage.getItem('cusetomerTokens') || '',
//       'sid':localStorage.getItem('cusetomerSid') || ''
//     }
//   )
// }
const createappoint = (data) => {
  return request({
    url: '/api/room/createappoint',
    method: 'post',
    data: {
      msg: Encrypt(JSON.stringify({...data,sign:sign(data)}),appid,appkey)
    }
  })
}
export default {
  sign,
  appid,
  getToken,
  createConversation,
  sendMsg,
  sendOffline,
  readback,
  recall,
  query,
  invite,
  transfer,
  force,
  submsg,
  unsubmsg,
  querymember,
  createEvaluate,
  completeEvaluate,
  uploaddata,
  encryptMsg,
  grouplist,
  getcustomercarelistbycode,
  createappoint
}
