<template>
  <div class="seller-contract">
    <div class="seller-top">
      <div class="top-con">
        <div class="container">
          <img src="@/assets/image/Contract/indemnity-title.png"/>
          <span>订单编号：{{ form.order_sn }}</span>
        </div>
        <div class="logo"></div>
      </div>
    </div>
    <div class="seller-main">
      <div class="form">
        <el-form ref="form" label-position="top" label-width="200px" :model="form" :rules="rules">
          <!-- <div class="contract_order_sn">
            订单编号：{{ form.order_sn }}
          </div> -->
          <el-form-item prop="source" class="contract_radio">
            <div slot="label" class="item-label">
              <i>您是从哪里知晓uushouyou游戏服务网的？</i>
              <i class="item-radio">(单选)</i>
            </div>
            <el-radio  label="1" v-model="form.source">贴吧</el-radio>
            <el-radio  label="2" v-model="form.source">主播</el-radio>
            <el-radio  label="3" v-model="form.source">号商</el-radio>
            <el-radio  label="4" v-model="form.source">朋友推荐</el-radio>
            <el-radio  label="5" v-model="form.source">网页搜索</el-radio>
            <el-radio  label="6" v-model="form.source">小程序公众号</el-radio>
            <el-radio  label="7" v-model="form.source">抖音</el-radio>
            <el-radio  label="8" v-model="form.source">B站(bilbili)</el-radio>
            <el-radio  label="9" v-model="form.source">老客户</el-radio>
            <el-radio  label="10" v-model="form.source">微博</el-radio>
            <el-radio  label="0" v-model="form.source">其他</el-radio>
            <el-radio style="visibility: hidden" disabled label="11" v-model="form.source"></el-radio>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input placeholder="请输入姓名" v-model="form.name" :disabled="nameShow"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="card_id">
            <el-input placeholder="请输入身份证号" v-model="form.card_id" :disabled="cardIdShow"></el-input>
          </el-form-item>
          <el-form-item label="手机" prop="mobile">
            <el-input placeholder="请输入本人手机号" v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="父亲姓名" prop="dad_name">
            <el-input placeholder="请输入父亲姓名" v-model="form.dad_name" :disabled="dadNameShow"></el-input>
          </el-form-item>
          <el-form-item label="父亲联系电话" prop="dad_mobile">
            <el-input placeholder="请输入父亲联系电话" v-model="form.dad_mobile" :disabled="dadMobileShow"></el-input>
          </el-form-item>
          <el-form-item label="母亲姓名" prop="mom_name">
            <el-input placeholder="请输入母亲姓名" v-model="form.mom_name" :disabled="momNameShow"></el-input>
          </el-form-item>
          <el-form-item label="母亲联系电话" prop="mom_mobile">
            <el-input placeholder="请输入母亲联系电话" v-model="form.mom_mobile" :disabled="momMobileShow"></el-input>
            <div class="point">
              如果是单亲请填写朋友电话，并告知客服。
            </div>
          </el-form-item>
          <el-form-item label="游戏账号" prop="game_account">
            <el-input placeholder="请输入游戏账号" v-model="form.game_account"></el-input>
          </el-form-item>
          <el-form-item label="转让价格(填写到手价)" prop="sell_prize">
            <el-input placeholder="请输入转让价格" v-model="form.sell_prize"></el-input>
          </el-form-item>
          <el-form-item label="收款账号" prop="charge_account">
            <el-input placeholder="请输入收款账号" v-model="form.charge_account">
              <el-select v-model="form.charge_account_type" slot="append" placeholder="请选择">
                <el-option label="支付宝" :value="0"></el-option>
                <el-option label="微信" :value="1"></el-option>
                <el-option label="银行卡" :value="2"></el-option>
              </el-select>
            </el-input>
            <div class="point">
              默认支付宝收款，其他收款方式请重新选择，<span>一经放款概不负责！</span>
            </div>
          </el-form-item>
          <el-form-item label="首任/非首任" prop="sell_first" class="form-sell-first">
            <el-radio v-model="form.sell_first" label="1">首任</el-radio>
            <el-radio v-model="form.sell_first" label="0">非首任</el-radio>
          </el-form-item>
          <el-form-item label="身份证照片" prop="backIdPhoto" class="form-item-id-card">
            <div class="item-card-box">
              <div class="id-card">
                <el-upload
                v-loading="loading"
                action
                :disabled="!frontIdPhotoShow"
                :style="!frontIdPhotoShow ? 'cursor:not-allowed;' : ''"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="uploadZM">
                <div class="img-box" v-show="form.frontIdPhoto !== ''">
                  <img :src="form.frontIdPhoto">
                  <!-- <div class="mask" v-show="frontIdPhotoShow">
                    <span>重新上传</span>
                  </div> -->
                </div>
              </el-upload>
                <!-- <div class="img-box" v-show="form.frontIdPhoto !== ''">
                  <img :src="form.frontIdPhoto">
                  <div class="mask" v-show="frontIdPhotoShow">
                    <span>重新上传</span>
                  </div>
                </div>
                <input type="file" @change="upload($event,'frontIdPhoto')" :disabled="!frontIdPhotoShow" :style="!frontIdPhotoShow ? 'cursor:not-allowed;' : ''"> -->
              </div>
              <div class="id-card">
                <div class="img-box" v-show="form.backIdPhoto !== ''">
                  <img :src="form.backIdPhoto">
                  <!-- <div class="mask" v-show="backIdPhotoShow">
                    <span>重新上传</span>
                  </div> -->
                </div>
                <input type="file" @change="upload($event,'backIdPhoto')"
                v-loading="backloading" :disabled="!backIdPhotoShow" :style="!backIdPhotoShow ? 'cursor:not-allowed;' : ''">
              </div>
            </div>

          </el-form-item>
          <el-form-item label="网购/外卖单照片" prop="express_sheet" class="form-item-id-card express-sheet">
            <div class="id-card">
              <div class="img-box" v-show="form.express_sheet !== ''">
                <img :src="form.express_sheet">
                <div class="mask">
                  <span>重新上传</span>
                </div>
              </div>
              <input type="file" @change="upload($event,'express_sheet')">
            </div>
          </el-form-item>
          <div class="sign-wrapper">
            <div>
              <el-form-item label="承诺人：" prop="signature" class="">
                <el-input placeholder="请输入承诺人" v-model="form.signature"></el-input>
                <span>日期：{{dateTime}}</span>
              </el-form-item>
              <div class="commitment"><span>承诺书：</span>本人确保上述信息真实有效，保证为账号交付给金华市博淳网络科技有限公司后的安全使用负责；若账号出现登录不上、被盗、申诉找回等不能使用安全问题，在金华市博淳网络科技有限公司告知后，愿意在七个工作日内承担给贵司带来的经济损失（如律师费用等），产生争议同意由贵公司所在地法院解决。涉账号私密信息单独平台网页上交付。</div>
              <el-form-item label="意向勾选" prop="agree" class="is-required">
                <el-radio v-model="agree" label="1" class="agree-first">同意此承诺书</el-radio>
                <el-radio v-model="agree" label="2">不同意此承诺书</el-radio>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div class="seller-btn">
        <el-button @click="resetForm()" class="reset">重填</el-button>
        <el-button type="primary" @click="save()" class="sub">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {apiCompensations, apiSellCard, apiUploadAuth, apiuserContract, apiContractDetails} from "../../request/API";
import * as imageConversion from 'image-conversion'
/**
 * 表单验证失败时 滚动到第一个error位置
 */
const scrollIntoView = (className = "is-error", block = "center") => {
    Promise.resolve().then(() => {
       const target = document.getElementsByClassName(className);
     if (target.length) {
        target[0].scrollIntoView({
           behavior: "smooth",
           block: block,
       });
    }
  });
};
export default {
  name: 'indemnityContract',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入联系电话'));
      } else {
        if(this.is_benefit === 1){
          if(this.form.mobile !== this.$store.state.userData.telphone){
            callback(new Error('该手机号无效，请填写官网注册账号'))
          }
        }
        callback();
      }
    };
    return {
      loading: false,
      backloading: false,
      dateTime: '',
      agree: '',
      form: {
        charge_account_type: 0,
        contract_id: '',
        order_sn: '',
        name: '',
        card_id: '',
        mobile: '',
        dad_name: '',
        dad_mobile: '',
        mom_name: '',
        mom_mobile: '',
        charge_account: '',
        game_account: '',
        frontIdPhoto: '',
        backIdPhoto: '',
        sell_prize: '',
        sell_first: '',
        express_sheet: '',
        signature: '',
        source: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
          { max: 30, message: '姓名不得超过30个汉字', trigger: 'blur' }
        ],
        card_id: [
          {required: true, message: '请输入身份证号', trigger: 'blur'},
          { min: 18, max: 18, message: '请输入18位身份证号', trigger: 'blur' }
        ],
        mobile: [
          // { validator: validatePass, trigger: 'blur',required: true },
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {min:11, max: 11, message: '请输入11位手机联系号码', trigger: 'blur' }
        ],
        dad_name: [
          {required: true, message: '请输入父亲姓名', trigger: 'blur'},
          { max: 30, message: '父亲姓名不得超过30个汉字', trigger: 'blur' }
        ],
        dad_mobile: [
          {required: true, message: '请输入父亲联系电话', trigger: 'blur'},
          {min:11, max: 11, message: '请输入11位手机联系号码', trigger: 'blur' }
        ],
        mom_name: [
          {required: true, message: '请输入母亲姓名', trigger: 'blur'},
          { max: 30, message: '母亲姓名不得超过30个汉字', trigger: 'blur' }
        ],
        mom_mobile: [
          {required: true, message: '请输入母亲联系电话', trigger: 'blur'},
          {min:11, max: 11, message: '请输入11位手机联系号码', trigger: 'blur' }
        ],
        game_account: [
          {required: true, message: '请输入游戏账号', trigger: 'blur'},
          { max: 100, message: '游戏账号不得超过100个字符', trigger: 'blur' }
        ],
        sell_prize: [
          {required: true, validator: this.validatearmsData, trigger: 'blur'},
          // { max: 7, message: '转让价格不得超过一百万', trigger: 'blur' }
        ],
        sell_first: [
          {required: true, message: '请选择是否首任', trigger: 'change'},
        ],
        charge_account: [
          {required: true, message: '请输入收款账号', trigger: 'blur'},
          { max: 50, message: '收款账号不得超过50个字符', trigger: 'blur' }
        ],
        signature: [
          {required: true, message: '请输入承诺人', trigger: 'blur'},
          { max: 30, message: '承诺人姓名不得超过30个汉字', trigger: 'blur' }
        ],
        frontIdPhoto: [
          {required: true, message: '请上传身份证照片', trigger: 'change'},
        ],
        backIdPhoto: [
          {required: true, message: '请上传身份证照片', trigger: 'change'},
        ],
        express_sheet: [
          {required: true, message: '请上传网购/外卖单照片', trigger: 'change'},
        ],
        source: [
          {required: true, message: '请选择选项', trigger: 'change'},
        ],
      },
      isSave: false,
      frontIdPhotoShow: true,
      backIdPhotoShow: true,
      nameShow: false,
      cardIdShow: false,
      dadNameShow: false,
      dadMobileShow: false,
      momNameShow: false,
      momMobileShow: false,
      // 是否选择仅包赔
      is_benefit: 2
    }
  },
  created() {
    this.form.contract_id = this.$route.query.contract_id
    if (this.$store.state.loginStatus) {
    } else{
      this.$router.push({
        path: '/login',
        query: {
          Rurl: this.$route.path+'?contract_id='+this.form.contract_id,
        },
      })
    }
    apiuserContract(1).then(res => {
      this.form.charge_account_type = res.data.charge_account_type || 0
      this.form.name = res.data.name || ''
      this.form.name == '' ? this.nameShow = false : this.nameShow = true
      this.form.card_id = res.data.card_id || ''
      this.form.card_id == '' ? this.cardIdShow = false : this.cardIdShow = true
      this.form.dad_name = res.data.dad_name || ''
      // this.form.dad_name == '' ? this.dadNameShow = false : this.dadNameShow = true
      this.form.dad_mobile = res.data.dad_mobile || ''
      // this.form.dad_mobile == '' ? this.dadMobileShow = false : this.dadMobileShow = true
      this.form.mom_name = res.data.mom_name || ''
      // this.form.mom_name == '' ? this.momNameShow = false : this.momNameShow = true
      this.form.mom_mobile = res.data.mom_mobile || ''
      // this.form.mom_mobile == '' ? this.momMobileShow = false : this.momMobileShow = true
      this.form.charge_account = ''
      this.form.game_account = ''
      this.form.frontIdPhoto = res.data.frontIdPhoto || ''
      this.form.frontIdPhoto == '' ? this.frontIdPhotoShow = true : this.frontIdPhotoShow = false
      this.form.backIdPhoto = res.data.backIdPhoto || ''
      this.form.backIdPhoto == '' ? this.backIdPhotoShow = true : this.backIdPhotoShow = false
      this.form.sell_prize = res.data.sell_prize || ''
      this.form.sell_first = res.data.sell_first || ''
      this.form.express_sheet = res.data.express_sheet || ''
      this.form.signature = ''

    })
    // 获取订单编号
    apiContractDetails(1,{contract_id: this.$route.query.contract_id}).then(res => {
      this.form.order_sn = res.data.order_sn
      this.is_benefit = res.data.is_benefit
    })
    this.form.mobile = this.$store.state.userData.telphone
    let date = new Date()
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    this.dateTime = year + '-' + month + '-' + day;
  },
  methods: {
    validatearmsData(rule, value, callback) {
      console.log( isNaN(value));
      if(value.length == 0){
        return callback(new Error('请输入转让价格！'));
      }
      if (isNaN(value)) {
        callback(new Error('请输入数字值'));
      } else {
        if (value > 1000000) {
          callback(new Error('转让价格必须小于一百万！'));
        } else {
          callback();
        }
      }
    },
    resetForm() {
      this.$refs['form'].resetFields();
      this.form.frontIdPhoto = ''
      this.nameShow = false
      this.cardIdShow = false
      this.frontIdPhotoShow = true
      this.backIdPhotoShow = true
    },
    save() {
      if(this.agree == '2' || this.agree == ''){
        this.$message({
          message: '请同意承诺书后才可以提交！',
          center: true,
          offset: 200,
          customClass: 'sell-contract-msg'
        })
      }else{
        if (this.isSave) {
          return
        }
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.isSave = true
            apiCompensations(this.form).then(res => {
              if(res.code == 200){
                this.$router.push({
                  name: 'SubmitSuccess',
                  query: {
                    contract_id : this.form.contract_id,
                    type: 3,
                    app_status: 1
                  }
                })
                /* this.$alert(res.msg, '', {
                  showClose: false,
                  confirmButtonText: '确定',
                }); */
              }else{
                this.$message({
                  message: res.msg,
                  center: true,
                  offset: 200,
                  customClass: 'sell-contract-msg'
                })
              }
              this.isSave = false
            }).catch(err => {
              this.$message({
                message: err.msg,
                center: true,
                offset: 200,
                customClass: 'sell-contract-msg'
              })
              this.isSave = false
            })
          }else{
            scrollIntoView()
            this.$message({
              message: '还有未填项',
              center: true,
              offset: 200,
              customClass: 'sell-contract-msg'
            })
          }
        })
      }

    },
    /**
     * @description: 生成唯一标识
     * @return {*}
     */
    uuid() {
        let s = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";
        let uuid = s.join("");
        return uuid;
    },
     async uploadZM(file) {
      this.loading = true
      const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message({
          message: '上传图片只能是 JPG/PNG 格式!',
          center: true,
          offset: 200,
          customClass: 'sell-contract-msg'
        })
        return
      }
      // if (!isLt5M) {
      //   this.$message({
      //     message: '上传图片大小不能超过 5MB!',
      //     center: true,
      //     offset: 200,
      //     customClass: 'sell-contract-msg'
      //   })
      //   return
      // }
     let newFile = await imageConversion.compressAccurately(file.raw, {
        size: 900,
        type: 'image/jpeg'
      })
      let blob_file = new File([newFile],this.uuid()+".jpeg",{type:file.type,lastModified: Date.now()})
      this.$getBase64(newFile).then(res => {
        apiUploadAuth({
          file:res
        }).then(res=>{
          let formData = new FormData()
          formData.append('file', blob_file)
          apiSellCard(1,formData).then(resp => {
            this.form.frontIdPhoto = resp.data.file_path
            this.form.name = res.data.cert_name
            this.form.card_id = res.data.cert_id
            if(this.form.name == '' || this.form.card_id == ''){
              this.form.frontIdPhoto = ''
              this.$message({
                message: '验证失败，请重新上传',
                center: true,
                offset: 200,
                customClass: 'sell-contract-msg'
              })
            } else {
              this.nameShow = true
              this.cardIdShow = true
              this.frontIdPhotoShow = false
              this.$message({
                message: '验证通过',
                center: true,
                offset: 200,
                customClass: 'sell-contract-msg'
              })
            }
            this.loading = false
          }).catch(err => {
            this.$message({
              message: err.msg,
              center: true,
              offset: 200,
              customClass: 'sell-contract-msg'
            })
            this.loading = false
          })
        }).catch(err=>{
          this.$message({
            message: err.msg,
            center: true,
            offset: 200,
            customClass: 'sell-contract-msg'
          })
          this.loading = false
        })
      });
    },
    async upload(e,key) {
      if (e.target.files.length > 0) {
        this.backloading = true
        const isJPG = e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png';
        if (!isJPG) {
          this.$message({
            message: '上传图片只能是 JPG/PNG 格式!',
            center: true,
            offset: 200,
            customClass: 'sell-contract-msg'
          })
          return
        }
        let newFile = await imageConversion.compressAccurately(e.target.files[0], {
          size: 900,
          type: 'image/jpeg'
        })
        let blob_file = new File([newFile],this.uuid()+".jpeg",{type:e.target.files[0].type,lastModified: Date.now()})
        let formData = new FormData()
        formData.append('file', blob_file)
        apiSellCard(1,formData).then(res => {
          this.form[key] = res.data.file_path
          this.backloading = false
        }).catch(err => {
          this.$message.error(err.msg)
          this.backloading = false
        })
      } else {
        this.backloading = false
      }
    }
  }
}
</script>

<style lang="less">
.sell-contract-msg {
  min-width: 110px !important;
  min-height: 40px;
  background: rgba(0, 0, 0, 0.70) !important;
  border-radius: 4px !important;

  .el-message__content {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #ffffff !important;
  }

  .el-message__icon {
    display: none;
  }
}

.seller-contract {
  background-color: #f9fafb;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  .seller-top {
    height: 278px;
    width: 100vw;
    background-color: #5071C8;
    .top-con{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    .container {
      padding-top: 63px;
      padding-left: 20px;

      img {
        width: 224px;
        height: 30px;
      }

      span {
        display: block;
        margin-top: 13px;
        font-size: 20px;
        color: #fff;
      }
    }
    .logo{
      margin-top: 63px;
      background: url('../../assets/image/Contract/logo.png') no-repeat;
      background-size: 100%;
      width: 250px;
      height: 58px;
      margin-right: 20px;
    }
  }

  .seller-main {
    position: relative;
    top: -123px;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 57px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);

    .form {
      max-width: 400px;
      margin: 0 auto;
      padding-top: 30px;
      .contract_order_sn{
        background: #eef3ff;
        border-radius: 9px;
        margin: 39px 0 32px;
        padding: 13px 17px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #1e3c87;
      }
      .el-form-item {
        margin-bottom: 20px;

        .el-form-item__label {
          display: inline;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: bold;
          text-align: LEFT;
          color: #5b5b5b;
          display: flex;
          justify-content: flex-start;
          .item-label{
            flex: 1;
            display: flex;
            justify-content: space-between;
            i{
              font-style: normal;
              &.item-radio{
                font-size: 16px;
                color: #8799af;
              }
            }
          }
        }

        .el-form-item__error {
          right: 0;
          left: auto;

          &::before {
            position: absolute;
            content: ' ';
            left: -16px;
            top: 3.5px;
            width: 13px;
            height: 13px;
            background-image: url("../../assets/image/Contract/err.png");
            background-size: 100%;
          }
        }
        &.form-sell-first{
          .el-form-item__content{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 420px;
            margin: 0 auto 28px;
            .quradio{
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .el-radio{
              margin: 0;
            }
            .el-radio__input{
              display: none;
            }
            .el-radio__input.is-checked+.el-radio__label{
              color: #FF8A00;
              background: #feedcb;
            }
            .el-radio__label{
              display: inline-block;
              width: 250px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              background: #F7F8F9;
              border-radius: 20px;
              margin-bottom: 10px;
            }
          }
        }

      }
      .contract_radio{
        .el-form-item__content{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          margin-top: 14px;
          &::before,&::after{
            display: none;
          }
        }
        .el-radio{
          margin: 0;
        }
        .el-radio__input{
          display: none;
        }
        .el-radio__input.is-checked+.el-radio__label{
          color: #FF8A00;
          background: #feedcb;
        }
        .el-radio__label{
          display: inline-block;
          width: 168px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #f7f8f9;
          border-radius: 20px;
          margin-bottom: 10px;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #393939;
          padding: 0;
        }
      }

      .form-item-id-card {
        .item-card-box{
          display: flex;
          .id-card {
            position: relative;
            cursor: pointer;
            margin: 10px auto;
            width: 180px;
            height: 105px;
            .el-loading-parent--relative{
              width: 100%;
              height:100%;
            }
            &:first-child{
              background: url("../../assets/image/Contract/IDCard1.png") no-repeat;
              background-size: 100%;
            }
            &:last-child{
              background: url("../../assets/image/Contract/IDCard2.png") no-repeat;
              background-size: 100%;
            }
            &:first-child::after{
              content: '请上传人像面';
              position: absolute;
              bottom: -30px;
              left: 50px;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #808b9f;
            }
            &:last-child::after{
              content: '请上传国徽面';
              position: absolute;
              bottom: -30px;
              left: 50px;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #808b9f;
            }
            .img-box {
              position: relative;
              width: 100%;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
              }

              .mask {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.50);
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                  display: inline-block;
                  width: 70px;
                  height: 26px;
                  line-height: 26px;
                  font-size: 12px;
                  font-family: PingFang SC, PingFang SC-Regular;
                  font-weight: 400;
                  text-align: center;
                  color: #ffffff;
                  border: 1px solid #ffffff;
                  border-radius: 4px;
                }
              }
            }

            input[type="file"] {
              // position: absolute;
              // top: 0;
              z-index: 2;
              cursor: pointer;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
          }

        }
        .el-form-item__error {
          left: 37%;
          top: 100%;
        }
      }
      .point {
        padding: 9px 20px;
        margin-top: 5px;
        line-height: 16px;
        background: #fff0f0;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Light;
        color: #5b5b5b;

        span {
          color: #FF750C;
        }
      }
      .express-sheet{
        .id-card{
          width: 150px;
          height: 150px;
          background: url("../../assets/image/Contract/sheet.png") no-repeat;
          background-size: 100%;
          margin: 10px 0;
        }
        .el-form-item__error {
          left: 180px;
          bottom: 20px;
          top: auto;
        }
      }
      .sign-wrapper{
        width: 1068px;
        margin-left: -334px;
        padding-top: 30px;
        border-top: 1px dashed #d7dbe2;
        border-bottom: 1px dashed #d7dbe2;
        >div{
          width: 650px;
          margin: 0 auto;
          .commitment{
            border: 1px solid #dee3ec;
            border-radius: 10px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #808b9f;
            padding: 20px;
            span{
              display: block;
              font-size: 16px;
              margin-bottom: 5px;
            }
          }
        }
        .el-form-item{
          display: flex;
          justify-content: flex-start;
          .el-form-item__label{
            padding: 0;
          }
          .el-form-item__content{
            width: 520px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-input{
              width: 194px;
            }
            span{
              flex: 1;
              text-align: right;
            }
            .agree-first{
              margin: 0 40px 0 30px;
            }
          }
        }
      }
    }

    .seller-btn {
      width: 80%;
      margin: 0 auto;
      margin-top: 60px;
      display: flex;
      justify-content: center;

      button {
        width: 250px;
        height: 50px;
        border-radius: 30px;
        font-size: 18px;
        font-weight: 500;
        display: inline-block;
        text-align: center;
      }

      .reset {
        border: 1px #000 solid
      }

      .sub {
        border: 1px #FFDB51 solid;
        background-color: #FFDB51;
        color: #000;
        margin-left: 30px;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .seller-top{
      .top-con{
        width: 95vw;
      }
    }
    .seller-main {
      width: 95vw;
      .form{
        .sign-wrapper{
          width: 800px;
          margin-left: -200px;
          >div{
            width: 500px;
          }
          .el-form-item{
            .el-form-item__content{
              width: 420px;
          }
        }
        }
      }

    }
  }
  @media screen and (max-width: 900px) {
    .seller-top {
      height: 140px;
      .top-con{
        display: flex;
        justify-content: center;
        position: relative;
      }
      .container {
        margin: 0 auto;
        text-align: center;
        padding-top: 23px;
        img {
          width: 162px;
          height: 18px;
        }
        span{
          margin-top: 7px;
          font-size: 14px;
        }
      }
      .logo{
        position: absolute;
        padding: 0;
        top: 110px;
        z-index: 1;
        background: url('../../assets/image/Contract/logo-m.png') no-repeat;
        background-size: 100%;
        width: 139px;
        height: 33px;
        margin: 0;
      }
    }
    .seller-main {
      top: -53px;
      padding-top: 67px;
      .form{
        .contract_order_sn{
          margin-top: 0;
        }
        .sign-wrapper{
          width: 400px;
          margin: 0 auto;
          >div{
            width: 400px;
            margin: 0 auto;
            .commitment{
              padding: 20px;
            }
          }
          .el-form-item{
            display: block;
            .el-form-item__label{
              padding: 0;
            }
            .el-form-item__content{
              width: 400px;
              display: block;
              .el-input{
                width: 400px;
              }
              >span{
                display: block;
                text-align: right;
              }
              .agree-first{
                margin: 0 40px 0 0px;
              }
            }
          }
        }
        .form-item-id-card {
          .item-card-box{
            display: block;
            .id-card {
              width: 195px;
              height: 105px;
              &:first-child{
                margin-bottom: 40px;
              }
            }
          }
          .el-form-item__error{
            left: 37%;
            top: 102%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .seller-main {
      width: 93vw;
      .form {
        width: 80vw;
        padding-top: 10px;
        .contract_radio{
          .el-radio__label{
            width: 136px;
            font-size: 14px;
          }
        }
        .el-form-item{
          .el-form-item__label{
            font-size: 14px;
          }
          &.form-sell-first{
            .el-form-item__content{
              width: 80vw;
              .el-radio__label{
                width: 60vw;
              }
            }
          }
        }
        .sign-wrapper{
          width: 80vw;
          >div{
            width: 80vw;
          }
          .el-form-item{

            .el-form-item__content{
              width: 80vw;
              .el-input{
                width: 80vw;
              }
            }
          }
        }
      }
    }
  }
   @media screen and (max-width: 380px) {
    .seller-main {
      .form {
        .contract_radio{
          .el-radio__label{
            width: 123px;
          }
        }
      }
    }
  }
}
</style>
