<template>
  <div class="cusSer" id="cusSer" ref="imageTofile" @click="goRead">
    <div class="main">
      <div class="title">
        <div class="info">
          <img src="@/assets/image/CustomerSer/logoAvatar.png" alt="">
          uushouyou代售
          <span class="divide"></span>
          <span>签署合同 找回包赔</span>
        </div>
        <div class="url">
          平台唯一官网：<a href="https://www.pxb7.com" target="https://www.pxb7.com">www.pxb7.com</a>
        </div>
      </div>
      <div class="body">
        <div class="chat">
          <div class="chatHeader">
            <div class="currentCustomerService">
              <img class="complain" src="@/assets/image/CustomerSer/chatIcon.png" alt="">
              {{nickname=='' ? '当前暂无客服' : '当前客服：'+nickname}}
              &emsp;&ensp;｜&ensp;&emsp;
              为您解决账号、订单、支付等问题，快速响应
            </div>
            <div class="historyRecord" @click="historyDialogOpen">
              点击查看历史记录
            </div>
          </div>
          <div class="chatMain" ref="chatMain" @scroll="handleScroll">
            <div class="msgList" v-for="(item,index) in msgList" :key="index">
              <div class="msgItem" :class="isMe(item.userid)?'my_msg':''" v-if="item.contenttype !== -1">
                <div class="msgTime">
                  <span>{{isMe(item.userid)?'我': nickname}}</span>
                  {{formatDate(item.addtime)}}
                </div>
                <div class="msgBody">
                  <div class="msgAvatar"><img :src="!isMe(item.userid)? require('@/assets/image/CustomerSer/WechatIMG.png') : avatar == '' ? require('@/assets/logo.png') : avatar" alt=""></div>
                  <div class="message" @contextmenu.prevent.stop="(event)=>{msgRight(item,index,event)}" v-if="item.contenttype == 1 || item.contenttype == 2">
                    {{ item.content }}
                  </div>
                  <div class="message messageFile" @contextmenu.prevent.stop="(event)=>{msgRight(item,index,event)}" v-else-if="item.contenttype == 6">
                    <img class="msgFileIcon" src="@/assets/image/CustomerSer/fileIcon.png">
                    <div class="msgFileInfo">
                      <div class="msgFileName">{{ item.content.filename }}</div>
                      <div class="msgFileSize">{{ (item.content.size/1024).toFixed(2)}}kb</div>
                    </div>
                  </div>
                  <div
                    class="message messageFile"
                    @contextmenu.prevent.stop="(event)=>{msgRight(item,index,event)}"
                    :class="item.contenttype == 4 ? 'imgMessage' : ''"
                    v-else-if="item.contenttype == 4">
                    <div class="msgFileInfo">
                      <img  @click="imgShow(item.content.url)" :src="item.content.thumbnail" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.contenttype == -1" class="recall_wrapper">
                {{isMe(item.userid)?`我${item.content}`:`${nickname}${item.content}`}}
              </div>
            </div>
            <div class="score" id="score" ref="score" v-if="scoreShow" @click.prevent.self="scoreShow = false">
              <div class="scoreHeader">
                <span></span>
                请为客服{{nickname}}的服务打分
                <span></span>
              </div>
              <div class="scoreBody">
                <el-rate v-model="score" void-icon-class="iconfont star"
                          @change="rateChange"
                         :texts="['恶劣', '较差', '一般', '较好', '满意']" show-text
                         :icon-classes="['iconfont star','iconfont star','iconfont star']"
                         :colors="['#ffd427','#ffd427','#ffd427']" :void-color="'#ebeef2'"
                         score-template="{value}"></el-rate>
              </div>
            </div>
          </div>
          <div class="newMsg" @click="newMsgClick" :style="checkNewMsg?'display:flex':'display:none'"><img
            src="@/assets/image/CustomerSer/newMsgIcon.png" alt="">1条新消息
          </div>
          <div class="chatBtn">
            <div class="btn" v-for="(item,index) in btnGroup" :key="index" @click="item.click"
                 :ref="item.label==='评价客服'?'score':''"
                 :style="item.icon?'padding-left:13px':''">
              <img v-if="item.icon" :src="item.icon" alt="">
              {{ item.label }}
            </div>
          </div>
          <div class="chatEnter">
            <div class="chatEnterHeader">
              <div class="chatEnterTools">
                <div class="chatTool">
                  <emoji @click="emojiClick">
                    <template slot-scope="{open}">
                      <i class="iconfont expression" @click="open()"></i>
                    </template>
                  </emoji>
                </div>
                <!-- 截图 -->
                <div class="chatTool" @click="screenShot"><i class="iconfont screenshot"></i></div>
                <div class="chatTool">
                  <el-upload
                    :headers="{appid:appid}"
                    action="https://wsapi.pxb7.com/api/upload/uploaddata"
                    :show-file-list="false"
                    :data="dataMsg"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                    :before-upload="beforeUpload"
                  >
                  <!-- :action="apiUrl+'api/upload/uploaddata'" -->
                    <!-- :http-request="uploadImg" -->
                    <i class="iconfont upload_file"></i>
                  </el-upload></div>
                <div class="chatTool">
                  <el-upload
                    :headers="{appid:appid}"
                    action="https://wsapi.pxb7.com/api/upload/uploaddata"
                    :show-file-list="false"
                    :data="dataMsg"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                    :before-upload="beforeUpload"
                  >
                    <i class="iconfont upload_img"></i>
                  </el-upload>
                </div>
              </div>
              <div class="chatEnterSend" @click="handleSendMessage">发送</div>
            </div>
            <div class="chatEnterInput" id="myTextarea">
              <my-textarea ref="myTextarea" v-model="message" :members="this.members"
                           @ctrl-enter="handleSendMessage"></my-textarea>
            </div>
          </div>
        </div>
        <div class="baseInfo">
          <a href="https://www.pxb7.com" target="https://www.pxb7.com">
            <img src="@/assets/image/CustomerSer/logo.png" alt="" class="logo">
          </a>
          <div class="cusSerCard Card" v-if="nickname !== ''">
            <div class="title">
              <div class="text">客服名片</div>
            </div>
            <div class="cardBody">
              <img src="@/assets/image/CustomerSer/WechatIMG.png" alt="" class="avatar">
              <div class="other">
                <div>客服昵称：<span>{{nickname}}</span></div>
                <div>
                  客服评分：
                  <span :title="rate">
                    <el-rate v-model="score" disabled disabled-void-icon-class="iconfont star"
                             :icon-classes="['iconfont star','iconfont star','iconfont star']"
                             :colors="['#ffd427','#ffd427','#ffd427']" :disabled-void-color="'#ebeef2'"
                             score-template="{value}"></el-rate>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="aboutCard Card">
            <div class="title" style="margin-bottom: 16px">
              <div class="text">关于我们</div>
            </div>
            <div class="cardBody">
              <div class="content">
                uushouyou代售，为您的游戏交易保驾护航 历经三年用心打磨，累计成交订单超100000笔，为数十万客户提供过直接服务，我们与客户的利益站在同一阵线，感谢您选择螃蟹账号！<br/><br/>
                我们拥有线上线下二百人的工作团队为您全方位提供最专业的账号交易服务<br/><br/>
                我们拥有完善成熟的“正规电子合同签署+专业售后部门+合作律师”找回包赔流程 我们拥有让客户买得放心，卖得舒心，交易得开心的服务理念
              </div>
              <img class="complain" src="@/assets/image/CustomerSer/complain.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  历史聊天记录  -->
    <el-dialog
      :visible.sync="historyDialog"
      custom-class="historyDialog"
      :show-close="false"
      width="600px"
      top="5vh"
      center>
      <div id="historyDialog">
        <div class="hd_header">
          <div class="title"><img src="@/assets/image/CustomerSer/historyIcon.png" alt="">与客服{{nickname}}的聊天记录</div>
          <div class="search">
            <el-input v-model="searchText" placeholder="输入关键词"
              @keydown.enter.native="load(1)">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
        <div class="hd_body">
          <div class="chatMain" ref="hd_Main" @scroll="scrollEvent">
          <!-- <div class="chatMain" ref="hd_Main" v-infinite-scroll="load" :infinite-scroll-disabled="disabled"> -->
            <div class="msgList" v-for="(item,index) in historyMsgList" :key="index">
              <!-- <div class="msgDate" v-if="index%5 === 0">
                <span></span>
                2022.1.{{ 31 - index }}
                <span></span>
              </div> -->
              <div class="msgItem" :class="isMe(item.userid)?'my_msg':''">
                <div class="msgTime">
                  <span>{{isMe(item.userid)?'我': nickname}}</span>
                  {{formatDate(item.addtime)}}
                </div>
                <div class="msgBody">
                  <div class="msgAvatar"><img :src="isMe(item.userid)? require('@/assets/logo.png') : require('@/assets/image/CustomerSer/WechatIMG.png')" alt=""></div>
                  <div class="message" v-if="item.contenttype == 1 || item.contenttype == 2">
                    {{ item.content }}
                  </div>
                  <div class="message messageFile" @contextmenu.prevent.stop="(event)=>{msgRight(item,index,event)}" v-else-if="item.contenttype == 6">
                    <img class="msgFileIcon" src="@/assets/image/CustomerSer/fileIcon.png">
                    <div class="msgFileInfo">
                      <div class="msgFileName">{{ item.content.filename }}</div>
                      <div class="msgFileSize">{{ (item.content.size/1024).toFixed(2)}}kb</div>
                    </div>
                  </div>
                  <div
                    class="message messageFile"
                    :class="item.contenttype == 4 ? 'imgMessage' : ''"
                    v-else-if="item.contenttype == 4">
                    <div class="msgFileInfo">
                      <img :src="item.content.thumbnail" alt="">
                    </div>
                  </div>



                  <!-- <div class="message messageFile" v-else>
                    <img class="msgFileIcon" src="@/assets/image/CustomerSer/fileIcon.png">
                    <div class="msgFileInfo">
                      <div class="msgFileName">{{ item.content.filename }}</div>
                      <div class="msgFileSize">{{ item.contenttype == 4 ? `${item.content.width.toString()}*${item.content.height.toString()}` : item.contenttype == 6 ? `${item.content.size}` : '' }}</div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <p v-if="loading">加载中...</p>
            <p v-if="noHistory" class="no_history">
              <img src="@/assets/image/CustomerSer/noHistory.png">
            </p>
            <p v-if="noMore">没有更多了</p>
          </div>
        </div>
        <div class="close">
          <img src="@/assets/image/AccountDeal/close.png" alt="" @click="historyDialogClear">
        </div>
      </div>
    </el-dialog>
    <!--  右键菜单  -->
    <div v-show="msgRightVisible" class="msgRight" :style="`left: ${msgLeft}px;top: ${msgTop}px;`" key="msgRight">
      <div class="item" v-show="isShowRecall()" @click="handleRevoke(msgRow)">撤回</div>
      <div class="item" v-show="msgRow.contenttype != 1" @click="downLoad(msgRow)">下载</div>
    </div>
    <cropper ref="cropper" :returnUrl="getScreenShotUrls" style="z-index: 2" @upload-img="uploadImg"></cropper>
    <el-dialog
      title=""
      :visible.sync="showViewer"
      width="50%">
      <img :src="images" style="width:100%">
    </el-dialog>
  </div>
</template>

<script>
import {apiGameList} from "@/request/API";
import myTextarea from "@/views/chat/components/myTextarea";
import cropper from "@/components/cropper";
import moment from 'moment/moment'
import evn from "./../../util/evn";
import {Decrypt} from '@/request/customer/util.js'
import chatApi from '@/request/customer/customerRoom'
import emoji from "@/views/chat/components/emoji";
export default {
  name: "index",
  components: {
    myTextarea,
    cropper,
    emoji,
  },
  data() {
    return {
      images: '',
      //图片预览
      showViewer: false,
      //客服名片 客服的评分
      rate: 3.6,
      //消息列表
      msgList: [],
      historyMsgList: [],
      //按钮组
      btnGroup: [
        // {label: '评价客服', click: this.markScore, icon: require('@/assets/image/CustomerSer/starIcon.png')},
        // {label: '咨询包赔', click: ''},
        // {label: '业务介绍', click: ''},
        // {label: '售后服务', click: ''},
      ],
      //查看新消息按钮
      checkNewMsg: false,
      //是否能滚动到最底部
      scrollBottom: true,
      //历史记录弹出窗口
      historyDialog: false,
      //搜索文本
      searchText: '',

      loading: false,

      //评价客服元素
      scoreShow: false,

      //消息右键
      msgRightVisible: false,
      //消息右键菜单显示位置
      msgTop: 0,
      msgLeft: 0,
      //右键的消息
      msgRow: {},
      //右键消息下标
      msgIndex: '',
      //消息
      message: '',
      members: [],
      roomCreat: false,
      ws: null,
      setIntervalWesocketPush: null,
      // 聊天记录分页
      page: 1,
      pagesize: 20,
      // 客服昵称
      nickname: '',
      // 评价id
      evid: '',
      avatar: '',
      // 历史消息没有更多
      noMore: false,
      // 上传接口加密msg
      dataMsg:{
        msg:chatApi.encryptMsg({
          format: 'json',
          type: 3
        })
      },
      apiUrl: evn.IMApi,
      appid: chatApi.appid,
      // 客服id
      customercareid: '',
      grouptitle: '',
      game_name: '',
      noHistory: false,
      customercarelist: [],
      score: 5
    }
  },
  mounted() {
    document.getElementById('cusSer').addEventListener('contextmenu', (e) => {
      e.preventDefault()
    })
    // this.initWebSocket(2)
    //页面挂载完成时调用
    this.scrollToBottom();
    document.getElementById('myTextarea').addEventListener(
      "paste",
      (evt) => {
        if (
          evt.clipboardData &&
          evt.clipboardData.files &&
          evt.clipboardData.files.length
        ) {
          evt.preventDefault();
          [].forEach.call(evt.clipboardData.files, (file) => {
            if (!file.type.match(/^image\/(gif|jpe?g|a?png)/i)) {
              return;
            }
            this.uploadImg(file)
            // const formData = new FormData();
            // formData.append("file[]", file);
            // this.uploadApi(formData).then(res => {
            //   let length = this.editor.getSelection()?.index || 0; //光标位置
            //   res.data.forEach(item => {
            //     // 插入图片，res为服务器返回的图片链接地址
            //     this.editor.insertEmbed(length, 'image', item.file_path)
            //     // 调整光标到最后
            //     this.editor.setSelection(length + 1)
            //     length++
            //   })
            // })
          });
        }
      },
      false
    );
  },
  methods: {
    //打开图片预览
    imgShow(src) {
      this.images = src
      this.showViewer = true;
    },
    /**
     * @description: 根据分组标识获取客服列表
     * @return {*}
     */
    getcustomercarelist() {
      if(this.grouptitle){
        chatApi.getcustomercarelistbycode({
          msg: chatApi.encryptMsg({
            format: 'json',
            type: 3,
            code: this.grouptitle
          })
        }).then(res => {
          let data = JSON.parse(Decrypt(res.result))
          this.customercarelist = data.customercarelist
          // if(this.nickname) {
            for(let i = 0;i<this.customercarelist.length;i++){
              if(this.customercarelist[i].nickname ==this.nickname){
                this.score = this.customercarelist[i].score
                return
              }
            }
          // } else {}
        }).catch(err => {
          console.log(err);
        })
      } else {
        this.gameData()
      }

    },
    /**
     * @description: 生成唯一标识
     * @return {*}
     */
    uuid() {
        let s = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";
        let uuid = s.join("");
        return uuid;
    },
    /**
     * @description: 上传截图
     * @param {*} file
     * @return {*}
     */
    uploadImg: function (file) {
      let blob_file = new File([file],this.uuid()+".png",{type:file.type,lastModified: Date.now()})
      let form = new FormData();
      form.append("file", blob_file);
      form.append("msg", this.dataMsg.msg);
      chatApi.uploaddata(form).then(resp => {
        let result = JSON.parse(Decrypt(resp.result))
        this.getScreenShotUrl(result.url,result)
      })
    },
    /**
     * @description: 客服消息 未读 =》 已读
     * @return {*}
     */
    goRead() {
      let ids = []
      this.msgList.forEach(item => {
        if(item.msgtype == 1){
          ids.push(item.id)
        }
      })
      if(ids.length > 0){
        chatApi.readback({
          format: 'json',
          ids: JSON.stringify(ids),
          roomid: localStorage.getItem('roomId')
        }).then(res => {
          // console.log(res);
        })
      }

    },
    /**
     * @description: 上传图片/文件前调用
     * @param {*} file
     * @return {*}
     */
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
       if (!isLt10M) {
          this.$message.error('上传文件大小不能超过 10MB!');
        }
      return isLt10M
    },
    /**
     * @description: 上传文件/图片成功回调
     * @param {*} response
     * @param {*} file
     * @param {*} fileList
     * @return {*}
     */
    uploadSuccess(response, file, fileList) {
      let resData = JSON.parse(Decrypt(response.result))
      if(this.roomCreat) {
        if(file.raw.type.indexOf('image') != -1){
          chatApi.sendMsg({
            format:'json',
            list: JSON.stringify([{
              contenttype: 4,
              content: JSON.stringify({
                filename:file.name,
                url:resData.url,
                width:resData.width,
                height: resData.height
              })
            }]),
            roomid: localStorage.getItem('roomId')
          }).then(res => {
            let value = JSON.parse(JSON.parse(Decrypt(res.msg)).list)[0]
            value.userid = this.$store.state.userData.telphone
            value.msgtype = 2
            this.getIpList(value)
          })
        } else {
          chatApi.sendMsg({
            format:'json',
            list: JSON.stringify([{
              contenttype: 6,
              content: JSON.stringify({
                filename:file.name,
                url:resData.url,
                filetype:file.raw.type,
                size: file.size
              })
            }]),
            roomid: localStorage.getItem('roomId')
          }).then(res => {
            let value = JSON.parse(JSON.parse(Decrypt(res.msg)).list)[0]
            value.userid = this.$store.state.userData.telphone
            value.msgtype = 2
            // console.log(value,'value');
            this.getIpList(value)
          })
        }
      }else{
        if(file.raw.type.indexOf('image') != -1){
          // 离线
          chatApi.sendOffline({
            content: JSON.stringify({
              filename:file.name,
              url:resData.url,
              width:resData.width,
              height: resData.height
            }),
            contenttype: 4,
            format: 'json',
            groupcode: this.grouptitle
          }).then(res => {
            let msg = JSON.parse(Decrypt(res.msg))
            msg.userid = this.$store.state.userData.telphone
            msg.msgtype = 2
            this.getIpList(msg)
          }).catch(err => {
            this.$message.error('当前存在客服在线，请返回与在线客服沟通')
          })
        } else {
          // 离线
          chatApi.sendOffline({
            content: JSON.stringify({
              filename:file.name,
              url:resData.url,
              filetype:file.raw.type,
              size: file.size
            }),
            contenttype: 6,
            format: 'json',
            groupcode: this.grouptitle
          }).then(res => {
            let msg = JSON.parse(Decrypt(res.msg))
            msg.userid = this.$store.state.userData.telphone
            msg.msgtype = 2
            this.getIpList(msg)
          }).catch(err => {
            this.$message.error('当前存在客服在线，请返回与在线客服沟通')
          })
        }
      }
    },
    uploadError(err, file, fileList) {
      console.log(err, file, fileList);
    },
    /**
     * @description: 13位时间戳转时间
     * @param {*} time
     * @param {*} format
     * @return {*}
     */
    formatDate(time, format) {
      if(!time) return ''
      format = format || 'YYYY-MM-DD HH:mm:ss';
      return moment(time).format(format);
    },
    //表情
    emojiClick(val) {
      this.$refs.myTextarea.insertText(val)
    },
    /**
     * @description: 获取聊天token
     * @return {*}
     */
    getCusToken() {
      chatApi.getToken({
        format: "json",
        type: 2,
        userid: this.$store.state.userData.telphone,
      }).then(res => {
        localStorage.setItem('cusetomerTokens', JSON.parse(Decrypt(res.msg)).token)
        this.initWebSocket()
      })
    },
    /**发送心跳
   * @param {number} time 心跳间隔毫秒 默认5000
   * @param {string} ping 心跳名称 默认字符串ping
   */
    sendPing(time = 50000, ping = 'ping') {
      clearInterval(this.setIntervalWesocketPush)
      if(this.ws.readyState === WebSocket.OPEN){
        this.ws.send(ping)
        this.setIntervalWesocketPush = setInterval(() => {
          this.ws.send(ping)
        }, time)
      }
    },
    initWebSocket() {
      let _this = this;
      //判断页面有没有存在websocket连接
      // if (window.WebSocket) {
        if(localStorage.getItem('cusetomerTokens') !== null && localStorage.getItem('cusetomerTokens') !== ''){
          let ws = new WebSocket(evn.IMSocketApi+'&token='+localStorage.getItem('cusetomerTokens'));
          _this.ws = ws;
          ws.onopen = function(e) {
            _this.sendPing()
            console.log("服务器连接成功");
          };
          ws.onclose = function(e) {
            clearInterval(_this.setIntervalWesocketPush)
            console.log('服务器连接关闭....正在尝试重连')
            if (_this.ws.readyState !== 2) {
              _this.ws = null
              _this.initWebSocket()
            }
          };
          ws.onerror = function() {
            _this.ws.close()
            clearInterval(_this.setIntervalWesocketPush)
            console.log("服务器连接出错");
            if (_this.ws.readyState !== 3) {
              _this.ws = null
              _this.initWebSocket()
            }
          };
          ws.onmessage = function(e) {
            //接收服务器返回的数据
            if(e.data.toString().length> 10){
              let resData = JSON.parse(Decrypt(e.data));
              // console.log('resData',resData);
              if(resData[0].act == "loginok"){
                localStorage.setItem('cusetomerSid', resData[0].data.sid)
                // _this.creatRoomPoint()
                // _this.creatRoom()
                _this.roomCreatCus()
              } else if(resData[0].act == "createroom") {
                localStorage.setItem('roomId', resData[0].data.id)
                _this.selectRoomList()
              } else if(resData[0].act == "chat") {
                let value = resData[0].data
                // _this.msgList.push(value)
                _this.getIpList(value)
              } else if(resData[0].act == "offline") {
                let value = resData[0].data.type
                if(value == 1){
                  _this.$message.info('当前客服已离线！')
                  _this.selectRoomList()
                }
              } else if(resData[0].act == "online") {
                let value = resData[0].data.type
                if(value == 1){
                  _this.$message.info('客服'+ _this.nickname +'已上线！')
                  _this.selectRoomList()
                }
              } else if(resData[0].act == "tempoffline") {
                let value = resData[0].data.type
                if(value == 1){
                  _this.$message.info('当前客服暂时离开，请稍后！')
                  _this.selectRoomList()
                }
              } else if(resData[0].act == "recall") {
                // 撤回消息
                let recall_id = resData[0].data.id
                _this.recreadCall(recall_id)
              }
            }
          };
        }else {
          this.getCusToken()
        }
      // }
    },
    recreadCall(id) {
      if(id){
        let index = this.msgList.findIndex(val => val.id === id)
        let item = this.msgList.find(i => i.id === id)
        this.msgList.splice(index,1,{
          contenttype: -1,
          content: '撤回了一条消息',
          userid: item.userid
        })
        sessionStorage.setItem('msgListData', JSON.stringify(this.msgList))
      }

    },
    roomCreatCus() {
      // _this.creatRoomPoint()
      // _this.creatRoom()
      if(this.game_id) {
        apiGameList({
          game_id:this.game_id
        }).then(res => {
          this.game_name = res.data[0].game_name
          this.grouptitle = res.data[0].game_alias
          this.creatRoom()
        })
      }else{
        this.creatRoomPoint()
      }
    },
    /**
     * @description: 指定客服创建会话
     * @return {*}
     */
    creatRoomPoint() {
      chatApi.createappoint({
        format: 'json',
        grouptitle: this.grouptitle,
        page_title: '联系客服',
        page_url: location.href,
        receiveid: this.customercareid,
        type: 1
      }).then(res => {
        this.roomCreat = true
      }).catch(err => {
        this.roomCreat = false
        this.$message.info('当前客服不在线')
      })
    },
    /**
     * @description: 创建会话
     * @return {*}
     */
    creatRoom() {
      chatApi.createConversation({
        format: 'json',
        groupcode: this.grouptitle,
        grouptitle: this.game_name,
        page_title: '联系客服',
        page_url: location.href,
      }).then(res => {
        this.roomCreat = true
      }).catch(err => {
        this.roomCreat = false
        this.$message.info('当前无客服在线')
      })
    },
    /**
     * @description: 查询成员信息
     * @return {*}
     */
    selectRoomList() {
      chatApi.querymember({
        format: 'json',
        roomid: localStorage.getItem('roomId')
      }).then(res =>{
        let res_data = JSON.parse(Decrypt(res.msg))
        let list = JSON.parse(res_data.list)
        // console.log('房间成员',list);
        if(res_data.type == 1){
          // 双人
          list.forEach(item => {
            if(item.type == 1){
              // 客服
              this.nickname = item.nickname
              this.getcustomercarelist()

            } else {
              // 用户
            }
          });
        } else {
          // 多人
        }
      })
    },
    /**
     * 滚动条到最底部
     */
    scrollToBottom() {
      if (this.scrollBottom) {
        // 聊天定位到底部
        let chatMain = this.$refs.chatMain    //通过$refs获取到该元素
        if(chatMain){
          setTimeout(() => {
            chatMain.scrollTop = chatMain.scrollHeight
          },100)
          this.checkNewMsg = false
        }
      } else {
        this.checkNewMsg = true
      }
    },

    /**
     * 滚动条到最底部
     */
    historyScrollToBottom() {
      // 聊天定位到底部
      let chatMain = this.$refs.hd_Main    //通过$refs获取到该元素
      chatMain.scrollTop = chatMain.scrollHeight
    },

    /**
     * 获取新消息
     */
    getIpList(text) {
      if(text){
        // if(text.msgtype == 1 || text.msgtype == 2 || text.msgtype == 5 || text.msgtype == 8){
        if(text.msgtype !== 10 || text.msgtype !== 11 || text.msgtype !== 12){
          if(text.contenttype == 1){
            if(text.msgtype == 5){
              this.selectRoomList()
              this.msgList.push(text)
            } else {
              this.msgList.push(text)
            }

          } else if(text.contenttype == 4 || text.contenttype == 6) {
            let content = JSON.parse(text.content)
            text.content = content
            if (text.content.width > text.content.height && text.content.width > 200) {
              //图片宽比高长并且宽超过200px
              text.content.height = text.content.height / (text.content.width / 183)
              text.content.width = 183
            } else if (text.content.height > 200) {
              //图片高比宽长并且宽超过200px
              text.content.width = text.content.width / (text.content.height / 200)
              text.content.height = 200
            }
            if (text.content.url.indexOf('https://wsapi.pxb7.com/api/upload/') !== -1) {
              //缩略图
              text.content.thumbnail = `${text.content.url}&width=${Math.round(text.content.width)}&height=${Math.round(text.content.height)}`
            } else {
              //缩略图
              text.content.thumbnail = `${text.content.url}`
            }
            // console.log(text);
            this.msgList.push(text)
          }
          sessionStorage.setItem('msgListe neData', JSON.stringify(this.msgList))
        }
        if(text.msgtype == 10&&this.roomCreat) {
          // console.log('评价邀请',text);
          this.evid = JSON.parse(text.content).evid
          this.scoreShow = true
        }
        if(text.msgtype == 12) {
          // console.log('评价超时',text);
          this.scoreShow = false
        }
        this.scrollBottom = true
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      } else {
        let msg = JSON.parse(sessionStorage.getItem('msgListData')) || ''
        if(msg !== ''){
          this.msgList = [...msg]
        }
      }
    },

    /**
     * 聊天窗口滚动监听
     */
    handleScroll(e) {
      this.msgRightVisible = false
      if (e.srcElement.scrollHeight - e.srcElement.scrollTop === 325) {
        this.scrollBottom = true
        this.checkNewMsg = false
      } else {
        this.scrollBottom = false
      }
    },

    /**
     * 查看新消息点击事件
     */
    newMsgClick() {
      this.scrollBottom = true
      this.scrollToBottom()
    },

    /**
     * 历史聊天记录弹窗关闭
     */
    historyDialogClear() {
      this.historyDialog = false
      this.searchText = ''
    },
    /**
     * 历史聊天记录弹窗打开
     */
    historyDialogOpen() {
      this.historyDialog = true
      this.load(1)
    },
    scrollEvent(e) {
      if ((e.srcElement.offsetHeight + e.srcElement.scrollTop) - e.srcElement.scrollHeight === 0) {
        // if(!this.noMore) {
          this.page += 1;
          this.load(2);
        // }
      }
    },
    /**
     * 加载历史记录
     */
    load(p) {
      if(localStorage.getItem('roomId')){
        this.loading = true
        chatApi.query({
          format: 'json',
          keyword: this.searchText,
          msgtype: JSON.stringify([1,2]),
          page: p == 1 || this.searchText == '' ? p : this.page,
          pagesize: this.pagesize,
          roomid: localStorage.getItem('roomId'),
          sort: "desc",
        }).then(res => {
          let arr = []
          let list = JSON.parse(JSON.parse(Decrypt(res.msg)).list)
          // console.log(987,this.page);
          if(Object.keys(list).length > 0){
            list.forEach(k => {
              // if((k.msgtype == 1 || (k.msgtype == 2 && k.userid == this.$store.state.userData.telphone)) && k.status == 1){
                if(k.status == 1){
                  if(k.contenttype == 1){
                    arr.push(k)
                  } else if(k.contenttype == 4 || k.contenttype == 6) {
                    let con = JSON.parse(k.content)
                    k.content = con
                    arr.push(k)
                  }
                }
              // }
            });
            if(p == 1){
              this.historyMsgList = [...arr]
            } else {
              this.historyMsgList = [ ...this.historyMsgList,...arr]
            }
            if(Object.keys(list).length<20) {
              this.page += 1;
              this.load(2);
            }
            if(this.historyMsgList.length<0){
              this.noHistory = true
            }
          } else {
            if(this.page == 1){
              this.noHistory = true
            } else {
              this.noMore = true
            }
          }
          this.loading = false
        })
      } else {
        this.noHistory = true
      }
    },

    /**
     * 评价客服
     */
    markScore() {
      this.scoreShow = !this.scoreShow
      if (this.scoreShow) {
        let $this = this
        $this.$nextTick(() => {
          let chatMain = $this.$refs.chatMain    //通过$refs获取到该元素
          chatMain.scrollTop = chatMain.scrollHeight
        })
      }
    },
    /**
     * @description: 提交评价
     * @param {*} val 评价分数
     * @return {*}
     */
    rateChange(val) {
      chatApi.completeEvaluate({
        evid: this.evid,
        format: 'json',
        roomid: localStorage.getItem('roomId'),
        score: val
      }).then(res => {
        // console.log(JSON.parse(Decrypt(res.msg)));
        this.scoreShow = false
        this.$message.success('评价成功！')
      })
    },
    /**
     * 是否为自己发的消息
     * @param id
     * @returns {boolean}
     */
    isMe(id) {
      return id == this.$store.state.userData.telphone
    },
    /**
     * 除消息区域外右键
     */
    roomListRight(item, event) {
      this.menuLeft = event.clientX
      this.menuTop = event.clientY
      this.menuVisible = false
      this.menuVisible = true
      this.roomRow = item
      setTimeout(() => {
        document.getElementById('cusSer').addEventListener('click', this.foo)
        document.getElementById('cusSer').addEventListener('contextmenu', this.foo)
      }, 50)
    },
    /**
     * 消息区域右键
     * @param item 消息
     * @param index
     * @param event
     */
    msgRight(item, index, event) {
      if (this.isMe(item.userid) || item.contenttype == 6) {
        this.msgLeft = event.clientX
        this.msgTop = event.clientY
        this.msgRightVisible = false
        this.msgRightVisible = true
        this.msgRow = item
        this.msgIndex = index
        setTimeout(() => {
          document.getElementById('cusSer').addEventListener('click', this.foo)
          document.getElementById('cusSer').addEventListener('contextmenu', this.foo)
        }, 50)
      }
    },
    /**
     * 关闭右键菜单
     */
    foo(e) {
      e.preventDefault()
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      this.msgRightVisible = false
      document.getElementById('cusSer').removeEventListener('click', this.foo)// 关掉监听，
      document.getElementById('cusSer').removeEventListener('contextmenu', this.foo)// 关掉监听，
    },

    /**
     * 是否显示撤回 俩分钟内可以撤回
     * @returns {boolean}
     */
    isShowRecall() {
      if (this.msgRow && this.isMe(this.msgRow.userid)) {
        let nowTime = Number(Math.round(new Date().getTime() / 1000).toString())
        let ss = nowTime - this.msgRow.addtime
        return ss / 60 <= 2
      } else {
        return false
      }
    },

    /**
     * 撤回
     * @returns {boolean}
     */
    handleRevoke(item) {
      chatApi.recall({
        format: 'json',
        id: item.id
      }).then(res => {
        this.recreadCall(item.id)
      }).catch(err => {
        this.$message.info('消息撤回已过期')
      })
    },

    /**
     * 下载
     */
    downLoad(item) {
      let link = document.createElement('a')
      link.href = item.content.url
      link.target = '_black'
      link.setAttribute('download', item.content.filename) //指定下载后的文件名，防跳转
      link.click()
    },

    /**
     * 发消息
     */
    handleSendMessage() {
      let msg = this.message.replace(/<div><\/div>|<div><br\/><\/div>|<div><br><\/div>/g, '')
      if (msg === '') {
        this.$message.error('不能发空消息！')
        return
      }
      let div = document.createElement('div')
      div.innerHTML = msg
      this.editText(msg)
      this.message = `<div></div>`
    },
    validDataUrl(s) {
      let str = s.toString()
      if(str.indexOf(';base64,')==-1){
        return true;
      }else{
        return false
      }
    },
    editText(msg){
      // msg
      let text = msg
      let s=text.match(/<img\s*src=\"([^\"]*?)\"[^>]*>/gi);
      if(s == null){
        this.handleSendText(text)
      } else {
        for(let i= 0;i<s.length;i++){
          text=text.replaceAll(s[i],'<split>'+s[i]+'<split>')
        }
        let list = text.split('<split>');
        list.map(item=>{
          if(s.includes(item)){
            let content = {};
            let separate = item.split(' ')
            separate.map(val => {
              if(val.indexOf('src') !== -1) {
                content.url= val.replace('src=','').replaceAll('"','')
              } else if(val.indexOf('filename') !== -1){
                content.filename= val.replace('filename=','').replaceAll('"','')
              } else if(val.indexOf('widths') !== -1) {
                content.width= val.replace('widths=','').replaceAll('"','')
              } else if(val.indexOf('heights') !== -1) {
                content.height= val.replace('heights=','').replaceAll('"','')
              }
            })
            if(this.roomCreat){
              if(this.validDataUrl(item)){
                chatApi.sendMsg({
                  format:'json',
                  list: JSON.stringify([{
                    contenttype: 4,
                    content: JSON.stringify({
                      filename:content.filename,
                      url:content.url,
                      width:content.width,
                      height:content.height
                    })
                  }]),
                  roomid: localStorage.getItem('roomId')
                }).then(res => {
                  let value = JSON.parse(JSON.parse(Decrypt(res.msg)).list)[0]
                  value.userid = this.$store.state.userData.telphone
                  value.msgtype = 2
                  this.getIpList(value)
                })
              }
            } else {
              // 离线
              chatApi.sendOffline({
                content: JSON.stringify({
                  filename:content.filename,
                  url:content.url,
                  width:content.width,
                  height:content.height
                }),
                contenttype: 4,
                format: 'json',
                groupcode: this.grouptitle
              }).then(res => {
                let msg = JSON.parse(Decrypt(res.msg))
                msg.userid = this.$store.state.userData.telphone
                msg.msgtype = 2
                this.getIpList(msg)
              }).catch(err => {
                this.$message.error('当前存在客服在线，请返回与在线客服沟通')
              })
            }
          }else{
            if(item !== ''){
              this.handleSendText(item)
            }
          }
        })
      }
    },
    /**
     * 发送文本消息
     */
    async handleSendText(text, ids) {
      if(this.roomCreat) {
        chatApi.sendMsg({
          format:'json',
          list: JSON.stringify([{
            contenttype: 1,
            content: text
          }]),
          roomid: localStorage.getItem('roomId')
        }).then(res => {
          let value = JSON.parse(JSON.parse(Decrypt(res.msg)).list)[0]
          value.userid = this.$store.state.userData.telphone
          value.msgtype = 2
          this.getIpList(value)
        })
      } else {
        // 离线
        chatApi.sendOffline({
          content: text,
          contenttype: 1,
          format: 'json',
          groupcode: this.grouptitle
        }).then(res => {
          let msg = JSON.parse(Decrypt(res.msg))
          msg.userid = this.$store.state.userData.telphone
          msg.msgtype = 2
          this.getIpList(msg)
        }).catch(err => {
          this.$message.error('当前存在客服在线，请返回与在线客服沟通')
        })
      }
    },
    /**
     * 发送文件
     */
    // async handleSendFile(file) {
    //   console.log('发送文件');
    //   console.log(file);
    //   this.$axios({
    //     method: 'post',
    //     url: 'https://wsapi.pxb7.com/api/upload/uploaddata',
    //     headers: {
    //       appid:'pxb7pcwebsadfjlk'
    //     },
    //     data: {
    //       msg: chatApi.encryptMsg({
    //         format: 'json',
    //         type: 3
    //       }),
    //       file:file[0]
    //     }
    //   }).then(res => {
    //     console.log(res);
    //   }).catch(err => {
    //     console.log(err);
    //   })
    //   // this.$refs[ref].value = ''
    //   // if(this.roomCreat) {
    //   //   chatApi.sendMsg({
    //   //     format:'json',
    //   //     list: JSON.stringify([{
    //   //       contenttype: 6,
    //   //       content: file
    //   //     }]),
    //   //     roomid: localStorage.getItem('roomId'),
    //   //   }).then(res => {
    //   //     let value = JSON.parse(JSON.parse(Decrypt(res.msg)).list)[0]
    //   //     this.getIpList(value)
    //   //   })
    //   // } else {
    //   //   // 离线
    //   //   chatApi.sendOffline({
    //   //     content: file,
    //   //     contenttype: 6,
    //   //     format: 'json',
    //   //     groupcode: 'game_test2'
    //   //   }).then(res => {
    //   //     this.getIpList(JSON.parse(Decrypt(res.msg)))
    //   //   })
    //   // }
    // },

    /**
     * 截图
     */
    screenShot() {
      this.$refs.cropper.tailoring()
    },
    getScreenShotUrls(url) {
      console.log('file',url);
    },
    /**
     * 获取截取后的图片
     * @param url
     */
    getScreenShotUrl(url,val) {
      this.message = this.message + `<img src="${url}" filename="${val.filename}" widths="${val.width}" heights="${val.height}" style="max-width:100px"/>`
    },
    gameData() {
      if(this.game_id){
        apiGameList({
          game_id:this.game_id
        }).then(res => {
          this.game_name = res.data[0].game_name
          this.grouptitle = res.data[0].game_alias
          // this.getcustomercarelist()
        })
      }
    },
  },
  created() {
    this.customercareid = this.$route.query?.id
    this.grouptitle = this.$route.query?.code
    this.game_id = this.$route.query?.game_id
    // this.gameData()
    this.avatar = this.$store.state.userData.avatar || ''
    this.getCusToken()
    this.getIpList()
  },
  computed: {
    // noMore() {
    //   return this.historyMsgList.length >= 50
    // },
    disabled() {
      return this.loading || this.noMore
    }
  },
}
</script>


<style lang="less">
.historyDialog {
  background: transparent !important;
  border-radius: 10px 10px 10px 10px !important;
  box-shadow: none !important;

  .el-dialog__header {
    display: none;
    padding: 0;
  }

  .el-dialog__body {
    padding: 0 !important;
    background: transparent;
  }

  #historyDialog {
    .hd_header {
      height: 60px;
      background: #17212D;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px 10px 0 0 !important;

      > .title {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 21px;

        img {
          width: 20px;
          height: 20px;
          margin: 0 10px 0 0;
        }
      }

      .search {
        .el-input, .el-input__inner {
          width: 250px;
          height: 40px;
          background: #FFFFFF;
          border-radius: 29px 29px 29px 29px;
          opacity: 1;
        }

        .el-input__inner::placeholder {
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #B8BCBF;
          line-height: 16px;
        }
      }
    }

    .hd_body {
      height: 540px;
      background: #F7F8FA;
      border-radius: 0 0 10px 10px;

      > .chatHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 11px 0 0;

        .currentCustomerService {
          width: calc(100% - 17px);
          display: flex;
          align-items: center;
          height: 38px;
          background: #EAF1FA;
          border-radius: 5px 5px 5px 5px;
          opacity: 1;
          border: 1px solid #75A6E5;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #75A6E5;

          .complain {
            padding: 5px 14px;
            background: #75A6E5;
            border-radius: 5px;
            margin: 0 20px 0 0;
          }
        }

        .historyRecord {
          margin: 10px 0 0;
          width: 140px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 12px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #5E6976;
          cursor: pointer;
          background: #E9EEF3;
          border-radius: 10px;
        }

      }

      > .chatMain {
        height: 520px;
        padding: 10px 20px 0;
        overflow: auto;
        .no_history{
          display: flex;
          justify-content: center;
          margin-top: 124px;
          img{
            width: 150px;
            height: 128px;
          }
        }
        .msgList {
          .msgItem {
            margin: 0 0 9px;

            .msgTime {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #A5ABB8;
              margin: 0 0 5px 57px;

              span {
                margin: 0 20px 0 0;
              }
            }

            .msgBody {
              display: flex;
              align-items: flex-start;

              .msgAvatar {
                width: 40px;
                height: 40px;
                border-radius: 20px;
                background: paleturquoise;
                margin: 0 17px 0 0;
                overflow: hidden;
                img{
                    width: 100%;
                  }
              }

              .message {
                max-width: 55%;
                padding: 12.5px 11px;
                word-break: break-all;
                word-break: break-word;
                background: #FFFFFF;
                border-radius: 5px 5px 5px 5px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 16px;
                position: relative;
              }

              .message::before, .messageFile::before {
                right: auto;
                left: -8px;
                border-left: none;
                border-top: 5px solid transparent;
                border-right: 10px solid #FFFFFF;
                border-bottom: 5px solid transparent;
                position: absolute;
                content: "";
                z-index: 1;
                width: 0;
                height: 0;
                top: 15px;
              }

              .messageFile {
                padding: 27px 50px;
                background: #FFFFFF !important;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .msgFileIcon {
                  width: 42px;
                  height: 36px;
                  margin: 0 20px 0 0;
                }

                .msgFileInfo {
                  // img{
                  //   width: 183px;
                  // }
                  .msgFileName {
                    font-size: 16px;
                    font-family: PingFangSC-Light, PingFang SC;
                    font-weight: 400;
                    color: #435160;
                    line-height: 19px;
                    margin: 0 0 3px;
                    width: 121px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  .msgFileSize {
                    font-size: 14px;
                    font-family: PingFangSC-Light, PingFang SC;
                    font-weight: 400;
                    color: #CFD3D7;
                    line-height: 16px;
                  }
                }
                &.imgMessage{
                  padding: 12.5px 11px;
                }
              }
            }
          }

          .my_msg {
            .msgTime {
              margin: 0 57px 5px 0;
              text-align: right;
            }

            .msgBody {
              flex-direction: row-reverse;

              .msgAvatar {
                margin: 0 0 0 17px;
                overflow: hidden;
                img{
                    width: 100%;
                  }
              }

              .message {
                background: #17212D;
                color: #FFFFFF;

              }

              .message::before {
                left: auto;
                right: -8px;
                border-right: none;
                border-top: 5px solid transparent;
                border-left: 10px solid #17212D;
                border-bottom: 5px solid transparent;
              }

              .messageFile::before {
                border-left: 10px solid #FFFFFF;
              }
            }
          }

          .msgDate {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #B5C2CD;
            line-height: 14px;
            margin: 10px 0;

            span {
              margin: 0 13px;
              display: inline-block;
              width: 100px;
              height: 1px;
              background: #EFEFEF;
            }
          }
          .recall_wrapper{
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ADADAD;
          }
        }
      }

      > .chatMain::-webkit-scrollbar-track {
        border-radius: 10px;
        background: transparent;
      }

      > .chatMain::-webkit-scrollbar {
        width: 3px;
        height: 4px;
      }

      > .chatMain::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(199, 226, 255, 1);
      }
    }

    .close {
      display: flex;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
        margin: 10px 0 0;
        cursor: pointer;
      }
    }
  }
}
</style>

<style scoped lang="less">
.cusSer {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .main {
    width: 900px;
    height: 700px;
    background: #F7F8FA;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    border: 1px solid #E9E9E9;

    > .title {
      height: 60px;
      background: #17212D;
      opacity: 1;
      border-radius: 10px 10px 0px 0px;
      padding: 0 27px 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .info {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        letter-spacing: 2px;

        img {
          width: 40px;
          height: 40px;
          margin: 0 20px 0 0;
        }

        span {
          font-size: 13px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
        }

        .divide {
          margin: 0 10px;
          width: 1px;
          height: 16px;
          display: inline-block;
          background: linear-gradient(183deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%);
        }
      }

      .url, .url * {
        font-size: 13px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 15px;
      }
    }

    .body {
      display: flex;

      .chat {
        width: 592px;
        height: 630px;
        background: #F7F8FA;
        opacity: 1;
        border-right: 1px solid #E9E9E9;
        border-radius: 0 0 0 10px;
        padding: 10px 0 0 11px;
        position: relative;

        .chatHeader {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 11px 0 0;

          .currentCustomerService {
            width: calc(100% - 17px);
            display: flex;
            align-items: center;
            height: 38px;
            background: #EAF1FA;
            border-radius: 5px 5px 5px 5px;
            opacity: 1;
            border: 1px solid #75A6E5;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #75A6E5;

            .complain {
              padding: 5px 14px;
              background: #75A6E5;
              border-radius: 5px;
              margin: 0 20px 0 0;
            }
          }

          .historyRecord {
            margin: 10px 0 0;
            width: 140px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #5E6976;
            cursor: pointer;
            background: #E9EEF3;
            border-radius: 10px;
          }

        }

        .chatMain {
          height: 325px;
          margin: 8px 0;
          padding: 0 20px;
          overflow: auto;

          .msgList {
            .msgItem {
              padding: 0 11px 0 0;
              margin: 0 0 9px;

              .msgTime {
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #A5ABB8;
                margin: 0 0 6px 57px;

                span {
                  margin: 0 20px 0 0;
                }
              }

              .msgBody {
                display: flex;
                align-items: flex-start;

                .msgAvatar {
                  width: 40px;
                  height: 40px;
                  border-radius: 20px;
                  background: paleturquoise;
                  margin: 0 17px 0 0;
                  overflow: hidden;
                  img{
                    width: 100%;
                  }
                }

                .message {
                  max-width: 55%;
                  padding: 12.5px 11px;
                  word-break: break-all;
                  word-break: break-word;
                  background: #FFFFFF;
                  border-radius: 5px 5px 5px 5px;
                  font-size: 14px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #000000;
                  line-height: 16px;
                  position: relative;
                }

                .message::before, .messageFile::before {
                  right: auto;
                  left: -8px;
                  border-left: none;
                  border-top: 5px solid transparent;
                  border-right: 10px solid #FFFFFF;
                  border-bottom: 5px solid transparent;
                  position: absolute;
                  content: "";
                  z-index: 1;
                  width: 0;
                  height: 0;
                  top: 15px;
                }

                .messageFile {
                  padding: 27px 50px;
                  background: #FFFFFF !important;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  .msgFileIcon {
                    width: 42px;
                    height: 36px;
                    margin: 0 20px 0 0;
                  }

                  .msgFileInfo {
                    // img{
                    //   width: 183px;
                    // }
                    .msgFileName {
                      font-size: 16px;
                      font-family: PingFangSC-Light, PingFang SC;
                      font-weight: 400;
                      color: #435160;
                      line-height: 19px;
                      margin: 0 0 3px;
                      width: 121px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }

                    .msgFileSize {
                      font-size: 14px;
                      font-family: PingFangSC-Light, PingFang SC;
                      font-weight: 400;
                      color: #CFD3D7;
                      line-height: 16px;
                    }
                  }
                  &.imgMessage{
                    padding: 12.5px 11px;
                  }
                }
              }
            }

            .my_msg {
              .msgTime {
                margin: 0 57px 6px 0;
                text-align: right;
              }

              .msgBody {
                flex-direction: row-reverse;

                .msgAvatar {
                  margin: 0 0 0 17px;
                  overflow: hidden;
                  img{
                    width: 100%;
                  }
                }

                .message {
                  background: #17212D;
                  color: #FFFFFF;

                }

                .message::before {
                  left: auto;
                  right: -8px;
                  border-right: none;
                  border-top: 5px solid transparent;
                  border-left: 10px solid #17212D;
                  border-bottom: 5px solid transparent;
                }

                .messageFile::before {
                  border-left: 10px solid #FFFFFF;
                }
              }
            }
            .recall_wrapper{
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ADADAD;
              text-align: center;
              line-height: 30px;
            }
          }
        }

        .newMsg {
          position: absolute;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #4594E8;
          display: flex;
          align-items: baseline;
          padding: 7px 15px 6px;
          background: #FFFFFF;
          box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
          left: calc(50% - 50px);
          bottom: 219px;
          cursor: pointer;

          img {
            margin: 0 4px 0 0;
          }
        }

        .score {
          margin: 19px 0 0;
          padding: 0 0 10px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .scoreHeader {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: PingFang SC-Light, PingFang SC;
            font-weight: 400;
            color: #A5ABB8;
            line-height: 14px;
            margin: 0 0 19px;

            > span {
              display: inline-block;
              margin: 0 10px;
              width: 83px;
              height: 1px;
              background: linear-gradient(270deg, #A5ABB8 0%, rgba(255, 255, 255, 0) 100%);
              border-radius: 7px 7px 7px 7px;
            }

            > span:last-of-type {
              background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #A5ABB8 100%);
            }
          }

          .scoreBody {
            height: 33px;

            /deep/ .el-rate__icon {
              font-size: 28px !important;
              margin: 0 10px !important;
            }

            /deep/ .el-rate__text {
              font-size: 12px;
              font-family: PingFang SC-Light, PingFang SC;
              font-weight: 400;
              color: #A5ABB8 !important;
              line-height: 14px;
              vertical-align: bottom;
            }

            /deep/ .el-rate__text::after {
              margin: 0 3px;
              content: ')';
            }

            /deep/ .el-rate__text::before {
              margin: 0 3px;
              content: '(';
            }

          }
        }

        .chatMain::-webkit-scrollbar-track {
          border-radius: 10px;
          background: transparent;
        }

        .chatMain::-webkit-scrollbar {
          width: 3px;
          height: 4px;
        }

        .chatMain::-webkit-scrollbar-thumb {
          border-radius: 5px;
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: rgba(199, 226, 255, 1);
        }

        .chatBtn {
          display: flex;
          align-items: center;
          padding: 0 0 10px;
          border-bottom: 1px solid #E9E9E9;
          margin: 0 11px 0 0;

          .btn {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #696969;
            line-height: 17px;
            padding: 5px 16px;
            border-radius: 28px 28px 28px 28px;
            border: 1px solid #E8E8E8;
            cursor: pointer;
            margin: 0 0 0 10px;

            img {
              margin: 0 3px 0 0;
            }
          }

          .btn:hover {
            border: 1px solid #FFAA00;
          }
        }

        .chatEnter {
          margin: 8px 20px 0 0;

          .chatEnterHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .chatEnterTools {
              display: flex;
              align-items: center;

              .chatTool {
                width: 23px;
                height: 23px;
                margin: 0 7.5px;
                cursor: pointer;

                .iconfont {
                  font-size: 23px !important;
                }
              }

              .chatTool:hover {
                .iconfont {
                  font-size: 23px !important;
                  color: #4594E8;
                }
              }
            }

            .chatEnterSend {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 30px;
              text-align: center;
              width: 70px;
              height: 30px;
              background: #4594E8;
              border-radius: 6px 6px 6px 6px;
              cursor: pointer;
            }

          }

          .chatEnterInput {
            ::v-deep.edit-div {
              resize: none;
              height: 98px;
              background: #F7F8FA;
              border: none;

              &:focus {
                border-color: #FFDB51;
              }
            }
          }
        }
      }

      .baseInfo {
        width: 297px;
        background: #FFFFFF;
        border-radius: 0 0 10px 0;

        .logo {
          margin: 20px 0 0 8px;
        }

        .Card {
          margin: 20px 19px 0;

          .title {
            border-bottom: 1px solid #EFEFEF;
            margin: 0 0 20px;

            .text {
              display: inline-block;
              font-size: 16px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 600;
              color: #4594E8;
              line-height: 19px;
              padding: 0 0 5px;
              margin: 0 0 0 10px;
              border-bottom: 2px solid #4594E8;
            }
          }

          .cardBody {
            margin: 0 0 0 10px;
            display: flex;
            align-items: center;

            .avatar {
              width: 50px;
              height: 50px;
              border-right: 25px;
              margin: 0 20px 0 0;
            }

            .other {
              > div {
                font-size: 12px;
                font-family: PingFang SC-Light, PingFang SC;
                font-weight: 400;
                color: #000000;
                display: flex;
                align-items: center;
                margin: 4.5px 0;

                span {
                  color: #8B8B8B;
                }

                /deep/ .el-rate {
                  display: inline-block;
                }
              }
            }

            .content {
              font-size: 14px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 400;
              color: #909AA5;
              line-height: 23px;
              height: 267px;
              overflow: auto;
              padding: 0 25px 0 0;
              word-break: break-all;
              word-wrap: break-word;
            }

            .content::-webkit-scrollbar-track {
              border-radius: 10px;
              background: transparent;
            }

            .content::-webkit-scrollbar {
              width: 3px;
              height: 4px;
            }

            .content::-webkit-scrollbar-thumb {
              border-radius: 5px;
              -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
              background: rgba(199, 226, 255, 1);
            }
          }
        }

        .aboutCard {
          margin: 20px 0 0;

          .title {
            margin: 0 19px;
          }

          .cardBody {
            flex-direction: column;
            margin: 0;

            .content {
              margin: 0 0 0 29px;
            }
            .complain{
              width: 100%;
            }
          }
        }
      }
    }
  }

  .msgRight {
    position: absolute;
    z-index: 9999;
    width: 61px;
    background: #FFFFFF;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    .item {
      text-align: center;
      height: 25px;
      line-height: 25px;
      font-size: 14px;
      font-family: PingFang SC-Light, PingFang SC;
      font-weight: 300;
      color: #9F9F9F;
      cursor: pointer;

      &:hover {
        background: #FFFAF0;
        color: #FFC251;
      }
    }
  }
}
</style>
