<template>
  <div class="buyer-contract">
    <div class="buyer-top">
      <div class="top-con">
        <div class="container">
          <img src="@/assets/image/Contract/title.png"/>
          <span>订单编号：{{ buyerForm.order_sn }}</span>
        </div>
        <div class="logo"></div>
      </div>
    </div>
    <div class="buyer-main">
      <div class="form">
        <el-form ref="form" label-position="top" label-width="200px" :model="buyerForm" :rules="rules">
          <!-- <div class="contract_order_sn">
            订单编号：{{ buyerForm.order_sn }}
          </div> -->
          <el-form-item prop="source" class="contract_radio">
            <div slot="label" class="item-label">
              <!-- <span> -->
                <i>您是从哪里知晓uushouyou游戏服务网的？</i>
                <i class="item-radio">(单选)</i>
              <!-- </span> -->
            </div>
            <el-radio  label="1" v-model="buyerForm.source">贴吧</el-radio>
            <el-radio  label="2" v-model="buyerForm.source">主播</el-radio>
            <el-radio  label="3" v-model="buyerForm.source">号商</el-radio>
            <el-radio  label="4" v-model="buyerForm.source">朋友推荐</el-radio>
            <el-radio  label="5" v-model="buyerForm.source">网页搜索</el-radio>
            <el-radio  label="6" v-model="buyerForm.source">小程序公众号</el-radio>
            <el-radio  label="7" v-model="buyerForm.source">抖音</el-radio>
            <el-radio  label="8" v-model="buyerForm.source">B站(bilbili)</el-radio>
            <el-radio  label="9" v-model="buyerForm.source">老客户</el-radio>
            <el-radio  label="10" v-model="buyerForm.source">微博</el-radio>
            <el-radio  label="0" v-model="buyerForm.source">其他</el-radio>
            <el-radio style="visibility: hidden" disabled label="11" v-model="buyerForm.source"></el-radio>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="buyerForm.name" placeholder="请输入姓名" :disabled="nameShow"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="card_id">
            <el-input v-model="buyerForm.card_id" placeholder="请输入身份证号" :disabled="cardIdShow"></el-input>
          </el-form-item>
          <el-form-item label="现住地址" prop="address">
            <el-input type="textarea" v-model="buyerForm.address" resize="none" :rows="3"
                placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <el-form-item label="通讯地址" prop="card_address">
            <el-input type="textarea" v-model="buyerForm.card_address" resize="none" :rows="3"
                placeholder="请输入身份证上的地址"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="mobile">
            <el-input v-model="buyerForm.mobile" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱(如没有邮箱请跳过)" prop="email">
            <el-input v-model="buyerForm.email" placeholder="请输入电子邮箱"></el-input>
          </el-form-item>
          <!-- <el-form-item label="法大大注册账号" prop="fdd_account">
            <el-input v-model="buyerForm.fdd_account" placeholder="请输入法大大注册账号"></el-input>
          </el-form-item> -->
          <el-form-item label="身份证照片" prop="frontIdPhoto" class="form-item-id-card">
            <div class="id-card">
              <el-upload
                v-loading="loading"
                action
                :disabled="!frontIdPhotoShow"
                :style="!frontIdPhotoShow ? 'cursor:not-allowed;' : ''"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="upload">
                <div class="img-box" v-show="buyerForm.frontIdPhoto !== ''">
                  <img :src="buyerForm.frontIdPhoto">
                  <div class="mask" v-show="frontIdPhotoShow">
                    <span>重新上传</span>
                  </div>
                </div>
              </el-upload>
              <!-- <img v-if="buyerForm.frontIdPhoto" :src="buyerForm.frontIdPhoto"> -->
              <!-- <input type="file" @change="upload" :disabled="!frontIdPhotoShow" :style="!frontIdPhotoShow ? 'cursor:not-allowed;' : ''"> -->
            </div>
          </el-form-item>
          <el-form-item label="备注信息" prop="note">
            <el-input type="textarea" v-model="buyerForm.note" resize="none" :rows="4" placeholder="请输入备注信息"></el-input>
          </el-form-item>
          <div class="fdd-tip">
            <img src="@/assets/image/Contract/fddTip.png"/>
            本平台电子合同由法大大提供技术支持
          </div>
        </el-form>
      </div>
      <div class="buyer-btn">
        <el-button @click="resetForm()" class="reset">重填</el-button>
        <el-button type="primary" @click="save()" class="sub">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {apiBuyerContract, apiSellCard, appUploadAuth, apiuserContract, apiContractDetails} from "../../request/API";
import * as imageConversion from 'image-conversion'
/**
 * 表单验证失败时 滚动到第一个error位置
 */
const scrollIntoView = (className = "is-error", block = "center") => {
    Promise.resolve().then(() => {
       const target = document.getElementsByClassName(className);
     if (target.length) {
        target[0].scrollIntoView({
           behavior: "smooth",
           block: block,
       });
    }
  });
};
export default {
  name: 'buyerContract',
  data() {
    return {
      loading: false,
      buyerForm: {
        contract_id: '',
        order_sn: '',
        name: '',
        card_id: '',
        address: '',
        card_address: '',
        mobile: '',
        email: '',
        fdd_account: '1',
        frontIdPhoto: '',
        note: '',
        source: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
          { max: 30, message: '姓名不得超过30个汉字', trigger: 'blur' }
        ],
        card_id: [
          {required: true, message: '请输入身份证号', trigger: 'blur'},
          { min: 18, max: 18, message: '请输入18位身份证号', trigger: 'blur' }
        ],
        address: [
          {required: true, message: '请输入现住地址', trigger: 'blur'},
          { min: 5, max: 100, message: '请输入5~100个字的准确现住地址', trigger: 'blur' }
        ],
        card_address: [
          {required: true, message: '请输入通讯地址', trigger: 'blur'},
          { min: 5, max: 100, message: '请输入5~100个字的准确通讯地址', trigger: 'blur' }
        ],
        mobile: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {min:11, max: 11, message: '请输入11位手机联系号码', trigger: 'blur' }
        ],
        email: [
          { max: 50, message: '邮箱地址不得超过50位', trigger: 'blur' }
          // {required: true, message: '请输入电子邮箱', trigger: 'blur'},
        ],
        // fdd_account: [
        //   {required: true, message: '请输入法大大注册账号', trigger: 'blur'},
        // ],
        frontIdPhoto: [
          {required: true, message: '请上传身份证照片', trigger: 'change'},
        ],
        source: [
          {required: true, message: '请选择选项', trigger: 'change'},
        ],
      },
      isSave: false,
      nameShow: false,
      frontIdPhotoShow: true,
      cardIdShow: false,
      userToken: '',
      app_status: 1
    }
  },
  created() {
    this.userToken = this.$route.query?.userToken
    this.buyerForm.contract_id = this.$route.query.contract_id
    if(this.userToken){
      let status = true
      this.$store.commit('set_token', this.userToken)
      this.$store.commit('set_loginStatus', status)
      this.app_status = 2
    } else{
      if (this.$store.state.loginStatus) {
        this.app_status = 1
      } else{
        this.$router.push({
          path: '/login',
          query: {
            Rurl: this.$route.path+'?contract_id='+this.buyerForm.contract_id,
          },
        })
      }
    }

    apiuserContract(this.app_status).then(res => {
      this.buyerForm.name = res.data.name || ''
      this.buyerForm.name == '' ? this.nameShow = false : this.nameShow = true
      this.buyerForm.card_id = res.data.card_id || ''
      this.buyerForm.card_id == '' ? this.cardIdShow = false : this.cardIdShow = true
      this.buyerForm.address = res.data.address || ''
      this.buyerForm.card_address = res.data.card_address || ''
      this.buyerForm.email = res.data.email || ''
      this.buyerForm.fdd_account = res.data.fdd_account || '1'
      this.buyerForm.frontIdPhoto = res.data.frontIdPhoto || ''
      this.buyerForm.frontIdPhoto == '' ? this.frontIdPhotoShow = true : this.frontIdPhotoShow = false
      this.buyerForm.note = res.data.note || ''
      this.buyerForm.mobile = res.data.mobile || ''
    })
    // 获取订单编号
    apiContractDetails(this.app_status,{contract_id: this.$route.query.contract_id}).then(res => {
      this.buyerForm.order_sn = res.data.order_sn
      let already = res.data.buy_user_id
      if(already !== null && already !=='' && already !== 0){
        this.$router.push({
          name: 'SubmitSuccess',
          query: {
            contract_id : this.buyerForm.contract_id,
            type: 1,
            already: 1,
            app_status: this.app_status
          }
        })
      }
      if (this.$store.state.loginStatus){
        if(this.userToken){
        } else {
          let tel = res.data.buy_nickname
          if(tel !== this.$store.state.userData.telphone){
            let telStart = tel.substring(0,3)
            let telEnd = tel.substring(7,11)
            this.$alert('当前用户无此合同编号，请重新登录'+ telStart + '****' + telEnd +'后填写',{
              confirmButtonText: '确定',
              center: true,
              showClose: false
            }).then(() => {
              this.$router.push({
                path: '/login',
                query: {
                  Rurl: this.$route.path+'?contract_id='+this.buyerForm.contract_id,
                },
              })
            }).catch(() => {})
          }
        }
      } else{
        this.$router.push({
          path: '/login',
          query: {
            Rurl: this.$route.path+'?contract_id='+this.buyerForm.contract_id,
          },
        })
      }

    })
    // this.buyerForm.mobile = this.$store.state.userData.telphone
  },
  methods: {
    /**
     * @description: 生成唯一标识
     * @return {*}
     */
    uuid() {
        let s = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";
        let uuid = s.join("");
        return uuid;
    },
    async upload(file) {
      this.loading = true
      const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message({
          message: '上传图片只能是 JPG/PNG 格式!',
          center: true,
          offset: 200,
          customClass: 'sell-contract-msg'
        })
      }
      // if (!isLt5M) {
      //   this.$message({
      //     message: '上传图片大小不能超过 5MB!',
      //     center: true,
      //     offset: 200,
      //     customClass: 'sell-contract-msg'
      //   })
      // }
     let newFile = await imageConversion.compressAccurately(file.raw, {
        size: 900,
        type: 'image/jpeg'
      })
      let blob_file = new File([newFile],this.uuid()+".jpeg",{type:file.type,lastModified: Date.now()})
      this.$getBase64(newFile).then(res => {
        appUploadAuth(this.app_status,{
          file:res
        }).then(res=>{
          let formData = new FormData()
          formData.append('file', blob_file)
          apiSellCard(this.app_status,formData).then(resp => {
            this.buyerForm.frontIdPhoto = resp.data.file_path
            this.buyerForm.name = res.data.cert_name
            this.buyerForm.card_id = res.data.cert_id
            if(this.buyerForm.name == '' || this.buyerForm.card_id == ''){
              this.buyerForm.frontIdPhoto = ''
              this.$message({
                message: '验证失败，请重新上传',
                center: true,
                offset: 200,
                customClass: 'sell-contract-msg'
              })
            } else {
              this.nameShow = true
              this.cardIdShow = true
              this.frontIdPhotoShow = false
              this.$message({
                message: '验证通过',
                center: true,
                offset: 200,
                customClass: 'sell-contract-msg'
              })
            }
            this.loading = false
          }).catch(err => {
            this.$message({
              message: err.msg,
              center: true,
              offset: 200,
              customClass: 'sell-contract-msg'
            })
            this.loading = false
          })
        }).catch(err=>{
          this.$message({
            message: err.msg,
            center: true,
            offset: 200,
            customClass: 'sell-contract-msg'
          })
          this.loading = false
        })
      });
      // return;
      // if (e.target.files.length > 0) {
      //   let formData = new FormData()
      //   formData.append('file', e.target.files[0])
      //   apiSellCard(formData).then(res => {
      //     this.buyerForm.frontIdPhoto = res.data.file_path
      //   }).catch(err => {
      //     this.$message.error(err.msg)
      //   })
      // }
    },
    resetForm() {
      // let name = this.buyerForm.name
      // let card_id = this.buyerForm.card_id
      this.$refs['form'].resetFields();
      // this.buyerForm.name = name
      // this.buyerForm.card_id = card_id
      this.buyerForm.mobile = ''
      this.nameShow = false
      this.cardIdShow = false
      this.frontIdPhotoShow = true
    },
    save() {
      if (this.isSave) {
        return
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.isSave = true
          apiBuyerContract(this.app_status,this.buyerForm).then(res => {
            if(res.code == 200){
              this.$router.push({
                name: 'SubmitSuccess',
                query: {
                  contract_id : this.buyerForm.contract_id,
                  type: 1,
                  app_status: this.app_status
                }
              })
              /* this.$alert(res.msg, '', {
                showClose: false,
                confirmButtonText: '确定',
              }); */
            }else{
              this.$message({
                message: res.msg,
                center: true,
                offset: 200,
                customClass: 'sell-contract-msg'
              })
            }
            this.isSave = false
          }).catch(err => {
            this.$message({
              message: err.msg,
              center: true,
              offset: 200,
              customClass: 'sell-contract-msg'
            })
            this.isSave = false
          })
        }else{
          scrollIntoView()
          this.$message({
            message: '还有未填项',
            center: true,
            offset: 200,
            customClass: 'sell-contract-msg'
          })
        }
      })
    }
  }
}
</script>

<style lang="less">
.sell-contract-msg {
  min-width: 110px !important;
  min-height: 40px;
  background: rgba(0, 0, 0, 0.70) !important;
  border-radius: 4px !important;

  .el-message__content {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #ffffff !important;
  }

  .el-message__icon {
    display: none;
  }
}

.buyer-contract {
  background-color: #f9fafb;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  .buyer-top {
    height: 278px;
    width: 100vw;
    background-color: #5071C8;
    .top-con{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    .container {
      padding-top: 63px;
      padding-left: 20px;

      img {
        width: 224px;
        height: 30px;
      }

      span {
        display: block;
        margin-top: 13px;
        font-size: 20px;
        color: #fff;
        font-family: PingFang SC, PingFang SC-Medium;
      }
    }
    .logo{
      margin-top: 63px;
      background: url('../../assets/image/Contract/logo.png') no-repeat;
      background-size: 100%;
      width: 250px;
      height: 58px;
      margin-right: 20px;
    }
  }

  .buyer-main {
    position: relative;
    top: -123px;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 57px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);

    .form {
      max-width: 400px;
      margin: 0 auto;
      padding-top: 30px;
      .contract_order_sn{
        background: #eef3ff;
        border-radius: 9px;
        margin: 39px 0 32px;
        padding: 13px 17px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #1e3c87;
      }
      .el-form-item {
        margin-bottom: 20px;

        .el-form-item__label {
          display: inline;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: bold;
          text-align: LEFT;
          color: #5b5b5b;
          display: flex;
          justify-content: flex-start;
          .item-label{
            flex: 1;
            display: flex;
            justify-content: space-between;
            i{
              font-style: normal;
              &.item-radio{
                font-size: 16px;
                color: #8799af;
              }
            }
          }
        }

        .el-form-item__error {
          right: 0;
          left: auto;

          &::before {
            position: absolute;
            content: ' ';
            left: -16px;
            top: 3.5px;
            width: 13px;
            height: 13px;
            background-image: url("../../assets/image/Contract/err.png");
            background-size: 100%;
          }
        }
      }
      .contract_radio{
        .el-form-item__content{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          margin-top: 14px;
          &::before,&::after{
            display: none;
          }
        }
        .el-radio{
          margin: 0;
        }
        .el-radio__input{
          display: none;
        }
        .el-radio__input.is-checked+.el-radio__label{
          color: #FF8A00;
          background: #feedcb;
        }
        .el-radio__label{
          display: inline-block;
          width: 168px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #f7f8f9;
          border-radius: 20px;
          margin-bottom: 10px;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #393939;
          padding: 0;
        }
      }

      .form-item-id-card {
        .el-form-item__error {
          left: 40%;
        }
      }

      .point {
        padding: 9px 20px;
        margin-top: 5px;
        line-height: 16px;
        background: #fff0f0;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Light;
        color: #5b5b5b;

        span {
          color: #FF750C;
        }
      }

      .id-card {
        position: relative;
        cursor: pointer;
        margin: 10px auto;
        width: 195px;
        height: 105px;
        background-image: url("../../assets/image/Contract/IDCard.png");
        background-size: 100%;
        .el-upload{
          width: 195px;
          height: 105px;
        }
        .img-box {
          position: relative;
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }

          .mask {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.50);
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              display: inline-block;
              width: 70px;
              height: 26px;
              line-height: 26px;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: center;
              color: #ffffff;
              border: 1px solid #ffffff;
              border-radius: 4px;
            }
          }
        }

        input[type="file"] {
          position: absolute;
          top: 0;
          z-index: 2;
          cursor: pointer;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
      .fdd-tip{
        height: 33px;
        background: #f0f4ff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #1e3c87;
        img{
          width: 20px;
          height: 12px;
          margin-right: 5px;
        }
      }
    }

    .buyer-btn {
      width: 80%;
      margin: 0 auto;
      margin-top: 60px;
      display: flex;
      justify-content: center;

      button {
        width: 250px;
        height: 50px;
        border-radius: 30px;
        font-size: 18px;
        font-weight: 500;
        display: inline-block;
        text-align: center;
      }

      .reset {
        border: 1px #000 solid
      }

      .sub {
        border: 1px #FFDB51 solid;
        background-color: #FFDB51;
        color: #000;
        margin-left: 30px;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .buyer-top{
      .top-con{
        width: 95vw;
      }
    }

    .buyer-main {
      width: 95vw;
    }
  }
  @media screen and (max-width: 900px) {
    .buyer-top {
      height: 140px;
      .top-con{
        display: flex;
        justify-content: center;
        position: relative;
      }

      .container {
        margin: 0 auto;
        text-align: center;
        padding-top: 23px;
        img {
          width: 130px;
          height: 18px;
        }
        span{
          font-size: 14px;
          margin-top: 7px;
        }
      }
      .logo{
        position: absolute;
        padding: 0;
        top: 110px;
        z-index: 1;
        background: url('../../assets/image/Contract/logo-m.png') no-repeat;
        background-size: 100%;
        width: 139px;
        height: 33px;
        margin: 0;
      }
    }
    .buyer-main {
      top: -53px;
      padding-top: 67px;
      .form{
        .contract_order_sn{
          margin-top: 0;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .buyer-main {
      width: 93vw;

      .form {
        width: 80vw;
        padding-top: 10px;
        .el-form-item{
          .el-form-item__label{
            font-size: 14px;
          }
        }
        .contract_radio{
          .el-radio__label{
            width: 136px;
            font-size: 14px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 380px) {
    .buyer-main {
      .form {
        .contract_radio{
          .el-radio__label{
            width: 123px;
          }
        }
      }
    }
  }
}
</style>
