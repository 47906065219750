import axios from "axios";
import qs from "qs";
import evn from "../../util/evn";

import { Message } from "element-ui";

let baseUrl = evn.IMSApi;
const TIME_OUT = 60 * 1000; // 超时时间300秒
const appid = 'pxb7pcwebsadfjlk'

axios.interceptors.response.use(
  (data) => {
    if (data.data.errcode === -1) {
      Message.error({
        message: data.data.msg,
      });
      return Promise.reject(data);
    }
    return data;
  },
  (err) => {
    console.log(err,'err--')
    return Promise.reject(err);
  }
);

const _request = (method, url, data, head) => {
  
  const headers = {
    "Content-Type":"multipart/form-data; charset=UTF-8",
    'appid':appid,
  };
  let configData = {
    url, // 请求的地址
    timeout: TIME_OUT, // 超时时间, 单位毫秒
    headers: {
      ...headers,
      ...head
    },
    baseURL : baseUrl,
    transformRequest:[function (data1,headers){
      //这个请求不需要请求头(在此处删除，上方设置的请求头。)
      if(headers.Authorization){
        delete headers.Authorization;
        delete headers.LoginStatus
      }
      // console.log(headers)
      return data1;
    }]
     
  };
  // method = method.toLowerCase();
  // configData.method = "post";

  if (method === "formdata") {
    configData.method = "post";
    configData.headers["Content-Type"] = "multipart/form-data; charset=UTF-8";
    configData.data = data;
  } else {
    configData.method = "post";
    configData.headers["Content-Type"] = "application/json; charset=UTF-8";
    configData.data = JSON.stringify(data);
  }
  // configData.data = data;
  // console.log('configData',configData)
  return axios(configData);
};

export default function ajax(url = "", data = {}, type = "GET", head = {}) {
  if (!url) return;
  return new Promise(function (resolve, reject) {
    let promise;
    promise = _request(type, url, data, head);
    promise
      .then((response) => {
        
        if (response) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        
        reject(error);
      });
  });
}
