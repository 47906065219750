import store from "@/store";

export default function kf53Client(){
  // (function () {
  //   var _53code = document.createElement("script");
  //   if (typeof store.state.userData.user_id == 'undefined') {
  //     _53code.src = "https://tb.53kf.com/code/code/1d02d00edbb13878088ba0b015676b7e9/1";
  //   }else {
  //     _53code.src = `https://tb.53kf.com/code/code/1d02d00edbb13878088ba0b015676b7e9/1?u_cust_id=${this.$store.state.userData.user_id}&u_cust_name=${this.$store.state.userData.nickname}`;
  //   }
  //   var s = document.getElementsByTagName("script")[0];
  //   s.parentNode.insertBefore(_53code, s);
  //   _53code.onload = () => {
  //     var api53 = $53.createApi()
  //     api53.push('cmd', 'member');
  //     if (typeof store.state.userData.user_id == 'undefined') {
  //       api53.push('id', '');
  //       api53.push('name', '');
  //     } else {
  //       api53.push('id', store.state.userData.user_id);
  //       api53.push('name', store.state.userData.nickname);
  //     }
  //     api53.query();
  //   }
  // })();

}
