<template>
  <div class="login">
    <div class="main">
      <div class="left">
        <img class="logo" src="../../assets/image/Login/logo.png" alt="">
        <router-link to="/" class="back">
          进入网站
          <img src="../../assets/image/Login/backBtn.png" alt="">
        </router-link>
      </div>
      <div class="right">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="密码登录" name="password_login">
            <div class="input-box">
              <el-form :model="loginForm" :rules="loginFormRules" ref="loginForm" class="loginForm">
                <el-form-item prop="phone" >
                  <el-input placeholder="请输入手机号"  autocomplete="new-password" v-model.trim="loginForm.phone">
                    <i slot="prefix" class="el-input__icon iconfont pxb7-login-phone"></i>
                  </el-input>
                </el-form-item>
                <el-form-item prop="pwd" >
                  <inputPwd placeholder="请设置登录密码" :pwd="loginForm.pwd" @inputChange="(val)=>{loginForm.pwd = val}"></inputPwd>
                </el-form-item>
              </el-form>
            </div>
            <div class="submitBtn" @click="clickLogin('loginForm')" >
              登录
            </div>
            <div class="other-box">
              <div class="links">
                <router-link :to="{name:'ForgetPassword'}">忘记密码</router-link>
                <span />
                <a @click="clickRegister">注册</a>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="短信登录" name="message_login">
            <div class="input-box">
                <el-form :model="codeLoginForm" :rules="codeLoginFormRules" ref="codeLoginForm" class="codeLoginForm">
                  <el-form-item prop="phone" >
                    <el-input placeholder="请输入手机号"  autocomplete="new-password" v-model.trim="codeLoginForm.phone">
                      <i slot="prefix" class="el-input__icon iconfont pxb7-login-phone"></i>
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="smsCode" class="inputBtnBox">
                    <inputPwd placeholder="请输入验证码" :pwd="codeLoginForm.smsCode" @inputChange="(val)=>{codeLoginForm.smsCode = val}">
                      <i slot="myprefix" class="el-input__icon iconfont pxb7-login-verify"></i>
                    </inputPwd>
                    <div class="inputBtn" v-if="smsCode" @click="getSmsCode(codeLoginForm.phone)">获取验证码</div>
                    <div v-else class="inputBtn" style="color:#BABABA;cursor:not-allowed">{{ smsTime }}秒后重发</div>
                  </el-form-item>
                </el-form>
          </div>
            <div class="submitBtn" @click="smsLogin('codeLoginForm')">
              登录
            </div>
            <div class="other-box">
              <div class="links">
                <router-link :to="{name:'ForgetPassword'}">忘记密码</router-link>
                <span />
                <a @click="clickRegister">注册</a>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import {apiLogin, apiSmsSend, apiSmsLogin} from '@/request/API';
import vuex from "@/store";
import inputPwd from '@/components/inputPwd'

export default {
  name: '',
  props: {},
  components: {
    inputPwd
  },
  data() {
    return {
      activeName: 'password_login',
      account: '',
      password: '',
      sms: '',
      checked: false,
      //获取验证码
      smsCode: true,
      smsTime: 60,
      smsBtn:false,
      passwordType:'password',
      //密码登录表单
      loginForm: {
        phone: '',
        pwd: '',
        autoLogin: ''
      },
      loginFormRules:{
        phone:[
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/, message: '请输入正确的手机号', trigger: 'blur'}
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },
      //验证码登录表单
      codeLoginForm: {
        phone: '',
        smsCode: '',
      },
      codeLoginFormRules: {
        phone:[
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/, message: '请输入正确的手机号', trigger: 'blur'}
        ],
        smsCode:[
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    // 注册
    clickRegister() {
      if(!this.$route.query.Rurl){
        this.$router.push({
          name: 'Register'
        });
      }else{
        this.$router.push({
          name: 'Register',
          query: {
            Rurl: this.$route.query.Rurl
          }
        })
      }
    },
    handleClick(tab, event) {
      this.codeLoginForm.phone = this.loginForm.phone
      this.loginForm.phone = this.codeLoginForm.phone
      this.loginForm.pwd = ''
      this.codeLoginForm.smsCode = ''
    },
    /**
     * 登录
     * @param phone
     * @param pwd
     */
    clickLogin(formName) {
      this.$refs[formName].validate((valid) => {
          if (valid) {
            apiLogin({
              phone: this.loginForm.phone.trim(),
              pwd: this.loginForm.pwd.trim()
            }).then(res => {
              this.token = res.data.token
              this.refresh_token = res.data.refresh_token
              let status = true
              this.$store.commit('set_token', this.token)
              this.$store.commit('set_refresh_token', this.refresh_token)
              this.$store.commit('set_userData', res.data)
              this.$store.commit('set_loginStatus', status)
              this.$store.state.loginStatus = true
              this.$store.state.userData = res.data
              // var api53 = $53.createApi()
              // api53.push('cmd', 'member');
              // api53.push('id', this.$store.state.userData.user_id);
              // api53.push('name', this.$store.state.userData.nickname);
              // api53.query();
              if (!this.$route.query.Rurl) {
                this.$router.push({
                  path: '/'
                });
              } else {
                this.$router.push({
                  path: this.$route.query.Rurl,
                  query: {
                    game_name: this.$route.query.game_name,
                    game_id: this.$route.query.game_id,
                    id: this.$route.query.id,
                  }
                })
              }
            }).catch(err => {
              this.$message({
                message: err.msg,
                type: 'error',
                center: true
              });
              this.password = ''
            })
          }
    })
      // // 判断是否需要记住密码
      // if (this.checked) {
      //   // 传入账号名，密码，和保存天数3个参数
      //   this.setCookie(this.account, this.password, this.checked, 7)
      // } else {
      //   // 清空Cookie
      //   this.clearCookie()
      // }

    },
    /**
     * 获取验证码
     * @param phone
     */
    getSmsCode(phone) {
      apiSmsSend({
        phone: phone,
        codeType: 1
      }).then(res => {
        this.$message({
          type: "success",
          center: true,
          message: "发送成功"
        })
        this.smsCode = false
      }).catch(err => {
        this.$message({
          type: "error",
          center: true,
          message: err.msg
        })
      })

    },
    /**
     * 验证码登录
     * @param phone   手机号码
     * @param smsCode 短信验证码
     */
    smsLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          apiSmsLogin({
            phone: this.codeLoginForm.phone,
            smsCode: this.codeLoginForm.smsCode,
          }).then(res => {
            this.token = res.data.token
            this.refresh_token = res.data.refresh_token
            let status = true
            this.$store.commit('set_token', this.token)
            this.$store.commit('set_refresh_token', this.refresh_token)
            this.$store.commit('set_userData', res.data)
            this.$store.commit('set_loginStatus', status)
            this.$store.state.loginStatus = true
            this.$store.state.userData = res.data
            // var api53 = $53.createApi()
            // api53.push('cmd', 'member');
            // api53.push('id', this.$store.state.userData.user_id);
            // api53.push('name', this.$store.state.userData.nickname);
            // api53.query();
            if (!this.$route.query.Rurl) {
              this.$router.push({
                path: '/'
              });
            } else {
              this.$router.push({
                path: this.$route.query.Rurl
              })
            }
          }).catch(err => {
            this.$message({
              message: err.msg,
              type: 'error',
              center: true
            });
            this.password = ''

          })
        }
      })

    },
    /**
     * 获取验证码冷却
     */
    countdownTime() {
      this.smsTime--
      if (this.smsTime === 0) {
        this.smsCode = true
      }
    },
    // 设置cookie
    setCookie(name, pwd, remeber, exdays) {
      var now = new Date() // 获取时间
      now.setTime(now.getTime() + 24 * 60 * 60 * 1000 * exdays) // 保存的天数
      // 字符串拼接cookie' ;toGMTString() 方法可根据格林威治时间 (GMT) 把 Date 对象转换为字符串，并返回结果
      window.document.cookie = 'userName' + '=' + name + ';time=' + now.toGMTString()
      window.document.cookie = 'userPwd' + '=' + pwd + ';time=' + now.toGMTString()
      window.document.cookie = 'remeber' + '=' + remeber + ';time=' + now.toGMTString()
    },
    // 读取cookie
    getCookie: function () {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; ') // 这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('=') // 再次切割
          // 判断查找相对应的值
          if (arr2[0] === 'userName') {
            this.account = arr2[1] // 保存到保存数据的地方
          } else if (arr2[0] === 'userPwd') {
            this.password = arr2[1]
          } else if (arr2[0] === 'remeber') {
            if (arr2[1] === 'true') {
              this.checked = true
            } else {
              this.checked = false
            }
          }
        }
      }
    },
    // 清除cookie
    clearCookie: function () {
      this.setCookie('', '', '', -1) // 修改2值都为空，天数为负1天就好了
    },
    onkeydown13() {
      let g = this;
      document.onkeydown = function (e) {
        let key = window.event.keyCode;
        if (!vuex.state.loginStatus) {
          if (key == 13) {
            if (g.activeName == 'password_login') {
              g.clickLogin('loginForm')
            } else {
              g.smsLogin('codeLoginForm')
            }
          }
        }
      }
    }
  },
  mounted() {
    this.getCookie()
    this.onkeydown13()
  },
  watch: {
    smsCode(val, oldVal) {//普通的watch监听
      if (!val) {
        this.smsTime = 60
        this.countdown = setInterval(this.countdownTime, 1000);
      } else {
        clearInterval(this.countdown)
      }
    },
  },
  computed: {},
  created() {}
}
</script>
<style lang="less" scoped>
.login {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/image/Login/backgroundImg.png") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .main{
    .left{
      width: 250px;
      height: 399px;
      background: rgba(115,146,255,0.6000);
      border-radius: 10px 0 0 10px;
      opacity: 1;
      padding: 50px 0 51px 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .logo{
        width: 195px;
        height: 60px;
      }
      .back{
        font-size: 20px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        cursor: pointer;
        img{
          width: 25px;
          height: 25px;
          margin: 0 0 0 10px;
        }
      }
    }
    .right{
      width: 370px;
      height: 470px;
      background: #FFFFFF;
      border-radius: 0 10px 10px 0;
      padding: 30px 15px 0;
      /deep/.el-tabs__nav-scroll{
        margin:  0 0 0 27px;
      }
      /deep/.el-tabs__header{
        margin: 0;
      }
      /deep/.el-tabs__item{
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #505050;
      }
      /deep/.is-active{
        font-size: 22px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }
      /deep/.el-tabs__active-bar{
        height: 3px;
        background: #434ED6;
        border-radius: 6px 6px 6px 6px;
      }
      /deep/.el-tabs__nav-wrap::after{
        height: 1px;
        background-color: #E2E2E2;
      }
      .input-box{
        width: 320px;
        padding: 20px 25px 16px;
        /deep/.el-input__inner{
          height: 50px;
          border-radius: 5px;
          border: 1px solid #EDEDED;
        }
        /deep/.el-input__prefix{
          i{
            height: 50px;
            font-size: 20px;
          }
        }
        /deep/.el-input__inner:focus+.el-input__prefix{
          i{
            color: #434ED6 !important;
          }
        }
        /deep/.el-input__inner:focus{
          border: 1px solid #434ED6 !important;
        }
        /deep/.el-form-item{
          margin: 0 0 23px 0 ;
        }
        /deep/.el-form-item.is-error .el-input__inner{
          border: 1px solid #FF7979;
        }
        /deep/.el-form-item.is-error .el-input__prefix{
          i{
            color: #FF7979;
          }
        }



        /deep/.el-form-item__error{
          padding: 1px 0 4px;
        }
        /deep/.el-form-item__content{
          display: flex;
          align-items: center;
        }

      }
      .submitBtn{
        cursor: pointer;
        margin: 0 25px 20px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        background: #434ED6;
        border-radius: 42px;
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
      .other-box{
        padding: 0 25px;
        .links{
          display: flex;
          align-items: center;
          a{
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #C7C7C7;
            cursor: pointer;
          }
          span{
            display: block;
            width: 1px;
            height: 12px;
            background: #C7C7C7;
            margin: 0 10px;
          }

        }

      }
      .inputBtnBox{
        display: flex;
        align-items: center;
        /deep/.el-input,/deep/.el-input__inner{
          width: 225px;
          border-right: none;
          margin: 0 !important;
          border-radius: 5px 0 0 5px;
        }
        .inputBtn{
          width: 100px;
          height: 50px;
          line-height: 50px;
          background: #FFFFFF;
          box-shadow: -3px 0px 7px 0px rgba(0,0,0,0.1000);
          border-radius: 5px 5px 5px 5px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #434ED6;
          text-align: center;
          z-index: 1;
          cursor: pointer;
        }

      }

    }
  }

  @media screen and (max-width: 1000px) {
    .left_logo{
      display: none;
    }
    .login_box{
      margin-left: 0;
      .back{
        display: none;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .login_box{
      width: 90vw;
      .box{
        width: 70vw;
        .box_btn{
          .password_login_box_btn_bot{
            width: 70vw;
          }
        }
        .message_login_box_input{
          /deep/.el-input--suffix{
            width: 55%;
          }
        }
      }
    }
  }
}
</style>
