<template>
  <div class="seller-contract">
    <div class="seller-top">
      <div class="top-con">
        <div class="container">
          <img :src="require(`@/assets/image/Contract/${imgUrl}`)"/>
          <span>订单编号：{{ order_sn }}</span>
        </div>
        <div class="logo suc-logo"></div>
      </div>
    </div>
    <div class="seller-main">
      <div class="already-box">
        <div class="alrady-img">
          <img src="@/assets/image/Questionnaire/qsub.png">
        </div>
        <div class="already-text">
          <div class="text-title">资料已成功提交</div>
          <span class="text-tip" v-if="already !== 1">法务加速审核并起草合同中</span>
          <div class="text-tip2" v-if="already !== 1">
            整个流程大概<span>十分钟</span>左右，老板耐心等待。
          </div>
          <span class="text-tip3" v-if="already !== 1">(合同发起后会有短信提醒签署)</span>
          <div v-if="mobile_show" class="text-tip text-bottom">即将在{{ count }}秒后返回个人中心，<router-link class="go-now" :to="{name:'ContractInfo'}">立即返回</router-link></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiContractDetails } from "../../request/API";
export default {
  name: 'submitSuccess',
  data() {
    return {
      order_sn: '',
      type: 0,
      imgUrl: '',
      count: 5,
      timer: '',
      mobile_show: true,
      already: 0,
      app_status: ''
    }
  },
  created() {
    this.already = this.$route.query?.already
    this.app_status = this.$route.query.app_status
    // 获取订单编号
    if(this.app_status){
      apiContractDetails(Number(this.app_status),{contract_id: this.$route.query.contract_id}).then(res => {
        this.order_sn = res.data.order_sn
      })
    }
    this.type = this.$route.query.type
    this.type == 1 ? this.imgUrl = 'title.png' : this.type == 2 ? this.imgUrl = 'sell-title.png' : this.imgUrl = 'indemnity-title.png'
    if(this._isMobile()){
      this.mobile_show = false
    }else{
      if(this.type == 1 || this.type == 2){
        this.mobile_show = true
        this.timer = setInterval(() => {
          if(this.count <= 0){
            clearInterval(this.timer)
            this.$router.push({name: 'ContractInfo'})
          } else {
            this.count--
          }
        },1000)
      } else {
        this.mobile_show = false
      }
      
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
}
</script>

<style lang="less">
.seller-contract {
  background-color: #f9fafb;

  .seller-top {
    height: 278px;
    width: 100vw;
    background-color: #5071C8;

    .container {
      padding-top: 63px;
      padding-left: 20px;

      img {
        width: 224px;
        height: 30px;
      }

      span {
        display: block;
        margin-top: 13px;
        font-size: 20px;
        color: #fff;
      }
    }
  }

  .seller-main {
    position: relative;
    top: -123px;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 57px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    .already-box{
      padding-top: 170px;
      margin-bottom: 200px;
      display: flex;
      justify-content: center;
      .alrady-img{
        width: 188px;
        height: 146px;
        img{
          width: 100%;
        }
      }
      .already-text{
        margin-left: 20px;
        .text-title{
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          color: #000000;
          margin-bottom: 10px;
        }
        .text-tip{
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #000000;
          letter-spacing: 3px;
        }
        .text-tip2{
          padding-right: 20px;
          height: 43px;
          background: #feedcb;
          position: relative;
          left: -20px;
          border-radius: 10px;
          text-indent: 20px;
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #ffa740;
          line-height: 49px;
          margin: 15px 0;
          &::before{
            position: absolute;
            background: #feedcb;
            content: '';
            transform:rotate(45deg);
            width: 10px;
            height: 10px;
            top: 20px;
            left: -5px;
          }
          span{
            color: #659BF7;
          }
        }
        .text-tip3{
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #acacac;
        }
        .text-bottom{
          margin-top: 10px;
          .go-now{
            color: #ffa740;
            &:hover{
              color: #FCAD13;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .seller-main {
      width: 95vw;
    }
  }
  @media screen and (max-width: 900px) {
    .suc-logo{
      display: none;
    }
    .seller-top {
      height: 140px;
      .top-con{
        display: flex;
        justify-content: center;
        position: relative;
      }
      
      .container {
        margin: 0 auto;
        text-align: center;
        padding-top: 23px;
        img {
          width: 130px;
          height: 18px;
        }
        span{
          margin-top: 7px;
          font-size: 14px;
        }
      }
    }
    .seller-main {
      width: 93vw;
      top: -53px;
      .already-box{
        margin-top: -27px;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        .already-text{
          margin-top: 18px;
          text-align: center;
          margin-left: 0;
          .text-title{
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            color: #3F4C5C;
            margin-bottom: 10px;
          }
          .text-tip{
            text-align: center;
            font-size: 16px;
            color: #96A0AC;
          }
          .text-tip2{
            padding-right: 0;
            height: 20px;
            background: transparent;
            position: static;
            left: 0px;
            border-radius: 0px;
            color: #000;
            line-height: 20px;
            margin: 50vw 0 7px;
            &::before{
              display: none;
            }
            span{
              color: #ffa740;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .seller-main {
      width: 93vw;
      .already-box{
        .already-text{
          .text-tip2{
            font-size: 14px;
          }
          .text-tip3{
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
