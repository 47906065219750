<template>
  <div class="questionnaire" v-loading="loading">
    <div v-if="status == true">
      <div class="top">
        <el-link @click="goLink" class="qbtn" :underline="false"></el-link>
      </div>
      <div class="container">
        <div class="qct">
          <p>{{remark}}</p>
        </div>
        <div class="qcmain">
          <el-form ref="form" label-position="top" :model="questForm" :rules="rules" :show-message="false">
            <el-form-item :prop="item.alias+'_'+item.id" v-for="item in qudata" :key="item.id">
              <span slot="label" class="item-f">
                <i class="item-tl">0{{item.id}}</i>
                <div class="item-title">
                  <i>{{item.name}}：</i>
                  <i v-if="item.alias != 'contact'" class="item-tt">({{item.type == 0 ? '单选': item.type == 1 ? '多选' : '选填'}})</i>
                </div>
              </span>
              <div v-if="item.type == 0" class="quradio">
                <el-radio v-model="questForm[item.alias+'_'+item.id]" :label="items.id" v-for="(items, index) in item.answer" :key="index">{{items.name}}</el-radio>
              </div>
              <el-checkbox-group v-model="questForm[item.alias+'_'+item.id]" v-if="item.type == 1">
                <el-checkbox :label="items.id" v-for="(items, index) in item.answer" :key="index">{{items.name}}</el-checkbox>
              </el-checkbox-group>
              <el-input
                type="textarea"
                :rows="3"
                placeholder="请输入内容"
                resize="none"
                v-if="item.type == 3"
                v-model="questForm[item.alias+'_'+item.id]">
              </el-input>
            </el-form-item>
          <el-button type="primary" class="qsub" @click="save('form')">提交</el-button>
          </el-form>
        </div>
      </div>
    </div>
    <div v-if="status == false" class="status-2">
      <div class="already-box">
        <div class="alrady-img">
          <img src="@/assets/image/Questionnaire/qsub.png">
        </div>
        <div class="already-text">
          <div class="text-title">您已提交问卷</div>
          <span class="text-tip">本平台调研问卷每月仅可填写一次</span>
          <div>
            <el-link @click="goLink" class="text-btn" :underline="false"></el-link>
          </div>
          <el-link href="/" class="go-index" :underline="false" v-if="showPc"><i class="el-icon-d-arrow-left"></i>返回首页</el-link>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="questionnaireDialog"
      custom-class="questionnaireDialog"
      :show-close="false"
      top="35vh"
      center>
          <span>
            <div id="qn_main">
              <div class="qn_info">
                <div class="qn_img"><img src="@/assets/image/Questionnaire/qsub.png"></div>
                <div class="qn_name">问卷提交成功</div>
                <div class="qn_con">次月可查看获奖名单请耐心等待</div>
              </div>
              <div class="qn_btn">
                <button class="qn_continue" target="_blank" @click="goIndex()">确认</button>
              </div>
            </div>
            <!-- <img class="qn_close" src="@/assets/image/AccountDeal/close.png" @click="questionnaireDialog = false"> -->
          </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiGetstatus, apiGetsurvey, apiSurveyAdd } from "../../request/API";
export default {
  name: 'Questionnaire',
  data() {
    return {
      // 调研问卷id
      id: 1,
      // 用户是否30天内填过该问卷
      status: 3,
      questionnaireDialog: false,
      questForm: {},
      rules: {},
      remark: '',
      // 存放调研问卷问题
      qudata: [],
      showPc: true,
      loading: false,
      isPad: false,
      userId: ''
    }
  },
  created() {
    this.loading = true
    // pc端访问，没登录 ? 跳转pc登录 : no something
    // 移动端访问 no something
    if(this._isMobile()){
      this.showPc = false
      this.userId = this.getQuery('data')
      // return;
    } else {
      if (this.$store.state.loginStatus) {
        this.userId = JSON.parse(localStorage.getItem('userData')).user_id || ''
        // return;
      } else{
        this.$router.push({
          path: '/login',
          query: {
            Rurl: this.$route.path,
          },
        })
      }
    }

    // 获取填写问卷状态
    apiGetstatus({
      id: this.id,
      uid: this.userId
    }).then(res => {
      this.status = res.data
      // this.status = true
      this.loading = false
    })
    this.getdata()
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    goIndex() {
      if(this._isMobile()) {
        this.questionnaireDialog = false
        window.location.reload()
      }else{
        this.showPc = false
        this.$router.push({path: '/'})
      }
    },
    /**
     * @description: 跳转中奖名单
     * @param {*}
     * @return {*}
     */
    goLink() {
       this.$router.push({name:'WinnersList',query: {id: this.id}})
    },
    /**
     * @description: 获取填写数据
     * @param {*}
     * @return {*}
     */
    getdata() {
      apiGetsurvey({id:this.id}).then(res => {
        this.remark = res.data.remark
        this.qudata = res.data.question
        for(let i in this.qudata){
          if(this.qudata[i].type == 1){
            this.$set(this.questForm,this.qudata[i].alias+'_'+this.qudata[i].id,[])
          }
          if(this.qudata[i].require == 1){
            this.$set(this.rules, this.qudata[i].alias+'_'+this.qudata[i].id, [{required: true, message: '', trigger: 'change'}])
          }
        }
      })
    },
    /**
     * @description: 提交
     * @param {*} formName
     * @return {*}
     */
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.questForm.id = this.id
          this.questForm.uid = this.userId
          apiSurveyAdd(this.questForm).then(res => {
            this.questionnaireDialog = true
          })
        } else {
          this.$message({
            message: '还有必填项没填~',
            center: true,
            offset: 200,
            customClass: 'sell-contract-msg'
          })
          return false;
        }
      });
    },
    //取url中的参数值
    getQuery(name) {
      // 正则：[找寻'&' + 'url参数名字' = '值' + '&']（'&'可以不存在）
      let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      console.log(r);
      if(r != null) {
          // 对参数值进行解码
          return decodeURIComponent(r[2]);
      }
      return null;
    }
  },
}
</script>

<style lang="less">
.questionnaireDialog {

  width: 342px !important;
  background: #FFFFFF;
  border-radius: 10px !important;
  position: relative;
  overflow: visible;
  .el-dialog__body {
    padding: 33px 0px 35px;
    position: relative;
    #qn_main {
      display: flex;
      flex-direction: column;
      align-items: center;
      .qn_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 34px;
        .qn_img{
          margin-bottom: 17px;
          img{
            width: 188px;
            height: 145px;
          }
        }
        .qn_name {
          align-self: center;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #353535;
          line-height: 20px;
          margin: 0 0 9px;
        }
        .qn_con{
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: CENTER;
          color: #96a0ac;
        }
      }
      .qn_btn {
        display: flex;
        justify-content: space-between;
        >button{
          display: inline-block;
          width: 233px;
          height: 40px;
          border-radius: 21px;
          font-size: 18px;
          color: #000000;
          text-align: center;
          line-height: 38px;
          cursor: pointer;
        }
        .qn_continue{
          border: 1px #FFDB51 solid;
          background-color: #FFDB51;
        }
      }
    }
    .qn_close {
      position: absolute;
      bottom: -63px;
      left: 155px;
      cursor: pointer;
    }
  }

  .el-dialog__header {
    padding: 0;
  }
}
  .questionnaire{
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    .top{
      width: 100vw;
      height: 271px;
      position: relative;
      background: url('../../assets/image/Questionnaire/qt.png') no-repeat center center;

      .qbtn{
        position: absolute;
        top: 130px;
        left: 50%;
        margin-left: -102px;
        width: 205px;
        height: 43px;
        background: url('../../assets/image/Questionnaire/qbtn.png') no-repeat;
        background-size: 100%;
      }
    }
    .container{
      width: 1200px;
      margin:  0 auto;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.10);
      position: relative;
      top: -74px;
      padding-top: 20px;
      padding-bottom: 100px;
      .qct{
        margin:0 auto;
        width: 84%;
        border-radius: 10px;
        box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.15);
        text-align: left;
        padding: 20px;
        margin-bottom: 20px;
        background-color: #fff;
        box-sizing: border-box;
        p{
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #8799af;
          line-height: 24px;
          letter-spacing: 0.01px;
        }
      }
      .qcmain{
        width: 500px;
        margin: 0 auto;
        .el-form-item{
          border-radius: 10px;
          box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.10);
          background-color: #fff;
          .el-form-item__label{
            display: block;
            position: relative;
            line-height: 20px;
            &::before{
              position: absolute;
              left: 50px;
              top: 10px;
            }
            .item-f{
              .item-tl{
                position: absolute;
                top: 0px;
                left: 0px;
                width: 36px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                background: url("../../assets/image/Questionnaire/ql.png") no-repeat;
                background-size: 100%;
                color: #fff;
                font-weight: 500;
                font-family: PingFang SC, PingFang SC-Medium;
                font-size: 16px;
                font-style: normal;
              }
              .item-title{
                display: flex;
                justify-content: space-between;
                font-family: PingFang SC, PingFang SC-Medium;
                padding: 10px 10px 0 56px;
                i{
                  font-style: normal;
                  &.item-tt{
                    color: #8799AF;
                  }
                }
              }
            }
          }
          .el-form-item__content,.el-checkbox-group{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 420px;
            margin: 0 auto 28px;
            .quradio{
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .el-radio,.el-checkbox{
              margin: 0;
            }
            .el-radio__input,.el-checkbox__input{
              display: none;
            }
            .el-radio__input.is-checked+.el-radio__label,.el-checkbox__input.is-checked+.el-checkbox__label{
              color: #FF8A00;
              background: #feedcb;
            }
            .el-radio__label,.el-checkbox__label{
              display: inline-block;
              width: 250px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              background: #F7F8F9;
              border-radius: 20px;
              margin-bottom: 10px;
            }
          }
          .el-checkbox-group{
            margin-bottom: 0;
          }
        }
        .qsub{
          display: block;
          width: 100%;
          height: 50px;
          color: #FFFFFF;
          background: #434ED6;
          border-radius: 30px;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: CENTER;
          border: none;
        }
      }
    }
    .status-2{
      width: 100vw;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      .already-box{
        margin-top: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        .alrady-img{
          width: 240px;
          height: 240px;
          margin: 0 48px 0 0;
          img{
            width: 100%;
          }
        }
        .already-text{
          .text-title{
            font-size: 24px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            color: #000000;
            margin-bottom: 10px;
          }
          .text-tip{
            font-size: 20px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #000000;
          }
          .text-btn{
            display: block;
            width: 205px;
            height: 43px;
            background: url('../../assets/image/Questionnaire/qbtn.png') no-repeat;
            background-size: 100%;
            margin: 11px 0 25px;
          }
          .go-index{
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #000000;
            &:hover{
              color: #FCAD13;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1300px) {
      .container {
        width: 95vw;
      }
    }
    @media screen and (max-width: 1100px) {
      .top{
        background: url('../../assets/image/Questionnaire/qtm2.png') no-repeat center center;
        background-size: cover;
        .qbtn{
          top: 32px;
          right: 8px;
          left: auto;
          width: 32px;
          height: 85px;
          background: url('../../assets/image/Questionnaire/qbtnm.png') no-repeat;
          background-size: 100%;
        }
      }
      .container {
        box-shadow: none;
        background: none;
        top: -115px;
        .qct{
          width: 500px;
          padding: 14px 31px;
          box-sizing: border-box;
          text-align: left;
        }
      }
    }
    @media screen and (max-width: 790px) {
      .top{
        background: url('../../assets/image/Questionnaire/qtm.png') no-repeat center center;
        background-size: cover;
      }
      .status-2{
          position: relative;
        .already-box{
          margin-top: 50px;
          display: flex;
          flex-direction: column;
          align-items: center;
          // .alrady-img{
          //   width: 188px;
          //   height: 146px;
          //   img{
          //     width: 100%;
          //   }
          // }
          .already-text{
            margin-top: 18px;
            .text-title{
              text-align: center;
              font-size: 24px;
              font-weight: 500;
              color: #3F4C5C;
              margin-bottom: 10px;
            }
            .text-tip{
              text-align: center;
              font-size: 20px;
              color: #96A0AC;
            }
            .text-btn{
              position: absolute;
              top: 65px;
              right: 0px;
              // left: auto;
              width: 32px;
              height: 85px;
              background: url('../../assets/image/Questionnaire/qbtnm.png') no-repeat;
              background-size: 100%;
            }
            .go-index{
              display: none;
            }
          }
        }
      }
    }
    @media screen and (max-width: 540px) {
      .questionnaireDialog{
        width: 300px !important;
        .el-dialog__body{
          .qn_close{
            left: 133px;
          }
        }
      }
      .container {
        width: 93vw;
        .qct{
          width:93vw;
        }
        .qcmain{
          width: 93vw;
          .el-form-item{
            .el-form-item__content,.el-checkbox-group{
              width: 70vw;
              .el-radio__label,.el-checkbox__label{
                width: 200px;
              }
            }

          }
        }
      }
    }
  }
</style>
