<template>
  <div class="help_detail">
    <div class="header">
      <div class="header_top">
        <router-link tag="div" class="logo_search" :to="{path:'/'}">
          <img src="@/assets/image/Home/logo.png">
        </router-link>
        <el-input
          placeholder="请输入问题关键词"
          class="search_input"
          @keydown.enter.native="handleSearch"
          v-model.trim="keyWord">
          <span slot="prefix">
            <img src="@/assets/image/HelpCenter/search.png">
          </span>
        </el-input>
      </div>
    </div>
    <div class="detail_box">
      <div class="detail_main">
        <div class="main_left" id="main_left">
          <el-menu
            :default-active="default_active"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose">
            <el-submenu index="all">
              <template slot="title">
                <span>全部问题</span>
              </template>
              <el-submenu
                :index="item.cat_id.toString()"
                v-for="item in menu"
                :key="item.cat_id">
                <template slot="title">{{ item.cat_name }}</template>
                <el-menu-item
                  :index="item.cat_id.toString()+'-'+child.id.toString()"
                  v-for="child in item.list"
                  @click="handleHelp(item.cat_id,child.id,child.title)"
                  :key="item.cat_id+'-'+child.id">
                  {{ child.title }}</el-menu-item>
              </el-submenu>
            </el-submenu>
          </el-menu>
        </div>
        <div class="main_right">
          <div class="main_right_top">
            <!-- <a @click="$router.back(-1)">返回</a> -->
            <router-link :to="{name:'helpCenter'}">返回</router-link>
                /
            全部问题 {{ sort_title ? ' / '+sort_title : '' }} {{ qes_title ? ' / '+qes_title : '' }}
          </div>
          <div class="main_right_wrapper">
              <div class="main_content ql-container ql-snow">
                <p class="ql-editor" v-html="content"></p>
              </div>
            <div class="main_content_right">
              <div
                class="letter_title"
                :class="{'active': right_index==index}"
                v-for="(item, index) in GuideFixed"
                @click="jump(item,index)"
                :key="index">
                {{ item.innerText }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiHelpCenter, apiHelpDetail } from "@/request/API";
export default {
  name: 'helpDetail',
  data() {
    return {
      keyWord: '',
      id: '',
      cat_id: '',
      content: '',
      menu: [],
      // 问题标题
      qes_title: '',
      // 分类标题
      sort_title: '',
      default_active: '',
      // 右侧小标题
      letter_title: [],
      GuideFixed: [],
      // scroll: '',
      right_index: 0
    }
  },
  created() {
    this.id = this.$route.query?.id
    this.cat_id = this.$route.query?.cat_id
    this.getMenuData()
    if(this.cat_id){
      this.handeleGetData()
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
     onScroll(e) {
      let scrollItems = document.querySelectorAll('h2')
      // console.log(document.documentElement.scrollTop);
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =
          document.documentElement.scrollTop >=
          scrollItems[i].offsetTop - scrollItems[0].offsetTop
        if (judge) {
          this.right_index = i
          break
        }
      }
      //滚动条触底了
      if (
        e.srcElement.scrollTop + e.srcElement.offsetHeight ==
        e.srcElement.scrollHeight
      ) {
        if (this.GuideFixed) {
          this.right_index = this.GuideFixed.length - 1
        } else {
          this.right_index = this.quizFixed.length - 1
        }
      }
    },
    /**
     * @description: 右侧锚点跳转定位
     * @param item
     * @return {*}
     */
    jump(item, index) {
      item.scrollIntoView({
        behavior: "instant",
        block: "start",
      });
      this.right_index=index
    },
    /**
     * @description: 点击左侧菜单显示不同内容
     * @param cat_id 父id
     * @param id 当前数据id
     * @return {*}
     */
    handleHelp(cat_id, id) {
      this.cat_id = cat_id,
      this.id = id
      this.right_index = 0
      this.GuideFixed = []
      this.$router.push({
        name: 'helpDetail',
        query: {id: this.id,cat_id: this.cat_id}
      })
      this.handeleGetData()
    },
    /**
     * @description: 获取左侧菜单
     * @return {*}
     */
    getMenuData() {
      apiHelpCenter().then(res => {
        this.menu = res.data
      })
    },
    /**
     * @description: 搜索
     * @return {*}
     */
    handleSearch() {
      this.$router.push({
        name:'helpCenterSearch',
        query:{
          keyWord: this.keyWord
        }
      })
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    /**
     * @description: 获取问题详情
     * @return {*}
     */
    handeleGetData() {
      apiHelpDetail({
        id: this.id,
        cat_id: this.cat_id
      }).then(res => {
        this.content = res.data.list[0].content
        this.default_active = `${this.cat_id}-${this.id}`
        this.qes_title = res.data.list[0].title
        this.sort_title = res.data.list[0].cat_name
        this.$nextTick(() => {
          let ghead = document.getElementsByTagName('h2');
          this.GuideFixed = ghead
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.help_detail{
  width: 100vw;
  .header{
    background: #fff;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    .header_top{
      width: 1810px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo_search{
        width: 296px;
        height: 99px;
        img{
          margin: 1px 0 0 0;
          width: 100%;
          object-fit: cover;
        }
      }
      .search_input{
        width: 499px;
        height: 60px;
        border: 1px solid #e4e7ef;
        /deep/input{
          height: 60px;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          border: none;
          border-radius: 39px;
          padding-left: 66px;
        }
        /deep/.el-input__prefix{
          width: 26px;
          height: 26px;
          top: calc(50% - 13px);
          left: 26px;

          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .detail_box{
    background: #F4F4F4;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .detail_main{
      width: 1810px;
      padding-top: 20px;
      display: flex;
      margin: 0 50px;
      .main_left{
        width: 300px;
        background: #fff;
        border-radius: 20px 0 0 20px;
        padding: 30px 0 0 30px;
        box-sizing: border-box;
        &#main_left ul li ul li {
          /deep/.el-submenu__icon-arrow{
            right: auto;
            left: 20px;
          }
        }
        /deep/.el-submenu__icon-arrow{
          right: auto;
          left: 0px;
          transform: rotateZ(-90deg);
        }
        .el-menu{
          border: none;
        }
        /deep/.el-menu-item, /deep/.el-submenu__title{
          height: 40px;
          line-height: 40px;
          // padding-left: 59px !important;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;
          overflow: hidden;
          // width: 250px;
          // padding-left: 40px !important;
          // margin-left: 20px;
          text-overflow:ellipsis;
          white-space: nowrap;
        }

        /deep/.el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow{
          transform: rotateZ(0deg);
        }
        /deep/ .is-active > .el-submenu__title {
          color: #434ED6 !important;

          div {
            background: #f6f7fa !important;
          }
        }
        /deep/ .el-submenu__title:hover, .el-submenu__title:focus {
          color: #434ED6;
          background: transparent;
        }
        .el-menu-item:focus, .el-menu-item:hover{
          background: #F6F7FA;
        }
         /deep/ .el-submenu > .el-menu--inline > .is-active .is-active {
          background: #F6F7FA;
          // margin-left: 20px;
          padding-left: 40px !important;
          position: relative;
          &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 6px;
            height: 40px;
            background: #434ED6;
            border-radius: 6px 0 0 6px;
          }
        }
      }
      .main_right{
        flex: 1;
        margin-left: 10px;
        .main_right_top{
          background: #fff;
          border-radius: 20px 20px 0 0;
          height: 70px;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #b1b5c0;
          line-height: 70px;
          padding-left: 40px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          a{
            display: inline-block;
            width: 55px;
            height: 24px;
            background: #adc0fb;
            border-radius: 16px;
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            color: #ffffff;
            line-height: 24px;
            text-align: center;
            margin-right: 10px;
            cursor: pointer;
          }
        }
        .main_right_wrapper{
          background: #fff;
          display: flex;
          justify-content: space-between;
          position: relative;
          .main_content{
            flex: 1;
            padding: 0px 60px;
            border-right: 1px solid #e4e7ef !important;
            margin-right: 260px;
            box-sizing: border-box;
            img{
              width: 100% !important;
            }
            .ql-editor{
              padding: 0;
              margin: 0;
            }
          }
          .main_content_right{
            position: fixed;
            right: 50px;
            top: 220px;
            width: 260px;
            min-height: 962px;
            padding: 27px 0;
            .letter_title{
              height: 42px;
              line-height: 42px;
              font-size: 18px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #000000;
              text-indent: 39px;
            }
            .active{
              background: #F6F7FA;
              border-left: 2px #434ED6 solid;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
  .detail_box{
    .ql-container.ql-snow{
      border: none !important;
    }
  }
</style>
