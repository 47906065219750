<template>
  <div id='layout' :style="style">
    <Head style="position: relative;z-index: 999"></Head>
    <keep-alive>
      <router-view v-if="this.$route.meta.keepAlive" :key="key"></router-view>
    </keep-alive>
    <router-view v-if="!this.$route.meta.keepAlive" :key="key"></router-view>

    <RightTools></RightTools>
    <Footer></Footer>
    <!-- 估价通知 -->
    <el-dialog
      :visible.sync="vitimateSubmission"
      custom-class="Unpublished"
      :show-close="false"
      width="342px"
      top="35vh"
      center>
          <span>
            <div id="vi_main">
              <div class="UPS_info">
                <div class="UPS_name">{{ site_status == 1 ? '估价报告' : '合同报告'}}</div>
                <div class="UPS_con">{{ site_status == 1 ? '您的估价报告已放入通知列表' : '您的合同信息已放入通知列表'}}</div>
              </div>
              <div class="UPS_btn">
                <button class="UPS_continue ESconfirm" @click="viNotice()">查看</button>
              </div>
            </div>
            <img class="UPS_close" src="@/assets/image/AccountDeal/close.png" alt="" @click="vitimateSubmission = false">
          </span>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/footer'
import Head from '@/components/head'
import RightTools from '@/components/RightTools/index'

export default {
  name: '',
  props: {},
  components: {
    Footer,
    Head,
    RightTools,
  },
  data() {
    return {
      appDownload:true,
      vitimateSubmission: false,
      site_status: 1
    }
  },
  methods: {
    getQinMingJieDate() {
      let date = new Date()
      let fullYear = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      //清明节的日期是不固定的，规律是：闰年开始的前2年是4月4日，闰年开始的第3年和第4年是4月5日
      if (this.isLeapYear(fullYear) || this.isLeapYear(fullYear - 1)) {
        return month === 4 && day === 4;
      } else {
        return month === 4 && day === 5;
      }
    },
    //判断是否是闰年
    isLeapYear(Year) {
      if (((Year % 4) === 0) && ((Year % 100) !== 0) || ((Year % 400) === 0)) {
        return true;
      } else {
        return false;
      }
    },
    // 估价通知
    viNotice(){
      this.$router.push('/personalCenter/siteNotice')
      this.vitimateSubmission = false
    }
  },
  created() {
    // this.$WS.on('assess_tip', (e) => {
    //   this.site_status = 1
    //   this.vitimateSubmission = true
    // })
    // this.$WS.on('contractNotice', (e) => {
    //   this.site_status = 2
    //   this.vitimateSubmission = true
    // })
  },
  mounted() {
  },
  watch: {},
  computed: {
    key() {
      return this.$route.path
    },
    style() {
      let style = ''
      let date = new Date()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (this.$route.name === 'Home') {
/*        if (month === 12 && day === 13) {
          //南京大屠杀
          style = 'filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);-webkit-filter: grayscale(100%)'
        }*/
/*        else if (this.getQinMingJieDate()) {
          //清明节
          style = 'filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);-webkit-filter: grayscale(100%)'
        }*/
      }
      return style
    },
  }
}
</script>
<style lang="less">
  .Unpublished.el-dialog{
    border-radius: 10px;
  }
</style>
<style lang='less' scoped>
#layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F4F4F4;
  overflow: hidden;
  .appDownload{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 80px;
    background: rgba(40, 55, 72, 0.6);
    z-index: 999999;
    .btn{
      margin: 0 30px;
      position: absolute;
      right: 0;
      cursor: pointer;
    }
    .ad-conetnt{
      width: 1200px;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      .conetnt{
        height: 60px;
        object-fit: contain;
      }
      .qrcode{
        position: absolute;
        bottom: 12px;
        right: 0;
      }
    }
  }
  .Unpublished {
    width: 342px;
    background: #FFFFFF;
    border-radius: 10px;
    position: relative;
    overflow: visible;

  .el-dialog__body {
    padding: 33px 0px 35px;
    position: relative;

    #vi_main {
      display: flex;
      flex-direction: column;
      align-items: center;

      .UPS_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 0 19px;

        .UPS_name {
          align-self: center;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #353535;
          line-height: 20px;
          margin: 0 0 25px;
        }
        .UPS_con{
          font-size: 18px;
        }
      }

      .UPS_btn {
        display: flex;
        justify-content: space-between;
        >button{
          display: inline-block;
          width: 139px;
          height: 38px;
          border-radius: 25px;
          font-size: 16px;
          color: #000000;
          text-align: center;
          line-height: 38px;
          cursor: pointer;
        }
        .UPS_reset{
          border: 1px #000000 solid;
          background-color: #fff;
          margin-right: 14px;
        }
        .UPS_continue{
          border: 1px #FFDB51 solid;
          background-color: #FFDB51;
        }
        .ESconfirm{
          width: 233px;
        }
      }
    }
    .UPS_close {
      position: absolute;
      bottom: -47px;
      left: 154px;
      cursor: pointer;
        width: 30px;
        height: 30px;
    }
  }

  .el-dialog__header {
    padding: 0;
  }
}
}

</style>
